import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import AuthContext from '../../contexts/AuthContext';
import useFormHelper from '../../hooks/useFormHelper';
import styles from '../../styles/base';
import {
  useFormik,
  FormikTextField,
  FormikSelect
} from '../../utils/formik';
import { DoseConstants, DoseSchema } from '../../utils/validation';
import ClinicForm from './ClinicForm';

const { apptTypes, apptTimes } = DoseConstants;

const getApptTimes = () => {
  const d = new Date();
  const apptTimeMin = Math.ceil(2 * (d.getHours() + d.getMinutes() / 60)) / 2;
  const apptTimeMax = (apptTimeMin + 12);
  
  const apptTimesAllowed = [];
  for (let t = apptTimeMin; t < apptTimeMax; t += 0.5) {
    apptTimesAllowed.push(
      apptTimes.find(({ value }) => value === (t % 24))
    );
  }

  return {
    apptTimeMin,
    apptTimeMax,
    apptTimesAllowed
  }
};

const myTimezoneOffset = -1 * (new Date()).getTimezoneOffset();
function DoseDialogForm({ classes, onClose, onSave, tally }) {
  const [page, setPage] = useState(1);
  const [addingClinic, setAddingClinic] = useState(false);
  const { api, me } = useContext(AuthContext);

  const clinics = me.provider?.clinics || [];
  const clinicDefaults = {
    clinic: clinics[0].id || '',
    contactNumber: clinics[0].phone || '',
    arrivalInstructions: clinics[0].arrivalInstructions || '',
  };

  const { apptTimeMin, apptTimesAllowed } = getApptTimes();

  const formik = useFormik({
    initialValues: {
      apptType: 'before',
      apptTime: (apptTimeMin >= 15 || apptTimeMin < 6) ? apptTimesAllowed[5].value : 17, // minimum 5pm or 2 hours from now
      tally,
      ...clinicDefaults
    },
    validateOnBlur: true,
    validationSchema: DoseSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.post('doses', values);
      if (response) {
        const isAre = tally > 1 ? 's are' : ' is';
        setSuccessMsg(`Dose${isAre} now pending matchmaking. We will notify you when patient${isAre} confirmed.`);
        onSave();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik, true);

  const addClinic = () => {
    setAddingClinic(true);
  };

  const doneAddingClinic = (newClinic) => {
    setAddingClinic(false);
    if (newClinic && newClinic.id) {
      selectClinic(null, null, newClinic);
    }
  };

  const selectClinic = (event, child, newClinic) => {
    if (newClinic || event.target.value !== -1) {  // -1 is "add new clinic", will be selected after added (if not cancelled)
      const clinic = newClinic || clinics.find(({ id }) => id === event.target.value);
      if (clinic) {
        formik.setFieldValue('clinic', clinic.id);
        if (!formik.touched.contactNumber) {
          formik.setFieldValue('contactNumber', clinic.phone);
        }
        if (!formik.touched.arrivalInstructions) {
          formik.setFieldValue('arrivalInstructions', clinic.arrivalInstructions);
        }
      } else {
        formik.setFieldValue('clinic', '');
        formik.setFieldValue('contactNumber', '');
        formik.setFieldValue('arrivalInstructions', '');
      }
    }
  };
  
  const onNextPage = (direction) => () => {
    const nextPage = page + direction;
    if (nextPage <= 0) {
      onClose();
    } else if (nextPage > 3) {
      formik.handleSubmit();
    } else {
      setPage(nextPage);
    }
  };

  const selectedClinic = (clinics || []).find(({ id }) => id === formik.values.clinic);
  return [
    (
      <Dialog key={0} open>
        <DialogTitle disableTypography style={{ alignItems: 'center', display: 'flex' }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Allocate {tally > 1 ? `${tally} Doses` : 'Dose'}
            <Hidden xsDown>{' '}to the Waitlist</Hidden>
          </Typography>
          <IconButton aria-label="close" onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Collapse in={page === 1}>
            <div className={classes.dialogCollapseContainer}>
              <Typography variant="h6" color="textSecondary" style={{ fontWeight: '400' }}>Which clinic location?</Typography>
              <Select
                fullWidth
                name="clinic"
                onChange={selectClinic}
                error={formik.touched.clinic && Boolean(formik.errors.clinic)}
                value={formik.values.clinic}
                variant="outlined">
                {clinics.map(({ id, name, address }) => (
                  <MenuItem key={id} value={id}>
                    <ListItemText primary={name} secondary={address} />
                  </MenuItem>
                ))}
                <MenuItem onClick={addClinic} style={{ borderTop: '1px solid #eee' }} value={-1}>
                  <Typography style={{ display: 'flex', justifyContent: 'center', padding: '8px', width: '100%' }}>
                    <AddIcon /> Add New Location
                  </Typography>
                </MenuItem>
              </Select>
            </div>
          </Collapse>

          <Collapse in={page === 2}>
            <div className={classes.dialogCollapseContainer}>
              <Typography variant="h6" color="textSecondary" style={{ fontWeight: '400' }}>
                What time should the matching patient{tally > 1 ? 's' : ''} arrive?
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                  <FormikSelect
                    name="apptType"
                    formik={formik}
                    style={{ width: 160 }}>
                    {Object.keys(apptTypes).map((value) => (
                      <MenuItem key={value} value={value}>{apptTypes[value]}</MenuItem>
                    ))}
                  </FormikSelect>

                  <div style={{ width: 12 }} />

                  <FormikSelect
                    name="apptTime"
                    formik={formik}
                    style={{ flex: 1 }}>
                    {apptTimesAllowed.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </FormikSelect>
              </div>

              {selectedClinic?.timezone?.offset !== myTimezoneOffset && (
                <Typography
                  color="secondary"
                  variant="caption"
                  style={{ display: 'block', marginTop: 16, textAlign: 'center' }}>
                  Select the time of the appointment in the clinic's own timezone (not necessarily your own).
                </Typography>
              )}

            </div>
          </Collapse>

          <Collapse in={page === 3}>
            <div className={classes.dialogCollapseContainer} style={{ padding: '16px 0' }}>
              <FormikTextField
                autoFocus
                fullWidth
                name="contactNumber"
                label="Contact Number"
                helperText="i.e. In case of questions or delays. This number will only be shared with confirmed patients."
                formik={formik}
                style={{ marginBottom: 20 }}
                variant="outlined"
              />

              <FormikTextField
                fullWidth
                multiline
                rows={4}
                name="arrivalInstructions"
                label="Arrival Instructions"
                helperText="i.e. What should the patient bring with them? What should they do or where should they go when they arrive?"
                placeholder="Enter arrival instructions given for these patients..."
                formik={formik}
                variant="outlined"
              />
            </div>
          </Collapse>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            color="default"
            disabled={formik.isSubmitting}
            onClick={onNextPage(-1)}
            size="large"
            variant="contained">
            {page > 1 ? 'Back' : 'Cancel'}
          </Button>
          <div style={{ flex: 1 }} />
          <Button
            color="primary"
            disabled={formik.isSubmitting}
            onClick={onNextPage(1)}
            size="large"
            startIcon={page < 3 ? null : <CheckIcon />}
            variant="contained">
            {page < 3 ? 'Next' : 'Assign Doses'}
          </Button>
        </DialogActions>
      </Dialog>
    ),
    (
      <Dialog key={1} open={addingClinic} onClose={doneAddingClinic}>
        <DialogTitle disableTypography style={{ alignItems: 'center', display: 'flex', paddingBottom: 0 }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Add a Clinic
          </Typography>
          <IconButton aria-label="close" onClick={doneAddingClinic} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 20 }}>
          <ClinicForm onSave={doneAddingClinic} />
        </DialogContent>
      </Dialog>
    )
  ];
};

export default withStyles(styles)(DoseDialogForm);
