import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import ClinicForm from '../../components/provider/ClinicForm';
import Layout from '../../components/Layout';
import AuthContext from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  addClinic: {
    width: theme.spacing(40),
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

export default function Account() {
  const { me } = useContext(AuthContext);
  const [addingClinic, setAddingClinic] = useState(false);
  const [editingClinic, setEditingClinic] = useState(null);
  const classes = useStyles();

  const addClinic = () => {
    setAddingClinic(true);
  };
  const doneAddingClinic = () => {
    setAddingClinic(false);
  };

  const editClinic = (clinic) => () => {
    setEditingClinic(clinic);
  };
  const doneEditingClinic = () => {
    setEditingClinic(null);
  };

  const breadcrumbs = [
    { label: 'Dashboard', href: '/provider' },
    { label: 'Account', href: '/provider/account' },
    { label: 'Clinics' },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      {((me.provider || {}).clinics || []).map((clinic) => (
        <Paper key={clinic.id} style={{ padding: 32, marginBottom: 12 }}>
          <Button
            onClick={editClinic(clinic)}
            size="small"
            startIcon={<EditIcon />}
            style={{ float: 'right' }}>Edit</Button>
          <Typography variant="h6">{clinic.name}</Typography>
          <Typography display="block" variant="subtitle2">
            {clinic.affiliation}{clinic.affiliation && <br/>}
            {clinic.address}<br/>
            {clinic.phone}
          </Typography>
          {clinic.arrivalInstructions && (
            <Typography display="block" variant="body2" style={{ marginTop: 12 }}>
              {clinic.arrivalInstructions}
            </Typography>
          )}
        </Paper>
      ))}
      <div style={{ marginTop: 4, textAlign: 'center' }}>
        <Button
          className={classes.addClinic}
          color="primary"
          onClick={addClinic}
          size="large"
          startIcon={<AddIcon />}
          variant="outlined">Add Clinic</Button>
      </div>

      <Dialog open={addingClinic} onClose={doneAddingClinic}>
        <DialogTitle disableTypography style={{ alignItems: 'center', display: 'flex', paddingBottom: 0 }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Add a Clinic
          </Typography>
          <IconButton aria-label="close" onClick={doneAddingClinic} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: 20 }}>
          <ClinicForm onSave={doneAddingClinic} />
        </DialogContent>
      </Dialog>

      <Dialog open={Boolean(editingClinic)} onClose={doneEditingClinic}>
        <DialogTitle disableTypography style={{ alignItems: 'center', display: 'flex', paddingBottom: 0 }}>
          <Typography variant="h6" style={{ flex: 1 }}>
            Edit Clinic
          </Typography>
          <IconButton aria-label="close" onClick={doneEditingClinic} size="small">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <ClinicForm editClinic={editingClinic} onSave={doneEditingClinic} />
        </DialogContent>
      </Dialog>
    </Layout>
  );
}