import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import VolunteerIcon from '@material-ui/icons/ThumbUpOutlined';
import {
  CardElement,
  Elements, 
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import AuthContext from '../contexts/AuthContext';
import ToastContext from '../contexts/ToastContext';
import DonateImage from '../images/stethoscope.jpg';
import RecurImage from '../images/worldmask.jpg';
import VolunteerImage from '../images/volunteer.jpg';
import styles from '../styles/base';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
const cardStyle = {
  style: {
    base: {
      color: "#222222",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#999999"
      }
    },
    invalid: {
      color: "#f6685e",
      iconColor: "#f6685e"
    },
  }
};

function DonationDialog({ amount, onClose, popToast }) {
  const { api, me } = useContext(AuthContext);

  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const elements = useElements();
  const stripe = useStripe();
  const customerName = useRef();
  const customerEmail = useRef();

  useEffect(async () => {
    setClientSecret(null);
    if (amount && parseFloat(amount) > 0) {
      const { response, error } = await api.post('auth/predonate', { amount });
      if (response) {
        setClientSecret(response.token);
      } else {
        setError(error || 'Something went wrong. Please refresh the page and try again.');
      }
    }
  }, [amount]);

  const handleCardChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);

    try {
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          billing_details: {
            name: customerName.current.value,
            email: customerEmail.current.value
          },
          card: elements.getElement(CardElement)
        },
        receipt_email: customerEmail.current.value
      });
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        
        popToast('Thank you for your gift! Together we can save lives and beat COVID faster.', 'success');
        onClose();
      }
    } catch (e) {
      setError(`Something went wrong. Please try again.`);
      setProcessing(false);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogContent style={{ width: 500, maxWidth: 'calc(100vw - 64px)' }}>
        <form onSubmit={handleSubmit}>
          <Collapse in={processing}>
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: 200 }}>
              <CircularProgress />
            </div>
          </Collapse>
          <Collapse in={!processing}>
            <TextField inputRef={customerName} fullWidth variant="outlined" placeholder="Your Name" defaultValue={me ? `${me.firstName} ${me.lastName}` : ''} />
            <TextField inputRef={customerEmail} fullWidth variant="outlined" placeholder="Your Email" defaultValue={me ? `${me.email}` : ''} style={{ marginTop: 8 }} />
            <div style={{ padding: '18.5px 14px', border: '1px solid #CCC', borderRadius: 4, margin: '8px 0'  }}>
              <CardElement id="card-element" options={cardStyle} onChange={handleCardChange} />
            </div>
            <Collapse in={error}>
              <Alert severity="error" style={{ marginBottom: 8 }}>
                {error}
              </Alert>
            </Collapse>
            <div style={{ paddingBottom: 8, textAlign: 'center' }}>
              <Button
                color="primary"
                disabled={processing || disabled || succeeded}
                fullWidth
                type="submit"
                size="large"
                variant="contained">
                Make Your Gift
              </Button>
            </div>
          </Collapse>
        </form>
      </DialogContent>
    </Dialog>
  );
}

const isValidMoney = (value) => /^\d*\.?\d{0,2}$/.test(`${value}`);

function Donate({ classes }) {
  const { me } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [amount, setAmount] = useState('25.00');
  const [open, setOpen] = useState(false);

  const handleAmountBlur = () => {
    const cleansed = parseFloat(amount);
    setAmount(isNaN(cleansed) ? '' : `${cleansed.toFixed(2)}`);
  };
  const handleAmountChange = (event) => {
    if (isValidMoney(event.target.value)) {
      setAmount(event.target.value);
    }
  };
  const handleClick = () => {
    const testAmount = parseFloat(amount);
    if (isNaN(testAmount) || testAmount <= 0) {
      popToast('Selected amount is invalid. Please try again.', 'error');
    } else if (testAmount < 2.5) {
      popToast('$2.50 is the minimum donation size we can accept at this time.', 'error');
    } else if (testAmount > 10000) {
      popToast('$10,000 is the maximum individual gift size we can accept at this time.', 'error');
    } else {
      setOpen(true);
    }
  };
  const onClose = () => {
    setAmount('');
    setOpen(false);
  };

  return (
    <>
      <Layout centerContent>
        <div style={{ maxWidth: 1068 }}>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h3" className={classes.onboardingHeader} color="primary">Thanks for helping out!</Typography>
            <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 40 }}>
              All funds are used directly on providing our service. We have no salaries or
              paychecks in our organization. Any excess funds remaining at the end of our
              organization's mission will be redistributed to other high-impact 501(c)3's.
            </Typography>
          </div>

          <Paper elevation={8}>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
              <div style={{
                background: `url(${DonateImage}) center center no-repeat`,
                backgroundSize: 'cover',
                flex: 1,
                minHeight: 150,
                minWidth: 250
              }}>{' '}</div>
              <div className={classes.donationForm}>
                <Typography gutterBottom variant="h5">
                  Make a One-Time Gift
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Make a gift today and the impact may last for a generation.
                </Typography>
                <div style={{ flex: 1, minHeight: 16 }} />
                <TextField
                  label="Select Amount"
                  inputProps={{
                    step: 0.01
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                  }}
                  onBlur={handleAmountBlur}
                  onChange={handleAmountChange}
                  placeholder="0.00"
                  size="small"
                  type="number"
                  value={amount}
                />
                <Button
                  color="primary"
                  onClick={handleClick}
                  size="small"
                  style={{ marginTop: 8 }}
                  variant="contained">
                  Make Gift
                </Button>
              </div>
            </div>
          </Paper>

          <div className={classes.authSwitcher} style={{ flexWrap: 'wrap', marginTop: 32 }}>
            <Typography>Rather donate time than money?</Typography>
            <div className={classes.flexBreak} />
            <Button
              component={RouterLink}
              endIcon={<VolunteerIcon />}
              to="/signup/volunteer">
              Volunteer
            </Button>
          </div>

        </div>
      </Layout>
      <Elements stripe={stripePromise}>
        {open && (
          <DonationDialog amount={amount} onClose={onClose} popToast={popToast} />
        )}
      </Elements>
      {!me && <Footer />}
    </>
  );
}

export default withStyles(styles)(Donate);
