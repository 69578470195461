import About from './about';
import Donate from './donate';
import Goodbye from './goodbye';
import Index from './index';
import Login from './login';
import Logout from './logout';
import PatientRoutes from './patient/routes';
import Privacy from './privacy';
import ProviderRoutes from './provider/routes';
import Signup from './signup';
import Terms from './terms';
import Verify from './verify';
import VolunteerIndex from './volunteer/index';
import VolunteerRoutes from './volunteer/routes';

export default {
  'always': [
    { path: '/', component: Index, exact: true },
    { path: '/about', component: About },
    { path: '/donate', component: Donate },
    { path: '/goodbye', component: Goodbye },
    { path: '/privacy', component: Privacy },
    { path: '/terms', component: Terms },
  ],
  'loggedOut': [
    { path: '/volunteer', component: VolunteerIndex },
    { path: '/login', component: Login },
    { path: '/signup/:type?', component: Signup },
    { redirect: '/login' }
  ],
  'loggedIn': [
    { path: '/volunteer', component: VolunteerRoutes },
    { path: '/verify/:code?', component: Verify },
    { path: '/logout', component: Logout },
  ],
  'patient': [
    { path: '/patient', component: PatientRoutes },
    { redirect: '/patient' }
  ],
  'provider': [
    { path: '/provider', component: ProviderRoutes },
    { redirect: '/provider' }
  ],
  'volunteer': [
    { redirect: '/volunteer' }
  ],
};