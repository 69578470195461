import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SignupIcon from '@material-ui/icons/DoubleArrowOutlined';
import Layout from '../components/Layout';
import AuthContext from '../contexts/AuthContext';
import useFormHelper from '../hooks/useFormHelper';
import styles from '../styles/base';
import { useFormik, FormikTextField } from '../utils/formik';
import { LoginSchema } from '../utils/validation';

function Login({ classes, history }) {
  const { api, patchMe } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validateOnChange: true,
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.post('auth/login', { ...values });
      if (response) {
        patchMe(response);
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg } = useFormHelper(formik);

  return (
    <Layout centerContent>
      <div style={{ maxWidth: 600, textAlign: 'center' }}>
        <Typography variant="h3" className={classes.onboardingHeader} style={{ marginBottom: 8 }}>Login to your Account</Typography>
          <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
            <FormikTextField
              fullWidth
              label="Email or Cell Phone"
              name="login"
              formik={formik}
            />

            <FormikTextField
              fullWidth
              label="Password"
              type="password"
              name="password"
              formik={formik}
            />

            <Button
              type="submit"
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              variant="contained">Login</Button>

            <div className={classes.authSwitcher}>
              <Typography>Don't have an account yet?</Typography>
              <Button
                component={RouterLink}
                endIcon={<SignupIcon />}
                to="/signup">
                Sign Up
              </Button>
            </div>
          </form>
      </div>
    </Layout>
  );
}

export default withStyles(styles)(Login);
