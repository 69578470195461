import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Collapse,
  Typography,
} from '@material-ui/core';
import ClinicForm from '../../components/provider/ClinicForm';
import ContactPreferences from '../../components/provider/ContactPreferences';
import EmployerInfo from '../../components/provider/EmployerInfo';
import Layout from '../../components/Layout';
import VerificationForm from '../../components/VerificationForm';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import styles from '../../styles/base';

function ProviderSignup({ classes }) {
  const { api, me, patchMe } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [page, setPage] = useState(me && (!me.cell || me.cellVerified) ? 2 : 1);

  const onNextPage = async () => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  };

  const onComplete = async () => {
    const { response, error } = await api.post('users/onboarded');
    if (response) {
      patchMe(response);
    } else if (error) {
      popToast(error, 'error');
    } else {
      popToast('Something went wrong. Please try again.', 'error');
    }
  };
  
  return (
    <Layout onboarding centerContent>
      <div style={{ maxWidth: 600, textAlign: 'center' }}>
        <Collapse in={page === 1}>
          <Typography variant="h3" className={classes.onboardingHeader}>Verify Your Cell Number</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 32 }}>
            We sent a text message to {me.cell} with a unique verification code.
            Please enter the code below to confirm your cell number.
          </Typography>

          <VerificationForm onSave={onNextPage} skippable />
        </Collapse>

        <Collapse in={page === 2}>
          <Typography variant="h3" className={classes.onboardingHeader}>Employment Info</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 16 }}>
            None of your personal info will ever be shared outside of our organization.
            We use this info exclusively to verify your identity and communicate
            with you about appointments.
          </Typography>

          <EmployerInfo onSave={onNextPage} />
        </Collapse>

        <Collapse in={page === 3}>
          <Typography variant="h3" className={classes.onboardingHeader}>Your Vaccination Site</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 16 }}>
            Any information submitted here will only be visible to patients
            who accept an available vaccine slot at your site.
          </Typography>

          <ClinicForm onSave={onNextPage} />
          
          <Typography variant="caption">Note: If you work at multiple vaccination sites, you can add more later.</Typography>
        </Collapse>

        <Collapse in={page === 4}>
          <Typography variant="h3" className={classes.onboardingHeader}>Contact Preferences</Typography>
          <ContactPreferences onSave={onComplete} />
        </Collapse>
      </div>

    </Layout>
  );
}

export default withStyles(styles)(ProviderSignup);

