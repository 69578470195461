import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Layout from '../../components/Layout';
import { HealthRisk, OccupationRisk, HouseholdRisk, OtherRisk } from '../../components/patient/risk/index';
import styles from '../../styles/base';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  // TODO (later): transition effect?
  const { children, visible } = props;
  return (
    <div role="tabpanel" hidden={!visible}>
      {visible && (
        <Box p={3}>{children}</Box>
      )}
    </div>
  );
}

const tabs = [
  { url: 'health', label: 'Health', Component: HealthRisk },
  { url: 'occupation', label: 'Occupation', Component: OccupationRisk },
  { url: 'household', label: 'Household', Component: HouseholdRisk },
  { url: 'other', label: 'Other', Component: OtherRisk },
];

function Profile({ classes, history }) {
  let { page } = useParams();

  const [tab, setTab] = useState(0);
  useEffect(() => {
    const initialTab = tabs.findIndex(({ url }) => url === page);
    setTab(initialTab > -1 ? initialTab : 0);
  }, [page]);

  const onChangeTab = (event, tabIndex) => {
    history.push(`/patient/risks/${tabs[tabIndex].url}`);
  };

  const breadcrumbs = [
    { label: 'Dashboard', href: '/patient' },
    { label: 'Account', href: '/patient/account' },
    { label: 'Risk Profile' },
  ];

  return (
    <Layout hideDivider breadcrumbs={breadcrumbs}>
      <Tabs
        value={tab}
        onChange={onChangeTab}
        indicatorColor="primary"
        scrollButtons="auto"
        style={{ borderBottom: '1px solid #CCC' }}
        textColor="primary"
        variant="scrollable"
        aria-label="page tabs">
        {tabs.map(({ label }, x) => (
          <Tab key={label} label={label} {...a11yProps(x)} />
        ))}
      </Tabs>
      
      <Paper className={classes.tabPanelPaper}>
        {tabs.map(({ Component }, x) => (
          <TabPanel key={x} visible={tab === x}>
            <Component />
          </TabPanel>
        ))}
      </Paper>

    </Layout>
  );
};

export default withStyles(styles)(Profile);