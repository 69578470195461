import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Button,
  Collapse,
  Divider,
  Hidden,
  Link,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowIcon from '@material-ui/icons/ForwardOutlined';
import FaqIcon from '@material-ui/icons/ContactSupportOutlined';
import PhoneIcon from '@material-ui/icons/QuestionAnswerOutlined';
import SmileIcon from '@material-ui/icons/EmojiEmotions';
import VoicemailIcon from '@material-ui/icons/Voicemail';
import Layout from '../../components/Layout';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';

const outcomes = [
  {
    parent: null,
    question: 'Were you able to connect?',
    options: {
      'did_not_call': 'Did Not Call',
      'invalid': 'Wrong Number',
      'did_not_connect': 'No Answer No Voicemail',
      'vm': 'Left Voicemail',
      'gatekeeper': 'Left Message with Admin',
      'connected': 'Talked to Provider',
    }
  },
  {
    parent: 'connected',
    question: 'What was their level of interest?',
    options: {
      'not_interested': 'Not Interested',
      'referral': 'Interested, but it\'s not their decision',
      'curious': 'Interested, but wants to think about it',
      'intent': 'Going to sign up!',
    }
  },
  {
    parent: 'not_interested',
    question: 'Why not?',
    options: {
      'no_problem': 'Have Their Own Backup List',
      'no_vaccine': 'No Longer Have Any Vaccine',
      'other': 'Other Reason',
    }
  },
];
const finalQuestions = {
  'referral': 'Whose decision is it?',
  'other': 'What was their reasoning?'
};

const FAQs = [
  ['How do I know this is legitimate?', [
    `VaccineList was founded by a husband and wife team, who also happen to be an
    engineer and pediatrician, and who also happen to be a caretaker and a cancer patient.`,
    `Under her oncologist's guidance, she was delaying her next round of treatment
    until she could get vaccinated. So they frantically searched for an "extra" vaccine dose
    and called every vaccine provider they could until she finally lucked out a clinic that
    was about to throw their leftover dose away.`,
    `On the way home, they decided they need to help others in her boat do the same. So that's
    how VaccineList was born.`,
    `I don't know if that answers your question, if it would help you feel more comfortable, I
    can have one of the founders themselves call you to discuss. Would that help?`
  ]],
  ['How is provider information stored or shared?', [
    `All provider, clinic, and dose data is stored on an encrypted and firewalled server.`,
    `There are no listings or any publicly visible version of your data, and the only
    reason any data is ever shared is just to connect a patient and provider for a scheduled
    appointment.`,
    `At that point, the patient is provided with your clinic name, clinic address,
    and the contact phone and arrival instructions you provided for the appointment.
    And you are provided with the patient's name and phone number.`,
    `We take privacy very seriously, and there is no benefit to any more information being
    shared than this.`
  ]],
  ['Can I stop keeping my own waitlist?', [
    `That's the goal!`,
    `It doesn't hurt to keep a couple names available in case of an emergency,
    but in general, we recommend providers stop worrying about managing their
    own list.`,
    `You can even direct patients that call in about your waitlist to signup
    at VaccineList instead. It's better for the patient because they will
    then be on the waitlist for all VaccineList partners in the area at once,
    and it's better for you because you don't have to deal with all the note-taking
    and policy explanations and everything else.`
  ]],
  ['Is there a way to try it before dropping our existing waitlist?', [
    `Definitely, we actually recommend that so you feel confident before making
    the transition.`,
    `Signing up only take about 2 minutes and listing a dose takes less than a minute,
    so it's easy to try it out for a couple days without any risk or distraction.`,
  ]],
  ['How long does the matching process take?', [
    `This can vary significantly depending on the time of day, the amount of time until the
    appointment, and the patient characteristics in your area. Typically, we expect appointment
    confirmation within 3 - 15 minutes.`
  ]],
  ['How does it work for the patient?', [
    `When a patient signs up, they provide their cellphone number, complete a thorough
    availability survey, and complete a comprehensive risk profile.`,
    `When a dose is assigned to the waitlist in their area, we automatically build a list
    of available patients, rank them from most at-risk to least at-risk, and start texting
    one patient at a time to offer them the appointment.`,
    `Each patient has a couple minute head start before the next patient is also offered
    the appointment. But the first patient to confirm their ability to make the appointment
    is the one to get it.`,
    `This strategy gets the quickest possible response for providers while still prioritizing the
    most at-risk in the community.`
  ]],
  ['How can you make sure a patient is on time?', [
    `When an appointment is offered to a patient, they are first required to confirm their
    current location and planned method of travel. Then, we use a Google Maps connection
    to calculate their travel time in current traffic conditions, and confirm they are
    able to leave in time to safely make the appointment.`,
  ]],
  ['How is a patient\'s at-risk status evaluated?', [
    `Primarily using the CDC's published guidelines for states, but we use some additional
    criteria for tie-breaking as well, such as occupational exposure, shared household risk,
    and caretaker status.`
  ]],
  ['How many patients are registered in my area?', [
    `I don't have the exact number, but you wouldn't have popped up on my call list
     unless there weren't enough patients in the area to fulfill the waitlists of the
     providers in the area.`,
    `That said, if you list a dose at an unpopular time or something unusual, it is
     still possible it could take a little longer or even be impossible to find an 
     available patient. In those cases, we'll warn you once we know we are having
     trouble and then let you know if we actually run out of options. That doesn't
     really happen, but the software supports it because we don't want to leave you
     in a lurch worried about not having time to find another option.`,
  ]],
  ['How is patient information stored or shared?', [
    `All patient data is stored on an encrypted and firewalled server, and information
    is only ever shared with a provider the patient has scheduled an appointment with.`,
  ]],
  ['Is VaccineList legal?', [
    `As of today, we are not aware of any jurisdictions where leftover doses
    are not allowed to be assigned to the most eligible candidates (especially
    when the alternative is vaccine waste).`,
    `We believe we've created the most reliable way to do just that.`,
    `We do encourage you to review the vaccine provider agreement in your state
    if you are unsure of the rules.` 
  ]],
  ['How does VaccineList make money?', [
    `We don't have any fees for either patients or providers. We are a nonprofit
    providing a free community service, supported by volunteers and donations.`
  ]],
];

const useStyles = makeStyles((theme) => ({
  arrowInstructionBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    '&$backgrounded': {
      background: theme.palette.secondary.main,
      color: 'white',
      margin: `${theme.spacing(3)}px ${theme.spacing(-2.5)}px ${theme.spacing(-2.5)}px`,
      padding: '15px 20px',
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap'
    }
  },
  backgrounded: {},
  bigPhone: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem'
    }
  },
  blocking: {
    color: theme.palette.primary.dark,
    display: 'block',
    fontWeight: 500,
    opacity: 1,
    '&$inlineBlocking': {
      display: 'inline',
      fontWeight: 400
    }
  },
  flexBreak: {
    height: theme.spacing(),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  flexBreakMd: {
    height: theme.spacing(0.5),
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    }
  },
  inlineBlocking: {},
  instructions: {
    background: theme.palette.primary.dark,
    borderRadius: 4,
    color: 'white',
    float: 'right',
    padding: '2px 6px'
  },
  script: {
    '& li, & p, & br': {
      marginBottom: theme.spacing(),
      marginTop: theme.spacing(),
    }
  },
  scriptBullets: {
    margin: 0,
  },
  scriptHeader: {
    marginTop: 20,
    '&:first-child': {
      marginTop: 0 
    }
  }
}));

export default function CallPage({ history }) {
  const classes = useStyles();
  const { api, me } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [outcome, setOutcome] = useState([]);
  const [notes, setNotes] = useState('');
  const [prospect, setProspect] = useState(null);
  const [tab, setTab] = useState(0);

  const xsDown = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const breadcrumbs = [
    { label: 'Volunteer', href: '/volunteer' },
    { label: 'Action Center', href: '/volunteer' },
    { label: 'Dialing for Doses' },
  ];

  const fetchProspect = async () => {
    setLoading(true);
    const { response, error } = await api.get('volunteer/call');
    if (response) {
      setProspect(response);
    } else {
      popToast(error || 'Something went wrong. Please try again.', 'error');
      setProspect(null);
    }
    setLoading(false);
  };

  const handleOutcome = (groupIndex, value) => () => {
    const newOutcome = outcome.slice(0, groupIndex + 1);
    newOutcome[groupIndex] = value;
    setOutcome(newOutcome);

    api.patch(`volunteer/call/${prospect.id}`, { outcome: newOutcome });
  };

  const handleNoteChange = (event) => {
    setNotes(event.target.value || '');
  };

  const handleSave = (keepGoing) => async () => {
    const { response, error } = await api.patch(`volunteer/call/${prospect.id}`, { outcome, notes, unlock: true });
    if (response) {
      setNotes('');
      setOutcome([]);
      if (keepGoing) {
        fetchProspect();
      } else {
        history.push('/volunteer');
      }
    } else {
      popToast(error || 'Something went wrong. Please try again.', 'error');
    }
  };

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  const callbackNumber = me.cell || (<i className={clsx([classes.blocking, classes.inlineBlocking])}>YOUR PHONE NUMBER</i>);

  // TODO (v2): batch size w/ progress bar
  let finalQuestion;
  const outcomePeek = outcome.length > 0 ? outcome[outcome.length - 1] : null;
  if (outcomePeek) {
    if (!outcomes.find(({ parent }) => parent === outcomePeek)) {
      finalQuestion = finalQuestions[outcomePeek] || 'Any Other Notes?';
    }
  };

  const formRef = useRef(null);
  const scrollToForm = () => formRef.current.scrollIntoView({ behavior: 'smooth' });

  {/* TODO (v2): Call #X of Y / progressbar */}
  return (
    <Layout breadcrumbs={breadcrumbs} suppressPageWarnings>
      <div ref={formRef} style={{ position: 'absolute', pointerEvents: 'none', visibility: 'hidden' }} />
      <Collapse in={Boolean(prospect)}>
        <Paper>
          <div style={{ padding: 16, width: '100%', marginBottom: 24 }}>
            <Paper elevation={4} style={{ padding: '24px 24px 16px', background: '#ffffef', width: '100%' }}>
              {prospect && (
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                  <div>
                    <Typography variant="h6" display="inline" style={{ fontWeight: 'bold' }}>{prospect.name}{' '}</Typography>
                    {prospect.website && (
                      <Hidden mdDown>
                        <Typography variant="caption">{prospect.website}</Typography>
                      </Hidden>
                    )}
                    {prospect.address && (
                      <Typography display="block" variant="caption">
                        {prospect.address}
                      </Typography>
                    )}
                    {prospect.website && (
                      <Hidden lgUp>
                        <Typography display="block" variant="caption">{prospect.website}</Typography>
                      </Hidden>
                    )}
                  </div>
                  <Typography className={classes.bigPhone} variant="h3">{prospect.phone}</Typography>
                </div>
              )}
            </Paper>
            
            {outcomes.map((group, groupIndex) => {
              const optionTally = Object.keys(group.options).length;
              return (
                <Collapse key={group.question} in={group.parent === null || outcome.includes(group.parent)}>
                  <div style={{ marginTop: 16 }}>
                    <Typography variant="h6">{group.question}</Typography>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                      {Object.keys(group.options).map((id, x) => {
                        return (
                          <>
                            <Button
                              key={id}
                              color="primary"
                              disabled={outcome[groupIndex] === id}
                              onClick={handleOutcome(groupIndex, id)}
                              style={{ flexGrow: 1, margin: 2, minWidth: 164, width: `calc(${100/optionTally}% - 4px)` }}
                              variant={(outcome.length <= groupIndex || outcome[groupIndex] === id) ? "contained" : "outlined"}>
                              {group.options[id]}
                            </Button>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </Collapse>
              );
            })}
            <Collapse in={finalQuestion}>
              <div style={{ marginTop: 16 }}>
                <Typography variant="h6">{finalQuestion}</Typography>
                <TextField
                  fullWidth
                  multiline
                  onChange={handleNoteChange}
                  placeholder="Add your notes..."
                  rows={2}
                  value={notes}
                  variant="outlined" />
              </div>
            </Collapse>
            <Divider style={{ marginBottom: 16, marginTop: 16, }} />
            <div style={{ textAlign: 'right' }}>
              <Button
                color="default"
                disabled={!finalQuestion}
                onClick={handleSave(false)}
                variant="contained"
                style={{ marginRight: 4 }}>Save & Close</Button>
              <Button
                color="secondary"
                disabled={!finalQuestion}
                onClick={handleSave(true)}
                variant="contained">Save & Continue</Button>
            </div>
          </div>
        </Paper>
      </Collapse>

      <Collapse in={!(loading || Boolean(prospect))}>
        <Paper style={{ padding: '24px 24px 16px', width: '100%', marginBottom: 24 }}>
          <Typography gutterBottom variant="h4">Getting Started</Typography>
          <Typography gutterBottom>
            Remember, VaccineList is a huge help for clinics, it is a great thing for the community,
            and it's offered as a free public service. A suggested call script, voicemail script,
            and a list of some questions frequently asked asked by providers are below.{' '}
            <u>Please read all three fully before making your first call.</u>
          </Typography>
          <Typography gutterBottom>
            We also encourage you to read <Link href="/about" target="_blank">our origin story</Link> if you have not already.
          </Typography>
          <Typography gutterBottom>
            If you have a friend you can do a practice call with first, that's a great way to warm up.
          </Typography>
          <Typography>
            Be sincere, positive, and persistent; we can do this!
          </Typography>
          
          <div className={classes.arrowInstructionBox}>
            <Typography variant="subtitle2">
              When you are ready to start, click here to be assigned a clinic's contact information
            </Typography>
            <div className={classes.flexBreak} />
            <ArrowIcon style={{ margin: '0 8px', opacity: 0.4 }} />
            <Button color="secondary" onClick={fetchProspect} variant="contained" style={{ whiteSpace: 'nowrap' }}>Start Calling</Button>
          </div>
        </Paper>
      </Collapse>

      <AppBar position="static" style={{ backgroundColor: '#333333' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          indicatorColor="secondary"
          variant="fullWidth">
          <Tab icon={<PhoneIcon />} label="Call Script" />
          <Tab icon={<VoicemailIcon />} label={xsDown ? "VM Script" : "Voicemail Script"} />
          <Tab icon={<FaqIcon />} label="FAQs" />
        </Tabs>
      </AppBar>

      <Paper style={{ padding: 20 }}>
        {tab === 0 && (
          <>
            <Typography className={classes.instructions} variant="button">
              <span>Note: colorful text is for thinking not speaking</span>
              <SmileIcon fontSize="small" style={{ marginLeft: 4, verticalAlign: 'text-bottom' }} />
            </Typography>
            <div className={classes.script}>
              <Typography className={classes.scriptHeader} variant="h6">Getting to the right person</Typography>
              <Typography>
                Hi, I was calling to ask a couple questions about the COVID vaccine. Did I get the right number?
              </Typography>
              <Typography>
                <i className={classes.blocking}>If no, and they don't know who to forward your call to...</i>
                <ul className={classes.scriptBullets}>
                  <li>
                    <i className={classes.blocking}>(If town hall, county office, or public health office...)</i>
                    Can you try connecting me to the public health nurse?
                  </li>
                  <li>
                    <i className={classes.blocking}>(If hospital or retail chain or pharmacy...)</i>
                    Can you try connecting me to the pharmacy?
                  </li>
                  <li>
                    <i className={classes.blocking}>(Otherwise...)</i>
                    Can you try connecting me to the manager?
                  </li>
                </ul>
              </Typography>

              <Typography className={classes.scriptHeader} variant="h6">Getting Started</Typography>
              <Typography>
                Hi, my name is {me.firstName}, and I'm a volunteer with the VaccineList Foundation.
              </Typography>
              <Typography>
                We are a nonprofit that provides free software to vaccine providers to help them manage their waitlist automatically.
                How are you currently handling extra doses? Do you keep your own waitlist, is it first-come first-served, or have you not really had anyone missing appointments?
              </Typography>

              <Typography>
                <ul className={classes.scriptBullets}>
                  <li>
                    <i className={classes.blocking}>If they manage their own waitlist...</i>
                    <Typography>
                      That's great, not all providers are motivated enough to pull that off!
                      We have heard it described by other providers as a "herculean effort"
                      at the end of each day to make sure no doses go to waste.
                    </Typography>
                    <Typography>
                      So that's one of the things our free service helps with -- to eliminate
                      those end-of-day phone calls and give you some time back in your day,
                      but without compromising the quality of your vaccine management.
                      <i className={clsx([classes.blocking, classes.inlineBlocking])}>⇨ Jump to "How It Works"</i>
                    </Typography>
                  </li>
                  <li>
                    <i className={classes.blocking}>If its first-come first-served...</i>
                    <Typography>
                      We talk to a lot of clinics handling it that way, and that's a great way
                      to keep from having to throw away doses, so we are all for it!
                    </Typography>
                    <Typography>
                      The goal of our service is to keep things just as simple for you as
                      "first-come first-served" but to help make sure the people ending up at
                      the front of that line are actually the most at-risk people in your community.
                      <i className={clsx([classes.blocking, classes.inlineBlocking])}>⇨ Jump to "How It Works" example</i>
                    </Typography>
                  </li>
                  <li>
                    <i className={classes.blocking}>If they say they don't have a problem...</i>
                    <Typography>
                      That's amazing! We don't hear that from many providers we talk to. If you
                      don't mind me asking, how do you manage that?
                    </Typography>
                    <i className={clsx([classes.blocking, classes.inlineBlocking])}>
                      Agree with what they do, ask questions, try to connect to how VaccineList
                      could help improve upon it either by better patient prioritization or by
                      saving them time or stress.
                    </i>
                  </li>
                  <li>
                    <i className={classes.blocking}>If they don't have any vaccine right now...</i>
                    Got it. Have you heard anything from the State about when to expect more?
                    <i className={clsx([classes.blocking, classes.inlineBlocking])}>Please add this to the call notes!</i>
                  </li>
                  <li>
                    <i className={classes.blocking}>If some other response...</i>
                    <i className={clsx([classes.blocking, classes.inlineBlocking])}>
                      Agree with what they do, and then try to connect to how VaccineList could help improve upon it.
                    </i>
                  </li>
                </ul>
              </Typography>

              <Typography className={classes.scriptHeader} variant="h6">How It Works (for the Provider)</Typography>
              <Typography>
                We run a centralized waitlist as a community service. When patients sign up, the software gives their profile a "risk score" and collects details on how far and when they can travel. When providers have a dose they need to find a "backup" for, we automatically match a patient to it. You don't have to make phone calls, or wave someone off the sidewalk, or stress about any vaccine being wasted. Can I give you an example?
              </Typography>
              <Typography>
                <b>Here's an example:</b>
                <ul className={classes.scriptBullets}>
                  <li>You have one or more extra doses on any given day, whether it's because of a no-show or expiring vial or whatever.</li>
                  <li>You log into your account, select how many doses you want to allocate to the waitlist for the day, and what time you want each waitlist patient to arrive. It takes less than a minute.</li>
                  <li>And that's it, you can go back to work. We'll send you a message with the name and phone number of the patient that we have matched to the dose.</li>
                </ul>
                How does that compare to what you are doing right now?
              </Typography>
              <Typography>
                <b>What's happening behind the scenes is...</b>
                <ul>
                  <li>We've already started signing up patients in the area. When they sign up, the software assigns them a COVID-risk score based on a survey they fill out on signup.</li>
                  <li>When you allocate a dose to the waitlist, our software finds all the patients in your area that could make your appointment time, then starts contacting them one by one from highest-risk to lowest-risk until someone confirms the appointment.</li>
                </ul>
              </Typography>

              <Typography className={classes.scriptHeader} variant="h6">Conclusion</Typography>
              <Typography>
                VaccineList's mission is to save you time and stress while also helping high-risk patients in your community, and we are a nonprofit so it's all free. Does it sound like something that might be helpful to your team?
                <ul>
                  <li>
                    <i className={classes.blocking}>If yes...</i>
                    Alright, it's really easy to sign up, you can even do it from your phone. Just go to vaccinelist.org/signup
                    and create an account. You can start using the waitlist pretty much immediately, and I'll reach back out in
                    a day or two to check-in.
                  </li>
                  <li>
                    <i className={classes.blocking}>If no...</i>
                    No problem. We really want to be providing a valuable service, so if you don't mind me asking, why not?
                    <i className={clsx([classes.blocking, classes.inlineBlocking])}>(Be polite, make call notes, and thank them for their time.)</i>
                  </li>
                  <li>
                    <i className={classes.blocking}>If not sure...</i>
                    What questions can I answer for you that might help?
                  </li>
                </ul>
              </Typography>
            </div>
            
            {prospect && (
              <div className={clsx([classes.arrowInstructionBox, classes.backgrounded])}>
                <Typography color="inherit" variant="h6">
                  Don't forget to log that your call at top of the page...
                </Typography>
                <div className={classes.flexBreak} />
                <ArrowIcon style={{ margin: '0 8px', opacity: 0.6 }} />
                <Button color="default" onClick={scrollToForm} variant="contained" style={{ whiteSpace: 'nowrap' }}>Scroll to Top</Button>
              </div>
            )}
          </>
        )}

        {tab === 1 && (
          <div className={classes.script}>
            <Typography>
              Hi, this is {me.firstName} from VaccineList.org. We operate a centralized COVID vaccine waitlist
              {prospect && prospect.state ? ` in ${prospect.state}` : ''} that includes your area.
            </Typography>
            <Typography>
              We are a nonprofit, this isn't a sales call, but I do need to connect you briefly at your earliest convenience.
            </Typography>
            <Typography>
              Please call me back at {callbackNumber}. Again, it's {me.firstName} at {callbackNumber}.
            </Typography>
            <Typography>
              Thanks so much!
            </Typography>
          </div>
        )}

        {tab === 2 && (
          <div>
            {FAQs.map(([Q, As]) => (
              <Accordion key={Q}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography style={{ fontSize: '1rem', fontWeight: 500 }}>{Q}</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ flexDirection: 'column' }}>
                  {As.map((A, x) => (
                    <Typography key={x} style={{ marginBottom: 8 }}>{A}</Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </Paper>
    </Layout>
  );
}