import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Hidden,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import MatchDetails from '../../components/patient/MatchDetails';
import MatchForm from '../../components/patient/MatchForm';
import Layout from '../../components/Layout';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import styles from '../../styles/base';

const getMatchTitle = (match) => {
  switch (match.status) {
    case 'COULD_NOT_MATCH':
      return `Dose #${match.refId} was released back to the provider.`;
    case 'MISSED':
      return `Dose #${match.refId} has already been claimed by another patient.`;
    case 'PATIENT_CANCELLED':
    case 'REJECTED':
      return `You have declined dose #${match.refId}.`;
    case 'WITHDRAWN':
      return `Dose #${match.refId} was withdrawn by the provider unexpectedly.`;
    default: return '';
  }
};
const getMatchSubtitle = (match) => {
  switch (match.status) {
    case 'COULD_NOT_MATCH':
      return `We didn't receive any responses fast enough to meet their scheduling criteria.`;
    case 'MISSED':
      return `You were the ${ordinalize(match.offerPosition)} patient offered this dose `
        + `but the ${ordinalize(match.matchPosition)} patient was quicker to respond.`;
    case 'WITHDRAWN':
      return `This doesn't happen often, so we will follow up to figure out what went wrong.`;
    default: return '';
  }
};

const prettyStatus = (status) => {
  switch (status) {
    case 'COULD_NOT_MATCH':
      return `Returned to Provider`;
    case 'MISSED':
      return `Missed by Patient`;
    case 'PATIENT_CANCELLED':
      return `Cancelled by Patient`;
    case 'REJECTED':
      return `Declined by Patient`;
    case 'WITHDRAWN':
      return `Cancelled by Provider`;
    default: return '';
  }
}


const ordinalize = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return `${n}${(s[(v-20)%10] || s[v] || s[0])}`;
}


function PatientIndex({ classes }) {
  const { api, me, patchMe } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const breadcrumbs = [
    { label: 'Dashboard', href: '/patient' },
    { label: 'Home' },
  ];

  const refreshMatches = async () => {
    const { response, error } = await api.get(`matches`);
    if (response) {
      patchMe({ matches: response });
    } else if (error) {
      popToast(error, 'error');
    }
  };
  useEffect(refreshMatches, []);

  const myMatches = (me.matches || []).sort((a, b) => {
    return a.offeredAt > b.offeredAt ? -1 : 1;
  });
  const activeMatch = myMatches.find((m) => ['MATCHING', 'MATCHED'].includes(m.status));
  return (
    <Layout centerContent breadcrumbs={breadcrumbs}>
      {activeMatch && (
        <>
          {activeMatch.status === 'MATCHED' && <MatchDetails match={activeMatch} refreshMatches={refreshMatches} />}
          {activeMatch.status === 'MATCHING' && <MatchForm doseId={activeMatch.doseId} refreshMatches={refreshMatches} />}
        </>
      )}
      {!activeMatch && (
        <div style={{ textAlign: 'center', maxWidth: 800 }}>
          <Paper className={classes.paddedPaper} style={{ marginBottom: 60 }}>
            {myMatches.length === 0 && (
              <>
                <Typography variant="h3" color="primary">You are ready to match!</Typography>
                <Typography variant="h6" className={classes.onboardingSubheader}>
                  Doses are still extremely limited, but if you do match to an available dose, more information
                  will become available on this screen at that time. We will notify you of the match via text
                  message (SMS) at{' '}<u>{me.cell}</u>. Please respond quickly.
                </Typography>
              </>
            )}
            {myMatches.length > 0 && (
              <div style={{ textAlign: 'left' }}>
                <Alert elevation={3} severity="error" style={{ paddingBottom: 0 }}>
                  <AlertTitle>{getMatchTitle(myMatches[0])}</AlertTitle>
                  <p className={classes.alertParagraph}>{getMatchSubtitle(myMatches[0])}</p>
                  <p className={classes.alertParagraph}>
                    Here's the good news -- users that get at least one dose offer tend to get multiple dose offers.
                    So, we know this time was disappointing, but hang in there, and hopefully we'll try again soon.
                  </p>
                </Alert>

                <Table size="small" style={{ marginTop: 24 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>Dose History</TableCell>
                      <Hidden xsDown>
                        <TableCell>Status</TableCell>
                        <TableCell>Offer Position</TableCell>
                      </Hidden>
                      <TableCell>Offer Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myMatches.map((match) => (
                      <TableRow key={match.doseId}>
                        <TableCell>
                          #{match.refId}
                          <Hidden smUp>
                            <Typography display="block" variant="caption" style={{ whiteSpace: 'nowrap' }}>
                              {prettyStatus(match.status)}
                            </Typography>
                          </Hidden>
                        </TableCell>
                        <Hidden xsDown>
                          <TableCell>{prettyStatus(match.status)}</TableCell>
                          <TableCell>{match.offersTotal > 0 ? `${match.offerPosition} of ${match.offersTotal}` : '—'}</TableCell>
                        </Hidden>
                        <TableCell>{moment(match.offeredAt).calendar()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </Paper>

          <Typography variant="h4" color="primary">
            What else can I do?
          </Typography>
          <Typography variant="body1" color="textSecondary">
            The more clinics we have using our service, the more doses will become available to people like
            you. You can help us connect to more doses in your area by checking out our 
            {' '}
            <Link component={RouterLink} to="/volunteer">volunteer work</Link>
            {' '}
            or by
            {' '}
            <Link component={RouterLink} to="/donate">donating to the cause</Link>.
          </Typography>
        </div>
      )}
    </Layout>
  );
}

export default withStyles(styles)(PatientIndex);
