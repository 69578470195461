import * as Yup from 'yup';

const days = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa'];
//const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9])(?=.*[0-9]).{8,}$/;
//const passwordMessage = 'Must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z]).{8,}$/;
const passwordMessage = 'Must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, and one number or special character.';
const cellRegex = /^(?=.*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9].*[0-9])/;

let allHourValue = 0;
const allHourTimes = [];
['am', 'pm'].forEach((pm) => {
  ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'].forEach((hr) => {
    ['00', '30'].forEach((min) => {
      allHourTimes.push({
        value: allHourValue,
        label: `${hr}:${min}${pm}`
      });
      allHourValue += 0.5;
    });
  });
});
allHourTimes.push({ value: 24, label: '12:00am' });

export const LoginSchema = Yup.object().shape({
  login: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

export const SignupSchema = Yup.object().shape({
  type: Yup.string().oneOf(['patient', 'provider', 'volunteer']).required(),
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  jobTitle: Yup.string()
    .when('type', {
      is: (type) => ['volunteer'].includes(type),
      then: Yup.string().required('Required')
    }),
  email: Yup.string()
    .email('Invalid email address')
    .when('type', {
      is: (type) => ['provider','volunteer'].includes(type),
      then: Yup.string().required("Required")
    }),
  cell: Yup.string()
    .matches(cellRegex, 'Invalid cell number')
    .when('type', {
      is: 'patient',
      then: Yup.string().required("Required")
    }),
  password: Yup.string()
    .matches(passwordRegex, passwordMessage)
    .required('Required')
});

export const AccountSchema = Yup.object().shape({
  type: Yup.string().oneOf(['patient', 'provider', 'volunteer']).required(),
  firstName: Yup.string()
    .required('Required'),
  lastName: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .when('type', {
      is: (type) => ['provider','volunteer'].includes(type),
      then: Yup.string().required("Required")
    }),
  cell: Yup.string()
    .matches(cellRegex, 'Invalid cell number')
    .when('type', {
      is: 'patient',
      then: Yup.string().required("Required")
    }),
  employer: Yup.string()
    .when('type', {
      is: 'provider',
      then: Yup.string().required("Required")
    }),
  jobTitle: Yup.string()
    .when('type', {
      is: (type) => ['provider','volunteer'].includes(type),
      then: Yup.string()  // TODO: is required for volunteer on signup, why not here?
    }),
});

export const ReverifySchema = Yup.object().shape({
  type: Yup.string().oneOf(['cell', 'email']).required("Required"),
  value: Yup.string()
    .when('type', {
      is: 'cell',
      then: Yup.string()
        .matches(cellRegex, 'Invalid cell number')
        .required('Required')
    })
    .when('type', {
      is: 'email',
      then: Yup.string()
        .email('Invalid email address')
        .required('Required')
    })
});

export const VerificationSchema = Yup.object().shape({
  type: Yup.string().oneOf(['cell', 'email']).required("Required"),
  code: Yup.string().required("Required")
});

export const VolunteerSchema = Yup.object().shape({
  specialty: Yup.string(),
  name: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  profession: Yup.string()
    .required('Required'),
  location: Yup.string()
    .required('Required'),
  message: Yup.string(),
});

const delays = [5, 10, 15, 20, 30, 45, 60, 120, 180, 240];
export const PatientGeographyConstants = {
  days,
  hours: [1, 2, 3, 4],
  miles: [5, 10, 15, 25, 50, 100, 250],
  minutes: delays.map((d) => ({
    value: d,
    label: d < 60 ? `${d} minutes` : `${d/60} hour${d > 60 ? 's' : ''}`
  })),
  times: allHourTimes
}

export const PatientGeographySchema = Yup.object().shape({
  address: Yup.string().required('Address is required'),
  geocode: Yup.array()
    .of(Yup.number())
    .length(2)
    .required('Address data is unavailable. Please try again.'),
  days: Yup.array()
    .of(
      Yup.string().oneOf(PatientGeographyConstants.days)
    )
    .min(1, 'Available days selection is required.')
    .max(7)
    .required('Available days selection is required.'),
  startTime: Yup.number()
    .oneOf(PatientGeographyConstants.times.map(({ value }) => value))
    .required('Available hours selections are required.'),
  stopTime: Yup.number()
    .oneOf(PatientGeographyConstants.times.map(({ value }) => value))
    .moreThan(Yup.ref('startTime'), 'Check that your available hours are in the right order.')
    .required('Available hours selections are required.'),
  distance: Yup.number()
    .oneOf(PatientGeographyConstants.miles)
    .required('Willing to travel distance selection is required.'),
  delay: Yup.number()
    .oneOf(PatientGeographyConstants.minutes.map(({ value }) => value))
    .required('Departure delay selection is required.'),
});

const maxBirthday = new Date();
maxBirthday.setFullYear(maxBirthday.getFullYear() - 16);

export const PatientRisksConstants = {
  maxBirthday,
  conditionBuckets: {
    healthRisksSevere: [
      'Cancer (active)',
      'Chronic Kidney Disease',
      'COPD',
      'Cystic Fibrosis',
      'Diabetes (Type 2)',
      [
        'Heart Conditions (severe)',
        'i.e. Heart Failure, Coronary Artery Disease (severe), Cardiomyopathy, etc'
      ],
      [
        'Immunosuppressed (active)',
        'i.e. due to cortisteroids or other medication',
      ],
      'Pulmonary Fibrosis',
      [
        'Severe Obesity',
        'BMI > 40 kg/m²'
      ],
      'Sickle Cell Disease'
    ],
    healthRisksMixed: [
      'Cancer (in remission)',
      'Cerebrovascular Disease',
      'Down Syndrome',
      'Hypertension (uncontrolled)',
      [
        'Immunocompromised',
        'i.e. due to bone marrow / stem cell transplant, genetic condition, HIV, or other immune deficiencies',
      ],
      [
        'Obesity',
        '30 kg/m² < BMI < 40 kg/m²'
      ],
      'Other Heart Conditions',
      'Pregnancy',
      'Thalassemia'
    ],
    healthRisksLimited: [
      'Asthma (moderate to severe)',
      'Diabetes (Type 1)',
      'Inherited metabolic disorders',
      'Liver Disease',
      [
        'Neurologic Conditions',
        'i.e. dementia'
      ],
      'Other Blood Disorders',
      'Other Chronic Lung Diseases',
      'Other Vascular Disorders',
      [
        'Overweight',
        '25 kg/m² < BMI < 30 kg/m²'
      ],
    ]
  },
  workerTallies: ['zero', '1 - 9', '10+']
};

export const PatientHealthRisksSchema = Yup.object().shape({
  birthday: Yup.date()
    .max(PatientRisksConstants.maxBirthday, 'You must be at least 16 years of age to receive a vaccine.')
    .required('Birthday is required.'),
  healthRisksSevere: Yup.number()
    .oneOf([0, 1, 2])
    .required('Health risks selections are required.'),
  healthRisksMixed: Yup.number()
    .oneOf([0, 1, 2])
    .required('Health risks selections are required.'),
  healthRisksLimited: Yup.number()
    .oneOf([0, 1, 2])
    .required('Health risks selections are required.'),
  covid: Yup.boolean()
});

export const PatientOccupationRisksSchema = Yup.object().shape({
  healthcare: Yup.boolean(),
  publicMembers: Yup.number()
    .oneOf([0, 1, 2])
    .required(),
  coworkers: Yup.number()
    .oneOf([0, 1, 2])
    .required(),
  wfh: Yup.boolean(),
});

export const PatientHouseholdRisksSchema = Yup.object().shape({
  multigenerational: Yup.boolean(),
  caretaker: Yup.boolean(),
  soleCaretaker: Yup.boolean(),
  otherSoleCaretaker: Yup.boolean(),
  parent: Yup.boolean(),
});

export const PatientOtherRisksSchema = Yup.object().shape({
  other: Yup.string(),
});

export const ProviderEmployerInfoSchema = Yup.object().shape({
  employer: Yup.string().required('Required'),
  jobTitle: Yup.string()
});

const reminderTimes = allHourTimes.filter(({ value }) => {
  return value >= 6 && value <= 20;
});

const remindersShape = {};
days.forEach((d) => {
  remindersShape[d] = Yup.number().oneOf([
    '', ...reminderTimes.map(({ value }) => value)
  ])
});

export const ProviderRemindersConstants = {
  reminderDays: days,
  reminderTimes
};

export const ProviderRemindersSchema = Yup.object().shape({
  preferSMS: Yup.boolean(),
  reminders: Yup.object().shape(remindersShape)
});

export const ClinicSchema = Yup.object().shape({
  address: Yup.string().required('Required'),
  geocode: Yup.array()
    .of(Yup.number())
    .length(2)
    .required('Address data is unavailable. Please try again.'),
  name: Yup.string().required('Required'),
  affiliation: Yup.string(),
  phone: Yup.string().required('Required'),
  arrivalInstructions: Yup.string().required('Required'),
});

export const DoseConstants = {
  apptTypes: {
    'at': 'Exactly at',
    'before': 'Anytime before',
  },
  apptTimes: allHourTimes,
  status: {
    MATCHING: 'MATCHING',
    COULD_NOT_MATCH: 'COULD_NOT_MATCH',
    WITHDRAWN: 'WITHDRAWN', // might be before or after match
    MATCHED: 'MATCHED',
    PATIENT_CANCELLED: 'PATIENT_CANCELLED',
  }
};

export const DoseSchema = Yup.object().shape({
  clinic: Yup.string()
    .required('Clinic selection is required.'),
  apptType: Yup.string()
    .oneOf(Object.keys(DoseConstants.apptTypes))
    .required('Appointment type is required.'),
  apptTime: Yup.number()
    .oneOf(DoseConstants.apptTimes.map(({ value }) => value))
    .required('Appointment time is required.'),
  contactNumber: Yup.string()
    .matches(cellRegex, 'A valid contact phone number is required.')
    .required('A contact phone number is required.'),
  arrivalInstructions: Yup.string()
    .required('Arrival instructions are required.'),
  tally: Yup.number()
    .min(1, 'At least one dose should be selected.')
    .required('Required'),
});
