import { useContext, useEffect, useState } from 'react';
import ToastContext from '../contexts/ToastContext';

export default function useFormHelper(formik, fieldErrorMode = false) {
  const [wasSubmitting, setWasSubmitting] = useState(false);

  const { popToast } = useContext(ToastContext);

  useEffect(() => {
    if (formik.isSubmitting) {
      setWasSubmitting(true);
    } else if (wasSubmitting) {
      setWasSubmitting(false);
      if (fieldErrorMode) {
        if (Object.keys(formik.errors).length) {
          popToast(
            'There is a problem with your submission. Please review and try again.',
            'error'
          );
        }
      } else {
        if (Object.values(formik.errors).length) {
          let error = Object.values(formik.errors)[0];
          if (typeof error === 'object') {
            error = Object.values(error)[0];
          }
          popToast(error, 'error');
        }
      }
    }
  }, [formik.isSubmitting]);

  return {
    setErrorMsg: (error) => popToast(error, 'error'),
    setSuccessMsg: (custom, duration) => popToast(custom || 'Changes saved.', 'success', duration || 2500)
  };
}