import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Api from '../utils/api';

const LS_USER_KEY = 'me';

const getUserFromStorage = () => {
  const userObjStr = localStorage.getItem(LS_USER_KEY);
  if (userObjStr) {
    try {
      return JSON.parse(userObjStr);
    } catch {}
  }
  return null;
}

const AuthContext = React.createContext();
export default AuthContext;

const api = new Api((getUserFromStorage() || {}).token);
export function AuthProvider({ children }) {
  const [me, setMe] = useState(getUserFromStorage);

  function syncFromLocalStorage() {
    const myUser = getUserFromStorage();
    api.setToken(myUser.token);
    setMe(myUser);
  }

  // NOTE: this only actually is triggered when localStorage changes
  //  happen in a different tab/window
  useEffect(() => {
    window.addEventListener('storage', syncFromLocalStorage);
    return () => {
      window.removeEventListener('storage', syncFromLocalStorage);
    }
  }, []);
  
  const patchMe = (mePatch) => {
    const newMe = {
      ...(me || {}),
      ...mePatch
    };
    
    api.setToken(newMe.token);
    localStorage.setItem(LS_USER_KEY, JSON.stringify(newMe));
    setMe(newMe);
  };

  const logout = async () => {
    await api.post('users/logout');

    api.setToken('');
    localStorage.removeItem(LS_USER_KEY);
    setMe(null);
  };

  const context = {
    api,
    me: cloneDeep(me),
    patchMe: patchMe,
    logout
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
    </AuthContext.Provider>
  );
}
