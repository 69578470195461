import { createMuiTheme } from '@material-ui/core/styles';

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#ffffff'
    }
  },
  overrides: {

  }
});

export default theme;