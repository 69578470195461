import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { upperFirst } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Typography,
}  from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import LoginIcon from '@material-ui/icons/DoubleArrowOutlined';
import Layout from '../components/Layout';
import AuthContext from '../contexts/AuthContext';
import useFormHelper from '../hooks/useFormHelper';
import styles from '../styles/base';
import {
  useFormik,
  FormikLabeledCheckbox,
  FormikTextField
} from '../utils/formik';
import { SignupSchema } from '../utils/validation';

function Signup({ classes, history }) {
  const { api, patchMe } = useContext(AuthContext);
  const [dialogOpen, setDialogOpen] = useState(true);
  const { type } = useParams();
  
  const formik = useFormik({
    initialValues: {
      type,
      firstName: '',
      lastName: '',
      cell: '',
      email: '',
      jobTitle: '',
      password: '',
      agree: true
    },
    validateOnBlur: true,
    validationSchema: SignupSchema,
    onSubmit: async (values) => {
      if (!values.agree) {
        setErrorMsg('Please check the checkbox to agree to proper use of this application.')
        return;
      }

      const { response, error } = await api.post('auth/signup', { ...values, type });
      if (response) {
        patchMe(response);
        if (type === 'provider') {
          setSuccessMsg('Thank you for helping us to get more at-risk patients vaccinated sooner. It is conscientious providers like you that make it possible to save more lives.', 10000);
        }
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  useEffect(() => {
    if (type) {
      if (!['patient','provider','volunteer'].includes(type)) {
        history.push(`/signup`);
      } else {
        formik.setFieldValue('type', type);
      }
    }
  }, type);

  const handleClose = () => {
    setDialogOpen(false);
  }
  
  const { setSuccessMsg, setErrorMsg } = useFormHelper(formik, true);

  return (
    <Layout centerContent>
      
      <Collapse in={!type}>
        <Typography variant="h4" style={{ textAlign: 'center' }}>I am a...</Typography>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', margin: 12 }}>
          <Button
            className={classes.halfButton}
            color="primary"
            component={RouterLink}
            to="/signup/patient"
            size="large"
            variant="contained">Patient</Button>
          <Button
            className={classes.halfButton}
            color="secondary"
            component={RouterLink}
            to="/signup/provider"
            size="large"
            variant="contained">Vaccine Provider</Button>
        </div>

        <div className={classes.authSwitcher}>
          <Typography>Already have an account?</Typography>
          <Button
            component={RouterLink}
            endIcon={<LoginIcon />}
            to="/login">
            Log In
          </Button>
        </div>
      </Collapse>

      <Collapse in={!!type}>
        <div style={{ maxWidth: 600, textAlign: 'center' }}>
          <Typography variant="h3" className={classes.onboardingHeader}>Create a {upperFirst(type)} Account</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader}>
            {type === 'volunteer'
              ? 'Help us try to save more lives. Whether it\'s just a couple minutes a day or much more, if you want to contribute to our mission, just signup below to get involved.'
              : 'Your information will never be shared with anyone.'}
          </Typography>
          
          <form onSubmit={formik.handleSubmit} className={classes.formContainer} style={{ marginTop: 16 }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormikTextField
                autoFocus
                fullWidth
                name="firstName"
                label="First Name"
                placeholder="Enter your first name..."
                formik={formik}
              />

              <div style={{ width: 16 }} />

              <FormikTextField
                fullWidth
                name="lastName"
                label="Last Name"
                placeholder="Enter your last name..."
                formik={formik}
              />
            </div>

            {type === 'provider' && (
              <>
                <FormikTextField
                  fullWidth
                  name="email"
                  label="Work Email"
                  placeholder="Enter email address..."
                  helperText="Use your work email address to expedite account verification."
                  formik={formik}
                />

                <FormikTextField
                  fullWidth
                  name="cell"
                  label="Cell Phone (optional)"
                  placeholder="Enter your cell phone..."
                  helperText="If you prefer communication via text message instead of email, provide your number here."
                  formik={formik}
                />
              </>
            )}

            {type === 'patient' && (
              <>
                <FormikTextField
                  fullWidth
                  name="cell"
                  label="Cell Phone"
                  placeholder="Enter your cell phone..."
                  helperText="If you match with an available dose, we use text message (SMS) to ensure the quickest possible response."
                  formik={formik}
                />

                <FormikTextField
                  fullWidth
                  name="email"
                  label="Email (optional)"
                  placeholder="Enter email address..."
                  helperText="For less urgent communication."
                  formik={formik} />
              </>
            )}

            {type === 'volunteer' && (
              <>
                <FormikTextField
                  fullWidth
                  name="jobTitle"
                  helperText="If retired, enter your pre-retirement profession."
                  label="Profession / Job Title"
                  placeholder="Enter your title..."
                  formik={formik}
                />

                <FormikTextField
                  fullWidth
                  name="email"
                  label="Email"
                  placeholder="Enter email address..."
                  formik={formik} />
              </>
            )}

            <FormikTextField
              fullWidth
              name="password"
              label="Password"
              placeholder="Enter a secure password here..."
              type="password"
              formik={formik} />

            {type === 'provider' && (
              <div style={{ textAlign: 'left' }}>
                <FormikLabeledCheckbox
                  color="primary"
                  label="I will not use this site in any manner which is unlawful in my jurisdiction."
                  name="agree"
                  formik={formik}
                />
              </div>
            )}
            
            {type === 'patient' && (
              <div style={{ textAlign: 'left' }}>
                <FormikLabeledCheckbox
                  color="primary"
                  label="I have read and agree to the following:"
                  name="agree"
                  formik={formik}
                />
                <ul style={{ marginTop: 0, marginLeft: 8, opacity: 0.75 }}>
                  <li>
                    I understand this site is a third-party nonprofit service, is not directly affiliated with or
                    representative of any governmental entity or healthcare organization, and does not recommend
                    any specific medical advice. I will consult with a physician as needed.
                  </li>
                  <li>
                    I do not consider participation in this service as a replacement for pursuit of vaccination
                    through any of the ordinary channels available to me in my jurisdiction. I understand this
                    service cannot guarantee me access to a vaccine dose any sooner than these channels may allow.
                  </li>
                  <li>
                    I will not use this site in any manner which is unlawful in my jurisdiction.
                  </li>
                </ul>
              </div>
            )}

            {type === 'volunteer' && (
              <div style={{ textAlign: 'left' }}>
                <FormikLabeledCheckbox
                  color="primary"
                  label="I agree to help progress the mission of VaccineList and not do anything to troll or otherwise hamper the efforts of the VaccineList volunteer community."
                  name="agree"
                  formik={formik}
                />
              </div>
            )}

            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              Create Account
            </Button>

            <div className={classes.authSwitcher}>
              <Typography>Already have an account?</Typography>
              <Button
                component={RouterLink}
                endIcon={<LoginIcon />}
                to="/login">
                Log In
              </Button>
            </div>

          </form>
        </div>
      </Collapse>

      <Dialog disableBackdropClick onClick={handleClose} open={dialogOpen}>
        <DialogContent className={classes.formContainer}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Typography style={{ flex: 1 }} variant="h6">Oops...</Typography>
            <IconButton aria-label="close" onClick={handleClose} size="small">
              <CloseIcon />
            </IconButton>
          </div>
          <Typography>
            We are no longer registering new users. We recommend all who
            are looking for a service like this to sign up at{' '}
            <span class={classes.drb}>Dr. B</span> instead.
          </Typography>
          <Typography>
            <span class={classes.drb}>Dr. B</span> is a similar service that
            was created by the former CEO of Zocdoc. It is growing quickly
            and helping distribute more doses than we ever were.
          </Typography>
          <Typography>
            Read more about why we are
            sending our users there <Link component={RouterLink} to="/goodbye">on this page</Link>.
          </Typography>
          <Button color="primary" fullWidth href="https://hidrb.com" size="large" variant="contained">Go to Dr. B's Website</Button>
        </DialogContent>
      </Dialog>
    </Layout>
  );
}

export default withStyles(styles)(Signup);
