const styles = (theme) => ({
  alertParagraph: {
    margin: `0 0 ${theme.spacing()}px`
  },
  authSwitcher: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.6,
    '& > p': {
      marginRight: theme.spacing()
    }
  },
  cardActions: {
    paddingLeft: theme.spacing(1.5),
  },
  checkboxStartSm: {},
  dialogCollapseContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 200,
    paddingBottom: theme.spacing(2),
    width: '100%'
  },
  drb: {
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  flexBreak: {
    height: theme.spacing(),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  flexCards: {
    alignItems: 'stretch',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  flexCard: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: 8
  },
  flexWrapArea: {
    alignItems: 'stretch',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'stretch',
  },
  flexWrapCard: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(),
    minHeight: 215,
    width: `calc(100% - ${2 * theme.spacing()}px)`,

    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${2 * theme.spacing()}px)`,
    },
    [theme.breakpoints.up('lg')]: {
      width: `calc(33.3% - ${2 * theme.spacing()}px)`,
      '&$highlight': {
        flexGrow: 1,
        width: `calc(50% - ${2 * theme.spacing()}px)`,
      },
    },
    [theme.breakpoints.up('xl')]: {
      width: `calc(25% - ${2 * theme.spacing()}px)`,
      '&$highlight': {
        width: `calc(33.3% - ${2 * theme.spacing()}px)`,
      },
    },
  },
  highlight: {},
  highlightHeaderAction: {
    borderBottomLeftRadius: 4,
    color: 'white',
    padding: '0 8px',
    position: 'absolute',
    right: 0,
    top: 0,
    [theme.breakpoints.down('xs')]: {
      lineHeight: '1.4em'
    }
  },
  flexPivotOnSm: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  flexWrapped: {},
  formContainer: {
    ['& > *']: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
      ['&:first-child']: {
        marginTop: 0
      },
    }
  },
  halfButton: {
    margin: theme.spacing(0.5), 
    width: 300
  },
  healthConditionChip: {
    fontSize: '0.7rem',
    margin: `2px 1px`,
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.65rem',
      margin: `1px 1px 1px 0`,
    }
  },
  healthTierBox: {
    alignItems: 'center',
    borderBottom: '1px solid #CCC',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(2)
    }
  },
  healthTierConditions: {
    flex: 1,
    paddingLeft: 12,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      marginBottom: theme.spacing(2)
    }
  },
  healthTierSelect: {
    width: 150,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  inlineFieldContainer: {
    marginBottom: theme.spacing(4)
  },
  inlineField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(-0.5)
  },
  occupationRisks: {
    padding: theme.spacing(1.5),
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      borderTop: '2px dotted #999',
      paddingLeft: 0,
      paddingRight: 0,
      ['& > p']: {
        fontSize: '0.9rem',
        lineHeight: '2rem',
      }
    }
  },
  onboardingHeader: {},
  onboardingSubheader: {
    color: theme.palette.text.secondary,
    fontWeight: 'normal',
  },
  matchAddressRow: {
    display: 'flex',
    ['& > *:first-child']: {
      flex: 1,
      marginRight: theme.spacing()
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      ['& > *:first-child']: {
        marginBottom: `${theme.spacing(0.75)}px`,
        marginRight: 0,
      }
    }
  },
  matchButtons: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      ['& > *:first-child']: {
        marginBottom: `${theme.spacing(0.75)}px`,
      }
    }
  },
  matchQuestion: {
    fontSize: '1.4rem',
    marginBottom: 0,
    textAlign: 'center',
  },
  noPrint: {},
  '@media print': {
    noPrint: {
      display: 'none !important',
      '& *': {
        display: 'none !important',
      }
    },
  },
  paddedPaper: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(4)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  tabPanelPaper: {
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(),
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
    }
  },
  dialogLinkBox: {
    background: '#eee',
    marginTop: theme.spacing(),
    padding: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      fontSize: '13px'
    }
  },
  donationForm: {
    height: 300,
    width: '100%',
    maxWidth: 360,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    }
  },
  [theme.breakpoints.down('sm')]: {
    checkboxStartSm: {
      alignItems: 'flex-start'
    },
    flexWrapped: {
      marginTop: theme.spacing(3)
    },
    onboardingHeader: {
      fontSize: '2rem',
    },
    onboardingSubheader: {
      fontSize: '1rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    onboardingHeader: {
      fontSize: '1.5rem',
    },
    onboardingSubheader: {
      fontSize: '0.8rem',
    },
  }
});
export default styles;