import React, { useContext, useState } from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  colors,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import HighlightIcon from '@material-ui/icons/Star';
import PhoneIcon from '@material-ui/icons/Phone';
import ResearchIcon from '@material-ui/icons/SearchOutlined';
import RecruitIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import ConnectIcon from '@material-ui/icons/Link';
import ExpertIcon from '@material-ui/icons/LocalLibraryOutlined';
import LegalIcon from '@material-ui/icons/Gavel';
import SalesIcon from '@material-ui/icons/AccountTreeOutlined';
import CloudIcon from '@material-ui/icons/FilterDramaOutlined';
import CodeIcon from '@material-ui/icons/SettingsEthernetOutlined';
import PRIcon from '@material-ui/icons/VolumeUpOutlined';
import PMOIcon from '@material-ui/icons/BubbleChartOutlined';
import NonprofitIcon from '@material-ui/icons/MoneyOffOutlined';
import DesignIcon from '@material-ui/icons/FavoriteOutlined';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import QuickVolunteerDialog from '../../components/QuickVolunteerDialog';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import styles from '../../styles/base';

const useStyles = makeStyles(styles);

const colorList = [
  'red',
  'indigo',
  'lightBlue',
  'orange',
  'deepOrange',
  'deepPurple',
  'teal',
  'amber',
  'purple',
  'pink',
  'blue',
  'green',
  'cyan',
  'yellow'
].map((c) => colors[c].A400);

// TODO: add share
const needs = [
  {
    href: '/volunteer/call',
    title: 'Dialing for Doses',
    content: [
      'If you imagine calling your neighborhood pharmacy to explain how using VaccineList '
        + 'would benefit both them and your community, what emotion comes to mind?',
      'If it\'s excitement, this is where we need the most help!'
    ],
    roles: [
      'Anyone', 'Especially those in healthcare / public health'
    ],
    highlight: true,
    color: colorList.shift(),
    icon: <PhoneIcon />
  },
  {
    href: '/volunteer/research',
    title: 'Perform Internet Research',
    content: [
      'Our primary research objective is to build comprehensive lists of all vaccine '
        + 'providers in every state in the US.',
      'This is a multistep process for each state involving internet research, data '
        + 'clean-up, and giving attention to detail.'
    ],
    roles: ['Anyone'],
    highlight: true,
    color: colorList.shift(),
    icon: <ResearchIcon />
  },
  {
    action: 'network',
    title: 'Connect Us to Decision Makers',
    content: [
      'Know someone involved in vaccine provision? Know someone in management at CVS, Publix, or any other chain helping with vaccine distribution?',
      'Introduce one of our founders and we\'ll set up a partnership that could help save lives.'
    ],
    roles: ['Anyone'],
    highlight: true,
    color: colorList.shift(),
    icon: <ConnectIcon />
  },
  {
    action: 'recruit',
    title: 'Recruit More Volunteers',
    content: [
      'We need as many volunteers as we can get! The easiest way to help is to invite friends and family that care as '
        + 'much as you do to come help too.',
      'Right now, we are especially looking for volunteers that are great on the phone and / or have medical credibility '
        + '(i.e. doctors, pharmacists, nurses, public health professionals and students) to help us make provider calls.'
    ],
    roles: ['Anyone'],
    highlight: true,
    color: colorList.shift(),
    icon: <RecruitIcon />
  },
  {
    action: 'sme',
    title: 'Subject-Matter Expertise',
    content: [
      'There is a lot we don\'t know yet, and a lot we don\'t even know we don\'t know yet.'
    ],
    roles: ['Epidemiologists', 'Public Health Experts'],
    color: colorList.shift(),
    icon: <ExpertIcon />
  },
  {
    action: 'legal',
    title: 'Legal Consulting',
    content: [
      'Especially in reviewing our (currently boilerplate) Privacy Policy and Terms of Use.'
    ],
    roles: ['Legal Experts'],
    color: colorList.shift(),
    icon: <LegalIcon />
  },
  {
    action: 'sales',
    title: 'Sales Consulting',
    content: [
      'We obviously aren\'t selling anything, but we could use help formalizing and optimizing our outreach strategy.'
    ],
    roles: ['Outbound B2B Sales Managers', 'Sales Consultants'],
    color: colorList.shift(),
    icon: <SalesIcon />
  },
  {
    action: 'cloud',
    title: 'Cloud Consulting',
    content: ['Help us plan to scale our stack on a budget.'],
    roles: ['Cloud Experts'],
    color: colorList.shift(),
    icon: <CloudIcon />
  },
  {
    action: 'software',
    title: 'Software Development',
    content: ['Help with New Feature Development, Improving Testing Coverage, Internationalization, and Accessibility.'],
    roles: ['Software Engineers (React, NodeJS, MongoDB)'],
    color: colorList.shift(),
    icon: <CodeIcon />
  },
  {
    action: 'pr',
    title: 'PR Consulting',
    content: ['Help us tell our story and get it in front of the people that can spread it further.'],
    roles: ['PR & Communication Professionals', 'Journalists', 'Media'],
    color: colorList.shift(),
    icon: <PRIcon />
  },
  {
    action: 'pmo',
    title: 'Project Management',
    content: ['Any help managing project teams, especially teams of remote and parttime volunteers, would be huge.'],
    roles: ['Project Managers', 'People Managers'],
    color: colorList.shift(),
    icon: <PMOIcon />
  },
  {
    action: 'npo',
    title: 'Nonprofit Consulting',
    content: ['Filing for 501c3 Status, Grant Writing'],
    roles: ['Legal Experts', 'Nonprofit Experts'],
    color: colorList.shift(),
    icon: <NonprofitIcon />
  },
  {
    action: 'uxui',
    title: 'Branding & UX/UI Improvement',
    content: ['Improving User Experience on the app, creating a real logo, improving site aesthetic, etc'],
    roles: ['UX Experts', 'UI Experts', 'Graphic Design'],
    color: colorList.shift(),
    icon: <DesignIcon />
  },
];

// name, phone number 
const breadcrumbs = [
  { label: 'Volunteer', href: '/volunteer' },
  { label: 'Action Center' },
];

const VolunteerDialog = ({ children, handleClose, title }) => (
  <Dialog open fullWidth onClose={handleClose}>
    <DialogContent style={{ paddingBottom: 20 }}>
      <Typography variant="h6">{title}</Typography>
      {children}
      <Button color="primary" fullWidth onClick={handleClose} style={{ marginTop: 8 }} variant="contained">
        Got It!
      </Button>
    </DialogContent>
  </Dialog>
);

function SpecialtyDialog({ disabled, handleClose, handleSave, specialty }) {
  const [notes, setNotes] = useState('');
  const save = () => {
    handleSave(notes);
  };
  const handleChange = (event) => {
    setNotes(event.target.value);
  };
  return (
    <Dialog open onClose={handleClose}>
      <DialogContent style={{ paddingBottom: 20 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">{specialty.title}</Typography>
          <IconButton onClick={handleClose} size="small"><CloseIcon /></IconButton>
        </div>

        <Typography variant="body2" color="textSecondary">
          Tell us a bit about your qualifications and how you would like to help.
        </Typography>
        <TextField
          autoFocus
          fullWidth
          multiline
          onChange={handleChange}
          rows={3}
          style={{ marginTop: 8, marginBottom: 8 }}
          value={notes}
          variant="outlined" />
        <Button color="primary" disabled={disabled} fullWidth onClick={save} variant="contained">
          Get Involved
        </Button>
      </DialogContent>
    </Dialog>
  );
}


// TODO: add "share" task, "esp. with people at-risk for severe covid infection" + buttons (can be in dialog) for sharing to FB, TW, insta?, email, sms

// TODO: script to make specific assignments (or just do from email)
//  - Can you call these 5 clinics?
//  - Can you find us the state provider list for Wisconsin?
export default function VolunteerIndex({ history }) {
  const classes = useStyles();
  const { api, me, patchMe } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [instructions, setInstructions] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [specialtySaving, setSpecialtySaving] = useState(false);

  const xsDown = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const onError = (msg) => {
    popToast(msg, 'error');
  };

  const handleClick = (need) => () => {
    switch (need.action) {
      case 'recruit':
        setInstructions({
          title: 'Recruit More Volunteers',
          children: (
            <>
              <Typography variant="body1">
                We need all the help we can get! Especially if you know someone well-suited for
                one of the positions listed on this page, we could use your help bringing them onboard.
                <br/><br/>
                Just tell them how we are trying to help the world, and give them a link to our
                volunteer signup page (below) or send an introduction email to them and our team
                ({process.env.REACT_APP_CONTACT_EMAIL}).
                <br/>
              </Typography>
              <Typography className={classes.dialogLinkBox} variant="body1">
                https://vaccinelist.org/signup/volunteer
              </Typography>
            </>
          )
        });
        break;
      case 'network':
        setInstructions({
          title: 'Help Us Connect',
          children: (
            <>
              <Typography variant="subtitle2">
                If you have a personal or professional relationship with any of the following,
                helping us connect would be incredibly valuable:
              </Typography>
              <Typography variant="body2">
                <ul>
                  <li>
                    Corporate managers at any national, regional, or local chains participating in
                    vaccine distribution (i.e. Walgreens, CVS, RiteAid, Publix, Walmart, etc). Even
                    if they aren't directly involved with vaccine operations or if they are recently
                    retired, they may be able to help connect us to the right person.
                  </li>
                  <li>
                    Local or national politicians who may have the interest and power to help.
                  </li>
                  <li>
                    Local or national public health officials who may be able to encourage or greenlight
                    use of VaccineList locally (or connect to the officials who could).
                  </li>
                  <li>
                    Hospital administrators at hospitals that are administering vaccines.
                  </li>
                  <li>
                    Independent pharmacists or doctors administering vaccines.
                  </li>
                  <li>
                    Anyone else involved in the vaccine provision process.
                  </li>
                </ul>
              </Typography>
              <Typography variant="subtitle2" style={{ borderLeft: '6px solid #3f51b5', paddingLeft: 12, marginBottom: 12, padding: '6px 12px' }}>
                The easiest way to actually make the connection is with an introduction email between
                your contact and our founder [patrick@vaccinelist.org]. We will take it from there!
              </Typography>
            </>
          )
        });
        break;
      default:
        setSpecialty(need);
        break;
    }
  };

  const closeInstructions = () => {
    setInstructions(null);
  };

  const closeSpecialty = () => {
    setSpecialty(null);
  };

  const saveSpecialty = async (notes) => {
    setSpecialtySaving(true);
    const { response, error } = await api.post(`volunteer/specialty`, { specialty: specialty.action, notes });
    setSpecialtySaving(false);
    if (response) {
      patchMe(response);
      return closeSpecialty();
    }
    return onError(error || 'There was a problem. Please try again.');
  };

  const layoutProps = me
    ? { breadcrumbs, centerContent: true }
    : { centerContent: true }

  const volunteered = (me?.volunteer?.specialties ?? []);
  return (
    <>
      <Layout {...layoutProps}>
        {!me && (
          <div style={{ marginBottom: 16, maxWidth: 725, textAlign: 'center' }}>
            <Typography variant="h3" className={classes.onboardingHeader}>Volunteering With Us</Typography>
            <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 16 }}>
              Help us try to save more lives. Whether it's just a couple minutes a day or much more,
              if you want to contribute to our mission, choose one of the many ways you can help below
              and click the corresponding button to get involved.
            </Typography>
          </div>
        )}
        
        <div>
          <div className={classes.flexWrapArea}>
            {needs.map((need) => {
              const buttonProps = {};
              if (need.href) {
                buttonProps.component = RouterLink;
                buttonProps.to = me ? need.href : '/signup/volunteer';
              } else {
                buttonProps.onClick = handleClick(need);
              }
              if (need.highlight) {
                buttonProps.children = 'Get Started';
              } else if (volunteered.includes(need.action)) {
                buttonProps.disabled = true;
                buttonProps.children = 'Pending';
              } else {
                buttonProps.children = 'Volunteer';
              }
              return (
                <Card elevation={3} className={clsx([classes.flexWrapCard, { [classes.highlight]: need.highlight }])}>
                  <CardHeader
                    action={need.highlight && (
                      <Typography className={classes.highlightHeaderAction} style={{ background: need.color }} variant="subtitle2">
                        <HighlightIcon fontSize="inherit" style={{ verticalAlign: 'text-top' }} /> High Priority
                      </Typography>
                    )}
                    avatar={(
                      <Avatar style={{ background: need.color, marginTop: 3 }}>{need.icon}</Avatar>
                    )}
                    title={need.title}
                    titleTypographyProps={{ variant: 'h6', style: { lineHeight: '1.2em', margin: '2px 0' } }}
                    style={{ alignItems: 'flex-start', position: 'relative' }}
                    subheader={need.roles.join(' ∙ ')}
                  />
                  <CardContent style={{ flex: 1, paddingTop: 0, paddingBottom: 0 }}>
                    {need.content.map((contentText, x) => (
                      <Typography gutterBottom={x < need.content.length - 1} variant="body2">
                        {contentText}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions style={{ flexDirection: 'row' }}>
                    <div style={{ flex: 1 }} />
                    <Button color="primary" {...buttonProps} />
                  </CardActions>
                </Card>
              );
            })}
          </div>
        </div>

        {instructions && (
          <VolunteerDialog {...instructions} handleClose={closeInstructions} />
        )}
        {specialty && me && (
          <SpecialtyDialog
            disabled={specialtySaving}
            handleClose={closeSpecialty}
            handleSave={saveSpecialty}
            specialty={specialty} />
        )}
        {specialty && !me && (
          <QuickVolunteerDialog
            handleClose={closeSpecialty}  
            specialty={specialty.action} />
        )}
      </Layout>
      
      {!me && <div style={{ height: 20 }} />}
      {!me && <Footer />}
    </>
  );
}