import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Layout from '../../components/Layout';
import DoseDialog from '../../components/provider/DoseDialog';
import DoseTable from '../../components/provider/DoseTable';
import AuthContext from '../../contexts/AuthContext';

const useStyles = makeStyles((theme) => ({
  addDosesContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    ['& button']: {
      marginLeft: theme.spacing()
    }
  },
  inlineField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(-0.5)
  },
  [theme.breakpoints.down('xs')]: {
    addDosesContainer: {
      flexWrap: 'wrap',
      ['& button']: {
        marginTop: theme.spacing(2)
      }
    },
  },
}));

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  // TODO (later): transition effect?
  const { children, visible } = props;
  return (
    <div role="tabpanel" hidden={!visible}>
      {visible && (
        <Box p={0}>{children}</Box>
      )}
    </div>
  );
}

const doseOptions = [...Array(8).keys()].map((x) => x + 1);
const halfDayAgo = new Date();
halfDayAgo.setHours(halfDayAgo.getHours() - 12);

// name, phone number 
const breadcrumbs = [
  { label: 'Dashboard', href: '/provider' },
  { label: 'Home' },
];

export default function ProviderIndex() {
  const { api, me } = useContext(AuthContext);

  const classes = useStyles();
  const xsDown = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const [tab, setTab] = useState(0);
  const [creatingDoses, setCreatingDoses] = useState(false);
  const [doseTally, setDoseTally] = useState(1);
  const [pendingDoses, setPendingDoses] = useState([]);
  const [allDoses, setAllDoses] = useState([]);

  let fetcher;
  const fetchDoses = async () => {
    if (fetcher) {
      clearTimeout(fetcher);
      fetcher = null;
    }

    const { response } = await api.get('doses');
    if (response) {
      setAllDoses(response);

      const pendingDoses = response.filter(({ pending }) => !!pending);
      setPendingDoses(pendingDoses);
      
      if (pendingDoses.length > 0) {
        fetcher = setTimeout(fetchDoses, 30e3);
      }
    }
  };

  useEffect(() => {
    fetchDoses();
    return function() {
      if (fetcher) {
        clearTimeout(fetcher);
      }
    };
  }, []);

  const onChangeTab = (event, tabIndex) => {
    setTab(tabIndex);
  };

  const onChangeDoses = (event) => {
    setDoseTally(event.target.value);
  };

  const createDoses = () => {
    if (doseTally > 0) {
      setCreatingDoses(true);
    }
  };

  const closeDoseCreation = () => {
    setCreatingDoses(false);
  };

  const onDoseCreation = () => {
    closeDoseCreation();
    fetchDoses();
  };

  const onDoseCancellation = (dose) => async () => {
    let confirmationMsg = 'Are you sure you want to cancel this waitlist appointment before it is booked?';
    if (dose.status === 'MATCHED') {
      confirmationMsg = 'This appointment has already been booked with an at-risk patient in your community! \n\n'
        + 'If you absolutely need to cancel, we ask that you please call the patient to let them know '
        + 'as they may already be on their way to your clinic. \n\nAre you sure you wish to cancel?';
    }
    if (window.confirm(confirmationMsg)) {
      await api.del(`doses/${dose.id}`);
      fetchDoses();
    }
  };
  
  return (
    <Layout breadcrumbs={breadcrumbs} hideDivider>
      <Tabs
        value={tab}
        onChange={onChangeTab}
        indicatorColor="primary"
        scrollButtons="auto"
        style={{ borderBottom: '1px solid #CCC', marginBottom: 12 }}
        textColor="primary"
        variant="scrollable"
        aria-label="page tabs">
        <Tab label="Pending Doses" {...a11yProps(0)} />
        <Tab label="Recent Matches" {...a11yProps(1)} />
      </Tabs>
      
      <TabPanel visible={tab === 0}>
        <Paper style={{ width: '100%', textAlign: 'center', padding: '48px 32px' }}>
          <Typography variant="h5" color="primary">
            Do you have any more doses for the waitlist today?
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" style={{ fontWeight: 'normal' }}>
            Add your leftover doses below and we will match them to 
            the most significantly at-risk patients who are available
            to receive them on your schedule.
          </Typography>
          <div className={classes.addDosesContainer}>
            <Typography>
              Assign
              <Select
                className={classes.inlineField}
                displayEmpty
                onChange={onChangeDoses}
                value={doseTally}>
                {doseOptions.map((doses) => (
                  <MenuItem key={doses} value={doses}>{doses} dose{doses === 1 ? '' : 's'}</MenuItem>
                ))}
              </Select>
              to the waitlist.
            </Typography>
            <Button
              color="primary"
              fullWidth={xsDown}
              onClick={createDoses}
              startIcon={<AddIcon />}
              variant="contained">
              Find Patient{doseTally === 1 ? '' : 's'}
            </Button>
          </div>
        </Paper>
          
        {pendingDoses.length > 0 && (
          <div style={{ width: '100%', marginTop: 32 }}>
            <Typography variant="h6" style={{ paddingLeft: 8 }}>Currently Pending</Typography>
            <DoseTable doses={pendingDoses} me={me} onCancel={onDoseCancellation} />
          </div>
        )}
      </TabPanel>

      <TabPanel visible={tab === 1}>
        <div style={{ width: '100%' }}>
          {allDoses.length > 0 && (
            <DoseTable doses={allDoses} me={me} />
          )}

          {allDoses.length === 0 && (
            <Paper style={{ width: '100%' }}>
              <div style={{ padding: 32 }}>
                <Typography variant="h5">
                  No matches yet.
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" style={{ fontWeight: 'normal' }}>
                  Assign doses to the waitlist from the "Pending Doses" tab, and the matches will be
                  recorded here once their appointment time has passed.
                </Typography>
              </div>
            </Paper>
          )}
        </div>
      </TabPanel>

      {creatingDoses && (
        <DoseDialog
          onClose={closeDoseCreation}
          onSave={onDoseCreation}
          tally={doseTally} />
      )}
    </Layout>
  );
}