import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { upperFirst } from 'lodash';
import {
  Avatar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
 } from '@material-ui/core';
 import { makeStyles } from '@material-ui/core/styles';
 import AccountIcon from '@material-ui/icons/AccountCircleOutlined';
 import ContactIcon from '@material-ui/icons/AlternateEmailOutlined';
 import ClinicsIcon from '@material-ui/icons/LocalPharmacyOutlined';
 import CloseIcon from '@material-ui/icons/ArrowForwardIos';
 import LogoutIcon from '@material-ui/icons/ExitToAppOutlined';
 import RiskIcon from '@material-ui/icons/ErrorOutlineOutlined';
 import HomeIcon from '@material-ui/icons/HomeOutlined';
 import GeographyIcon from '@material-ui/icons/MapOutlined';
 import VolunteerIcon from '@material-ui/icons/ThumbUpOutlined';
 import DonateIcon from '@material-ui/icons/MonetizationOnOutlined';
 import AuthContext from '../contexts/AuthContext';
 import ToastContext from '../contexts/ToastContext';

const drawerWidth = 250;
const useStyles = makeStyles((theme) => ({
  avatar: {
    fontSize: `${theme.spacing(4)}px`,
    height: theme.spacing(8),
    marginBottom: theme.spacing(),
    textTransform: 'uppercase',
    width: theme.spacing(8),
  },
  avatarContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1.5),
    paddingTop: theme.spacing(2),
    width: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    '@media print': {
      display: 'none !important',
    }
  },
  drawerPaper: {
    width: drawerWidth,
  },
  offset: theme.mixins.toolbar,
}));

const patientLinks = [
  { href: '/patient', label: 'Home', Icon: HomeIcon },
  { href: '/patient/geography', label: 'Geographic Profile', Icon: GeographyIcon },
  { href: '/patient/risks', label: 'Risk Profile', Icon: RiskIcon },
  { href: '/patient/risks/health', sublabel: 'Health' },
  { href: '/patient/risks/occupation', sublabel: 'Occupation' },
  { href: '/patient/risks/household', sublabel: 'Household' },
  { href: '/patient/risks/other', sublabel: 'Other' },
  { href: '/patient/account', label: 'Account', Icon: AccountIcon },
];
const providerLinks = [
  { href: '/provider', label: 'Home', Icon: HomeIcon },
  { href: '/provider/clinics', label: 'Clinic Locations', Icon: ClinicsIcon },
  { href: '/provider/contact', label: 'Contact Preferences', Icon: ContactIcon },
  { href: '/provider/account', label: 'Account', Icon: AccountIcon },
];
const volunteerLinks = [
  { href: '/volunteer', label: 'Home', Icon: HomeIcon },
  { href: '/volunteer/account', label: 'Account', Icon: AccountIcon },
];

export default function MyDrawer({ mobileOpen, onMobileClose, type }) {
  const classes = useStyles();
  const { me, logout } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);

  const contactUs = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_CONTACT_EMAIL || '');
    popToast('Our email address has been copied to your clipboard. You can paste it into your preferred email client to send us a message.', 'info', 10000);
    onMobileClose();
  };

  let links = [];
  if (type === 'patient') links = patientLinks;
  if (type === 'provider') links = providerLinks;
  if (type === 'volunteer') links = volunteerLinks;
  const drawerContent = (
    <>
      <div className={classes.offset}>
        <Hidden mdUp>
          <div style={{ height: 56, display: 'flex', justifyContent: 'flex-end', paddingRight: 12 }}>
            <IconButton onClick={onMobileClose}><CloseIcon /></IconButton>
          </div>
        </Hidden>
      </div>
      <Divider />
      <Hidden smDown>
        <div className={classes.avatarContainer} style={{ height: 136 }}>
          <Avatar className={classes.avatar}>
            {me.firstName.substr(0, 1)}
            {me.lastName.substr(0, 1)}
          </Avatar>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold', lineHeight: '1em' }}>
            {me.firstName} {me.lastName}
          </Typography>
          <Typography variant="caption">{upperFirst(me.type)}</Typography>
        </div>
        <Divider />
      </Hidden>
      {links && (
        <>
          <List>
            {links.map(({ href, label, sublabel, Icon }) => (
              <ListItem key={label || sublabel} button component={RouterLink} to={href} onClick={onMobileClose}>
                <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                <ListItemText primary={label} secondary={sublabel} />
              </ListItem>
            ))}
          </List>
          <Divider />
        </>
      )}
      <List>
        {type !== 'volunteer' && (
          <ListItem button component={RouterLink} to="/volunteer" onClick={onMobileClose}>
            <ListItemIcon><VolunteerIcon /></ListItemIcon>
            <ListItemText primary="Volunteer" />
          </ListItem>
        )}
        <ListItem button component={RouterLink} to="/donate" onClick={onMobileClose}>
          <ListItemIcon><DonateIcon /></ListItemIcon>
          <ListItemText primary="Donate" />
        </ListItem>
      </List>
      {me && (
        <Hidden mdUp>
          <Divider />
          <List>
            <ListItem button onClick={logout}>
              <ListItemIcon><LogoutIcon /></ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Hidden>
      )}

      <div style={{ flex: 1 }}></div>
      <Paper style={{ margin: '0 12px 12px', background: '#eee', padding: '8px 12px' }} elevation={2}>
        <Typography variant="subtitle2">Need help?</Typography>
        <Typography variant="body2" onClick={contactUs} style={{ cursor: 'pointer' }}>Contact Us</Typography>
      </Paper>
    </>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <Drawer
          anchor="right"
          variant="temporary"
          open={mobileOpen}
          onClose={onMobileClose}
          classes={{ paper: classes.drawerPaper }}
          ModalProps={{ keepMounted: true }}>
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          open>
          {drawerContent}
        </Drawer>
      </Hidden>
    </nav>
  );
}