import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { initMapsApi, getAutocompleteService, getGeocoderService } from '../utils/googlemaps';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

export const geocodeResult = async (value) => {
  return await new Promise((resolve) => {
    getGeocoderService().geocode({ placeId: value.place_id }, (results) => {
      if (results && results[0]) {
        resolve({
          address: results[0].formatted_address,
          geocode: [
            results[0].geometry.location.lng(),
            results[0].geometry.location.lat()
          ]
        });
      } else {
        resolve(null);
      }
    });
  })
}

let autocompleteService;
export default function AddressAutocomplete({ customInputProps, defaultValue, disabled, onChange, ...textFieldProps }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue || null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  initMapsApi(loaded);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.getPlacePredictions(request, callback);
      }, 300),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService) {
      autocompleteService = getAutocompleteService();
    }
    if (!autocompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      disabled={!!disabled}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={({ inputProps, ...params }) => (
        <TextField
          variant="outlined"
          {...textFieldProps}
          {...params}
          inputProps={{
            ...(customInputProps || {}),
            ...(inputProps || {}),
            autoComplete: 'chrome-off'
          }} />
      )}
      renderOption={(option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}
              <Typography variant="body2" color="textSecondary">
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}