import { useFormik as _useFormik } from 'formik';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

export const useFormik = _useFormik;

export const FormikTextField = ({ formik, name, suppressErrorText, ...props }) => {
  let helperText = props.helperText;
  if (!suppressErrorText) {
    if (formik.touched[name] && formik.errors[name] && props.helperText) {
      helperText = `${formik.errors[name]} (${props.helperText})`;
    } else if (formik.touched[name] && formik.errors[name]) {
      helperText = formik.errors[name];
    }
  }
  return (
    <TextField
      {...props}
      name={name}
      disabled={props.disabled || formik.isSubmitting}
      value={formik.values[name]}
      onChange={formik.handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={helperText} />
  );
};

// allValues will either be boolean or array of strings
const isChecked = (allValues, thisValue) => {
  if (Array.isArray(allValues)) {
    return allValues.includes(thisValue);
  }
  return allValues;
};
export const FormikLabeledCheckbox = ({ formik, name, label, formControlProps = {}, ...props }) => (
  <FormControlLabel
    control={(
      <Checkbox
        {...props}
        name={name}
        checked={isChecked(formik.values[name], props.value)}
        onChange={formik.handleChange} />
    )}
    disabled={props.disabled || formik.isSubmitting}
    label={label}
    {...formControlProps}
  />
);

export const FormikSelect = ({ children, formik, name, ...props }) => (
  <Select
    {...props}
    name={name}
    disabled={props.disabled || formik.isSubmitting}
    onChange={formik.handleChange}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    value={formik.values[name]}>
    {children}
  </Select>
);
