import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

export default function Toast({ duration = 6000, messageState, type = 'info' }) {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = messageState;

  useEffect(() => {
    setOpen(!!message);
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    setTimeout(() => {
      setMessage('');
    }, 250);
  };

  // type can be success, error, warning, info
  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
      <Alert
        action={(
          <Button
            color="inherit"
            onClick={handleClose}
            size="small">
            OK
          </Button>
        )}
        elevation={6}
        onClose={handleClose}
        severity={type}
        variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
}