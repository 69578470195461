import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AddressAutocomplete, { geocodeResult } from '../AddressField';
import AuthContext from '../../contexts/AuthContext';
import useFormHelper from '../../hooks/useFormHelper';
import styles from '../../styles/base';
import {
  useFormik,
  FormikLabeledCheckbox,
  FormikSelect
} from '../../utils/formik';
import {
  PatientGeographySchema,
  PatientGeographyConstants
} from '../../utils/validation';

const { times, miles, minutes, days } = PatientGeographyConstants;

function Geography({ classes, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);
  const xsDown = useMediaQuery(theme => theme.breakpoints.down('xs'));

  const myGeo = me.patient?.geography ?? {};
  const formik = useFormik({
    initialValues: {
      address: myGeo.address || '',
      geocode: myGeo.geocode || null,
      days: (myGeo.days && myGeo.days.length > 0) ? myGeo.days : [...days.slice(1, days.length - 1)],
      startTime: (myGeo.startTime || myGeo.startTime === 0) ? myGeo.startTime : '',
      stopTime: myGeo.stopTime || '',
      distance: myGeo.distance || '',
      delay: myGeo.delay || '',
    },
    validateOnBlur: true,
    validationSchema: PatientGeographySchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/patient/geography', values);
      if (response) {
        patchMe(response);
        if (onSave) onSave();
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  const onChangeAddress = async (value) => {
    const geocodeData = value ? await geocodeResult(value) : {};
    formik.setFieldValue('address', geocodeData.address);
    formik.setFieldValue('geocode', geocodeData.geocode);
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <div>
        <Typography>I am willing and able to be vaccinated on these days of the week:</Typography>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {days.map((day) => (
            <React.Fragment key={day}>
              <FormikLabeledCheckbox
                value={day}
                color="primary"
                label={day}
                formControlProps={{
                  labelPlacement: xsDown ? 'bottom' : 'right',
                  style: xsDown ? { margin: 0 } : {}
                }}
                name="days"
                formik={formik}
              />
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={classes.inlineFieldContainer}>
        <Typography component="div">
          I am willing and able to be vaccinated between
          <FormikSelect
            className={classes.inlineField}
            displayEmpty
            name="startTime"
            formik={formik}>
            <MenuItem value="" disabled><em>(select)</em></MenuItem>
            {times.slice(0, times.length - 1).map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </FormikSelect>
          and
          <FormikSelect
            className={classes.inlineField}
            displayEmpty
            name="stopTime"
            formik={formik}>
            <MenuItem value="" disabled><em>(select)</em></MenuItem>
            {times.slice(1).map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </FormikSelect>
        </Typography>
      </div>

      <div style={{ marginBottom: 32 }}>
        <Typography>I spend most of these hours at or near this address:</Typography>
        <AddressAutocomplete
          defaultValue={formik.values.address}
          error={Boolean(formik.errors.address) || Boolean(formik.errors.geocode)}
          fullWidth
          onChange={onChangeAddress}
          customInputProps={{ style: { paddingLeft: 52, textAlign: 'center' }}}
          placeholder="Enter an address here..."
          variant="standard"
        />
      </div>

      <div className={classes.inlineFieldContainer}>
        <Typography component="div">
          I am willing and able to travel up to
          <FormikSelect
            className={classes.inlineField}
            displayEmpty
            name="distance"
            formik={formik}>
            <MenuItem value="" disabled><em>(select)</em></MenuItem>
            {miles.map((distance) => (
              <MenuItem key={distance} value={distance}>{distance} miles</MenuItem>
            ))}
          </FormikSelect>
          for a dose.
        </Typography>
      </div>

      <div className={classes.inlineFieldContainer}>
        <Typography component="div">
          I am willing and able to depart within
          <FormikSelect
            className={classes.inlineField}
            displayEmpty
            name="delay"
            formik={formik}>
            <MenuItem value="" disabled><em>(select)</em></MenuItem>
            {minutes.map(({ value, label }) => (
              <MenuItem key={value} value={value}>{label}</MenuItem>
            ))}
          </FormikSelect>
          of being notified of a dose.
        </Typography>
      </div>

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">Submit</Button>
    </form>
  );
}

export default withStyles(styles)(Geography);
