import React, { useContext } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@material-ui/core';
import AuthContext from '../contexts/AuthContext';
import useFormHelper from '../hooks/useFormHelper';
import {
  useFormik,
  FormikTextField
} from '../utils/formik';
import { ReverifySchema } from '../utils/validation';

function ReverifyDialog({ onClose, open, type }) {
  const { api, me, patchMe } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      type,
      value: me[type] || ''
    },
    validateOnBlur: true,
    validationSchema: ReverifySchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/verify', values);
      if (response) {
        patchMe(response);
        setSuccessMsg(`New verification ${type === 'cell' ? 'code' : 'email'} sent.`);
        if (onClose) onClose();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent style={{ paddingTop: 24, paddingBottom: 24 }}>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="body1" style={{ marginBottom: 16 }}>
            Make sure your information is correct and click "Resend".
          </Typography>

          <div style={{ display: 'flex' }}>
            <FormikTextField
              autoFocus
              fullWidth
              name="value"
              label={type === 'cell' ? 'Cell Phone' : 'Email Address'}
              variant="outlined"
              style={{ flex: 1, marginRight: 8 }}
              formik={formik}
            />

            <Button
              color="primary"
              disabled={formik.isSubmitting}
              size="large"
              type="submit"
              variant="contained">Resend {type === 'cell' ? 'Code' : 'Email'}</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ReverifyDialog;
