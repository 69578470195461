import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AuthContext from '../../../contexts/AuthContext';
import useFormHelper from '../../../hooks/useFormHelper';
import {
  useFormik,
  FormikTextField
} from '../../../utils/formik';
import { PatientOtherRisksSchema } from '../../../utils/validation';
import styles from '../../../styles/base';

function OtherRisk({ classes, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);

  const myRisks = me.patient?.risks ?? {};
  const formik = useFormik({
    initialValues: { 
      subjective: myRisks.subjective || ''
    },
    validateOnBlur: true,
    validationSchema: PatientOtherRisksSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/patient/risks', {
        page: 'other',
        ...values
      });
      if (response) {
        patchMe(response);
        if (onSave) onSave();
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h6" className={classes.onboardingSubheader}>
        Are there any other circumstances that heighten your risk or the urgency with
        which you would like to receive a vaccine?
      </Typography>

      <FormikTextField
        autoFocus
        fullWidth
        multiline
        helperText="Most people should leave this field blank."
        rows={4}
        style={{ marginTop: 8, marginBottom: 16 }}
        variant="outlined"
        name="subjective"
        formik={formik}
      />

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">Submit</Button>
    </form>
  );
}

export default withStyles(styles)(OtherRisk);