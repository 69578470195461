import React, { useContext } from 'react';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import Account from './account';
import Geography from './geography';
import Index from './index';
import Onboarding from './onboarding';
import Risks from './risks';

export default () => {
  const { path } = useRouteMatch();
  const { me } = useContext(AuthContext);
  if (me.onboarded) {
    if (me.cellVerified) {
      return (
        <Switch>
          <Route exact path={path} component={Index} />
          <Route path={`${path}/account`} component={Account} />
          <Route path={`${path}/geography`} component={Geography} />
          <Route path={`${path}/risks/:page?`} component={Risks} />
          <Route><Redirect to={`${path}`} /></Route>
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path={`${path}/account`} component={Account} />
          <Route><Redirect to={`${path}/account`} /></Route>
        </Switch>
      );
    }
  } else {
    return (
      <Switch>
        <Route exact path={path} component={Onboarding} />
        <Route><Redirect to={`${path}`} /></Route>
      </Switch>
    );
  }
};