import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import AuthContext from '../../../contexts/AuthContext';
import useFormHelper from '../../../hooks/useFormHelper';
import styles from '../../../styles/base';
import {
  useFormik,
  FormikLabeledCheckbox
} from '../../../utils/formik';
import { PatientHouseholdRisksSchema } from '../../../utils/validation';

function HouseholdRisk({ classes, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);

  const myRisks = me.patient?.risks ?? {};
  const formik = useFormik({
    initialValues: {
      multigenerational: myRisks.multigenerational || false,
      caretaker: myRisks.caretaker || false,
      soleCaretaker: myRisks.soleCaretaker || false,
      otherSoleCaretaker: myRisks.otherSoleCaretaker || false,
      parent: myRisks.parent || false,
    },
    validateOnBlur: true,
    validationSchema: PatientHouseholdRisksSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/patient/risks', {
        page: 'household',
        ...values
      });
      if (response) {
        patchMe(response);
        if (onSave) onSave();
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer} style={{ textAlign: 'left' }}>
      <Collapse in>
        <FormikLabeledCheckbox
          color="primary"
          formControlProps={{ className: classes.checkboxStartSm }}
          label="I am the parent or guardian of a child between 2 - 18 years of age."
          name="parent"
          formik={formik}
        />
      </Collapse>

      <Collapse in>
        <FormikLabeledCheckbox
          color="primary"
          formControlProps={{ className: classes.checkboxStartSm }}
          label="My household includes at least one unvaccinated person over the age of 65 and at least one person under the age of 65."
          name="multigenerational"
          formik={formik}
        />
      </Collapse>

      <span>
        <FormikLabeledCheckbox
          color="primary"
          formControlProps={{ className: classes.checkboxStartSm }}
          label="I am a caretaker for an individual in one of the following groups:"
          name="caretaker"
          formik={formik}
        />
        <ul style={{ marginTop: 0, marginLeft: 8, opacity: 0.75 }}>
          <li>
            Under 2 years of age
          </li>
          <li>
            Between 2 - 16 years of age with a chronic health condition
          </li>
          <li>
            Between 16 - 70 years of age with any health condition that renders them medically ineligible for vaccination
          </li>
          <li>
            75+ years of age and not yet vaccinated
          </li>
        </ul>
      </span>

      <Collapse in={formik.values.caretaker}>
        <FormikLabeledCheckbox
          color="primary"
          label="I am the sole caretaker of the above individual."
          name="soleCaretaker"
          formik={formik}
        />
      </Collapse>

      <Collapse in>
        <FormikLabeledCheckbox
          color="primary"
          formControlProps={{ className: classes.checkboxStartSm }}
          label={(
            <span>I am the <strong><u>sole</u></strong> caretaker an individual not in one of the above groups but who cannot fully care for themselves (incl. single parents of minors).</span>
          )}
          name="otherSoleCaretaker"
          formik={formik}
        />
      </Collapse>

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">Submit</Button>
    </form>
  );
}

export default withStyles(styles)(HouseholdRisk);
