import React, { useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExpireIcon from '@material-ui/icons/HourglassEmptyOutlined';
import LogoIcon from '@material-ui/icons/LocalPharmacyOutlined';
import TrashIcon from '@material-ui/icons/DeleteOutline';
import VialIcon from '@material-ui/icons/LocalPharmacyOutlined';
import Footer from '../components/Footer';
import styles from '../styles/home';

const useStyles = makeStyles(styles);

export default function Home() {
  const classes = useStyles();

  const howItWorksRef = useRef(null);
  const scrollToHowItWorks = () => howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });
  
  return (
    <div className={classes.root}>
      <AppBar className={classes.appbar} color="transparent" position="static" elevation={0}>
        <Toolbar>
          <LogoIcon style={{ merginRight: 16 }} />
          <Typography variant="h6" className={classes.title}>
            VaccineList.org
          </Typography>
          <Hidden xsDown>
            <Button color="inherit" component={RouterLink} to="/login" style={{ marginRight: 8 }}>Log In</Button>
          </Hidden>
          <Button color="inherit" component={RouterLink} to="/signup" variant="outlined">Sign Up</Button>
        </Toolbar>
      </AppBar>

      <div className={classes.jumbotronOuter}>
        <div className={classes.jumbotron}>
          <div>
            <Typography variant="h2">
              Save doses, save lives.
            </Typography>
            <Typography variant="h5">
              Matching Unused COVID-19 Vaccines to Patients in Need
            </Typography>
            <div className={classes.jumbotronButtons}>
              <Button color="primary" onClick={scrollToHowItWorks} size="large" variant="outlined">How It Works</Button>
              <Button color="primary" component={RouterLink} to="/signup" size="large" variant="contained" style={{ marginLeft: 16 }}>Get Started</Button>
            </div>
          </div>
        </div>
        <div className={classes.jumbotronImage} />
      </div>

      <div className={classes.midSection}>
        <Typography variant="h3">We Did It!</Typography>
        <Typography variant="h6" color="textSecondary" style={{ fontWeight: 400 }}>
          Since January 11, 2020, the world has moved heaven and earth to create and test the vaccines
          humanity needs to stop the COVID-19 pandemic. But even as manufacturers now race to
          produce as many doses of the vaccine as possible, existing doses are being thrown in the trash
          every day.
        </Typography>
        
        <Paper className={classes.whatBox} elevation={8}>
          <Typography className={classes.what} variant="h4">
            What?!
          </Typography>
          <List>
            <ListItem className={classes.whatItem}>
              <ListItemAvatar>
                <Avatar><VialIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText>
                Each vial of the Moderna* vaccine has 10 doses in it. Once a vial is opened, all contained doses
                must be used within 6 hours. Any leftover doses are no longer reliable and so must be discarded.
              </ListItemText>
            </ListItem>

            <ListItem className={classes.whatItem}>
              <ListItemAvatar>
                <Avatar><ExpireIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText>
                If a vaccination clinic doesn't schedule an exact multiple of 10 patients per day, or if any scheduled
                patient is a no-show, then there will be leftover doses at the end of the day -- doses that will be
                useless by the next day!
              </ListItemText>
            </ListItem>

            <ListItem className={classes.whatItem}>
              <ListItemAvatar>
                <Avatar><TrashIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText>
                Doses end up in the trash instead of in someone's arm. That's unacceptable.
              </ListItemText>
            </ListItem>
          </List>
          
          <Typography variant="caption" className={classes.whatCaption}>* NOTE: The same is true of the Pfizer vaccine, but there are 5-6 doses per vial.</Typography>
        </Paper>
      </div>

      <Paper className={classes.slogan} square elevation={16}>
        <Typography variant="h4">
          We match people willing and able to be vaccinated with the extra doses that would otherwise be
          wasted.
        </Typography>
      </Paper>

      <div ref={howItWorksRef} className={classes.howItWorks}>
        <Typography variant="h3" style={{ textAlign: 'center' }}>How It Works</Typography>
        <div className={classes.howItWorksFlex}>
          <div className={classes.howItWorksLeft}>
            <Paper elevation={4}>
              <Typography variant="h4" style={{ textAlign: 'center' }}>For Patients</Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>1</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    Sign Up
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>2</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    You'll receive a notification if a vaccine is available and have a few minutes to respond.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>3</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    If you accept the dose, you'll receive instructions on where to go and when to be there.
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </div>
          <div className={classes.howItWorksRight}>
            <Paper elevation={4}>
              <Typography variant="h4" style={{ textAlign: 'center' }}>For Providers</Typography>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>1</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    Sign Up
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>2</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    When you know how many doses you have left, fill in the required arrival time for any matching patient.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>3</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    We'll automatically schedule available patients in CDC-priority order so they are there when you need them.
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>4</Avatar>
                  </ListItemAvatar>
                  <ListItemText>
                    Getting lots of calls? You don't need to manage a waitlist yourself! Direct those patients here.
                  </ListItemText>
                </ListItem>
              </List>
            </Paper>
          </div>
        </div>
        <div className={classes.arrowDownBox}>
          <span className={classes.arrowDown} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h3">
            Save doses, save lives.
          </Typography>
          <Typography variant="h4" color="textSecondary">
            Together we can beat COVID faster.
          </Typography>

          <div style={{ marginTop: 40 }}>
            <Hidden xsDown>
              <Button className={classes.finalSignupAux} color="primary" component={RouterLink} to="/signup/volunteer" variant="outlined" size="large">Volunteer</Button>
              <Button className={classes.finalSignup} color="primary" component={RouterLink} to="/signup" variant="contained" size="large">Sign Up</Button>
              <Button className={classes.finalSignupAux} color="primary" component={RouterLink} to="/donate" variant="outlined" size="large">Donate</Button>
            </Hidden>
            <Hidden smUp>
              <Button className={classes.finalSignup} color="primary" component={RouterLink} to="/signup" variant="contained" size="large">Sign Up</Button>
              <div className={classes.finalSignupAuxRow}>
                <Button color="primary" component={RouterLink} to="/volunteer" variant="outlined" size="large">Volunteer</Button>
                <div style={{ width: 12 }} />
                <Button color="primary" component={RouterLink} to="/donate" variant="outlined" size="large">Donate</Button>
              </div>
            </Hidden>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
