import React from 'react';
import moment from 'moment';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Hidden,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ClockIcon from '@material-ui/icons/UpdateOutlined';
import ErrorIcon from '@material-ui/icons/ReportOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PhoneIcon from '@material-ui/icons/PhoneAndroidOutlined';
import SuccessIcon from '@material-ui/icons/DoneOutline';

const myTimezoneOffset = -1 * (new Date()).getTimezoneOffset();

function DoseTable({ doses, me, onCancel }) {
  return (
    <>
      <Hidden smDown>
        <Paper style={{ width: '100%' }}>
          <Table style={{ width: '100%' }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Dose Id</TableCell>
                <TableCell align="center">Matched to Patient</TableCell>
                <TableCell align="center">Arrival Time</TableCell>
                <TableCell>Clinic</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doses.map((dose) => {
                const doseClinic = (me.provider?.clinics ?? []).find(({ id }) => id === dose.clinic);
                const tzOffset = doseClinic?.timezone?.offset ?? myTimezoneOffset;
                return (
                  <TableRow key={dose.refId}>
                    <TableCell>
                      #{dose.refId}
                    </TableCell>
                    <TableCell align="center">
                      {dose.status === 'MATCHING' && (
                        <Chip size="small"
                          color="default"
                          icon={<CircularProgress color="secondary" size="1rem" style={{ marginLeft: 6, marginRight: 0 }} />}
                          label="Finding a Match..."
                          variant="outlined"
                        />
                      )}
                      {['COULD_NOT_MATCH','PATIENT_CANCELLED','WITHDRAWN'].includes(dose.status) && (
                        <Chip size="small"
                          color="secondary"
                          icon={<ErrorIcon color="error" />}
                          label={dose.status === 'COULD_NOT_MATCH' ? 'Could Not Match' : (dose.status === 'PATIENT_CANCELLED' ? 'Patient Cancelled' : 'Dose Withdrawn')}
                          variant="outlined"
                        />
                      )}
                      {dose.status === 'MATCHED' && dose.matchUser && (
                        <>
                          {dose.matchUser.firstName} {dose.matchUser.lastName}
                          {dose.matchUser.cell && (
                            <span style={{ display: 'block', fontSize: '85%' }}>
                              <Link href={`tel:${dose.matchUser.cell}`}>
                                <PhoneIcon fontSize="inherit" style={{ verticalAlign: 'text-top', marginRight: 4 }} />
                                {dose.matchUser.cell}
                              </Link>
                            </span>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {dose.apptType === 'at' ? 'At' : 'Before'}
                      {' '}
                      {moment(dose.apptTime)
                        .utcOffset(tzOffset)
                        .format('h:mm a (MMM D)')}
                      {tzOffset !== myTimezoneOffset && (
                        <Tooltip title={doseClinic?.timezone?.label ?? "Clinic Timezone"} enterTouchDelay={0}>
                          <ClockIcon
                            color="secondary"
                            fontSize="small"
                            style={{ marginLeft: 4, opacity: 0.7, verticalAlign: 'bottom' }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell>
                      {doseClinic?.name}
                      <Typography variant="body2" color="textSecondary">{doseClinic?.address}</Typography>
                    </TableCell>
                    <TableCell>
                      {onCancel && ['MATCHED','MATCHING'].includes(dose.status) && (dose.createdBy === me._id) && (
                        <Button onClick={onCancel(dose)} size="small" style={{ opacity: '0.5' }}>
                          Cancel
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Paper>
      </Hidden>
      <Hidden mdUp>
        {doses.map((dose) => {
          const doseClinic = (me.provider?.clinics ?? []).find(({ id }) => id === dose.clinic);
          const tzOffset = doseClinic?.timezone?.offset ?? myTimezoneOffset;

          let avatarStyle = { background: 'white', color: 'white' };
          let icon = null;
          let title = '???';
          if (dose.status === 'MATCHING') {
            icon = <CircularProgress color="primary" />;
            title = 'Finding a Match...'
          
          } else if (['COULD_NOT_MATCH','PATIENT_CANCELLED','WITHDRAWN'].includes(dose.status)) {
            avatarStyle.background = '#c62828';
            icon = <ErrorIcon color="inherit" />;
            if (dose.status === 'COULD_NOT_MATCH') {
              title = 'Could Not Match';
            } else if (dose.status === 'PATIENT_CANCELLED') {
              title = 'Patient Cancelled';
            } else {
              title = 'Dose Withdrawn';
            }
          } else if (dose.status === 'MATCHED') {
            avatarStyle.background = '#4caf50';
            icon = <SuccessIcon color="inherit" />;
            title = 'Match Found';
          }

          return (
            <Card key={dose.refId} style={{ marginBottom: 12 }}>
              <CardHeader
                avatar={(<Avatar style={avatarStyle}>{icon}</Avatar>)}
                action={(
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                )}
                title={(
                  <>
                    {dose.apptType === 'at' ? 'At' : 'Before'}
                    {moment(dose.apptTime).utcOffset(tzOffset).format('h:mm a (MMM D)')}
                    {tzOffset !== myTimezoneOffset && (
                      <Tooltip title={doseClinic?.timezone?.label ?? "Clinic Timezone"} enterTouchDelay={0}>
                        <ClockIcon
                          color="secondary"
                          fontSize="small"
                          style={{ marginLeft: 4, opacity: 0.7, verticalAlign: 'bottom' }} />
                      </Tooltip>
                    )}
                  </>
                )}
                subheader={`#${dose.refId} | ${doseClinic?.name}`}
              />
              <Divider />
              <CardContent style={{ textAlign: 'center' }}>
                {dose.matchUser
                  ? (
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                      <Typography variant="h6">{dose.matchUser.firstName} {dose.matchUser.lastName}</Typography>
                      {dose.matchUser.cell && (
                        <Button href={`tel:${dose.matchUser.cell}`} startIcon={<PhoneIcon />} color="primary" size="small" variant="outlined" style={{ marginLeft: 12 }}>
                          {dose.matchUser.cell}
                        </Button>
                      )}
                    </div>
                  )
                  : (
                    <Typography variant="subtitle2">{title}</Typography>
                  )}
              </CardContent>
            </Card>
          );
        })}
      </Hidden>
    </>
  )
}

export default DoseTable;
