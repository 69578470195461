import axios from 'axios';

function serializeParams(obj) {
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&');
}

const timezoneOffset = -1 * (new Date()).getTimezoneOffset();

export default class Api {
  constructor(apiToken) {
    this.token = apiToken;
  }

  formatUrl(uri, queryParams) {
    if (queryParams) {
      return`${process.env.REACT_APP_API_URL}/${uri}?${serializeParams(queryParams)}`;
    }
    return `${process.env.REACT_APP_API_URL}/${uri}`;
  };
  formatConfig() {
    const headers = {
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Pragma': 'no-cache',
      'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
      'Timezone': timezoneOffset
    };
    if (this.token) headers['Authorization'] = this.token;
    return { headers };
  }
  setToken(token) {
    this.token = token;
  }

  handleError(error) {
    // auth error
    if (error?.response?.status === 401) {
      if (this.token) {
        window.location.href = '/logout';
      }

    } else if (typeof error?.response?.data === 'string') {
      return error.response.data;
    }
    return (error.toJSON() || {}).message || 'Unknown error. Please try again.';
  }
  handleResponse(response) {
    return response.data;
  }

  // for using...
  get(uri, queryParams) {
    return axios.get(
      this.formatUrl(uri, queryParams),
      this.formatConfig()
    )
    .then((response) => {
      return { response: this.handleResponse(response) };
    })
    .catch((error) => {
      return { error: this.handleError(error) };
    });
  };
  post(uri, params = {}) {
    return axios.post(
      this.formatUrl(uri),
      params,
      this.formatConfig()
    )
    .then((response) => {
      return { response: this.handleResponse(response) };
    })
    .catch((error) => {
      return { error: this.handleError(error) };
    });
  };
  patch(uri, params = {}) {
    return axios.patch(
      this.formatUrl(uri),
      params,
      this.formatConfig()
    )
    .then((response) => {
      return { response: this.handleResponse(response) };
    })
    .catch((error) => {
      return { error: this.handleError(error) };
    });
  };
  del(uri) {
    return axios.delete(
      this.formatUrl(uri),
      this.formatConfig()
    )
    .then((response) => {
      return { response: this.handleResponse(response) };
    })
    .catch((error) => {
      return { error: this.handleError(error) };
    });
  };
}