import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/VerifiedUserOutlined';
import Layout from '../components/Layout';
import AuthContext from '../contexts/AuthContext';
import ToastContext from '../contexts/ToastContext';

function Verify({ history }) {
  const { me, patchMe, api } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [errorMsg, setErrorMsg] = useState();
  const [success, setSuccess] = useState(false);

  const { code } = useParams();
  useEffect(async () => {
    if (code) {
      const { response, error } = await api.post('users/verify', { type: 'email', code });
      if (response) {
        patchMe(response);
        if (response.onboarding) {
          setSuccess(true);
        } else {
          popToast('Email Verified Successfully', 'success', 3000);
          history.push(`/${me.type}`);
        }
      } else {
        setErrorMsg(error || 'Something went wrong. Please try again.');
      }
    } else {
      setErrorMsg('The verification URL entered was incomplete. Please try again.');
    }
  }, [code]);

  return (
    <Layout centerContent onboarding>
      {success && (
         <>
          <SuccessIcon color="disabled" style={{ fontSize: 80 }} />
          <Typography style={{ marginTop: 12, textAlign: 'center' }}>Your email has successfully been verified.</Typography>
          <Button color="primary" variant="contained" component={RouterLink} to={`/${me.type}`} style={{ marginTop: 12 }}>
            Go to Home Page
          </Button>
        </>
      )}
      {!success && errorMsg && (
        <>
          <ErrorIcon color="action" style={{ fontSize: 80 }} />
          <Typography style={{ marginTop: 12, textAlign: 'center' }}>{errorMsg}</Typography>
          <Button color="primary" variant="contained" component={RouterLink} to={`/${me.type}/account`} style={{ marginTop: 12 }}>
            Go to Account Page
          </Button>
        </>
      )}
      {!success && !errorMsg && (
        <>
          <CircularProgress size={60} />
          <Typography style={{ marginTop: 12, textAlign: 'center' }}>Verifying Email Address...</Typography>
        </>
      )}
    </Layout>
  );
}

export default Verify;
