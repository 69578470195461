import React, { useContext, useState } from 'react';
import {
  Button,
  Typography,
} from '@material-ui/core';
import SkipIcon from '@material-ui/icons/DoubleArrowOutlined';
import AuthContext from '../contexts/AuthContext';
import useFormHelper from '../hooks/useFormHelper';
import {
  useFormik,
  FormikTextField
} from '../utils/formik';
import { VerificationSchema } from '../utils/validation';
import ReverifyDialog from './ReverifyDialog';

function VerificationForm({ onSave, skippable }) {
  const { api, patchMe } = useContext(AuthContext);
  const [reverifying, setReverifying] = useState(false);

  const formik = useFormik({
    initialValues: { code: '', type: 'cell' },
    validateOnBlur: true,
    validationSchema: VerificationSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.post('users/verify', values);
      if (response) {
        patchMe(response);
        if (onSave) onSave(response);
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  const onReverify = () => {
    setReverifying(true);
  };
  const onDoneReverifying = () => {
    setReverifying(false);
  };
  const skip = () => {
    if (onSave) onSave();
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div style={{ display: 'flex' }}>
          <FormikTextField
            autoFocus
            fullWidth
            name="code"
            label="Verification Code"
            placeholder="Enter your code..."
            variant="outlined"
            style={{ flex: 1, marginRight: 8 }}
            formik={formik}
          />

          <Button
            color="primary"
            disabled={formik.isSubmitting}
            size="large"
            type="submit"
            variant="contained">Submit Code</Button>
        </div>
        <Typography variant="subtitle2" style={{ marginTop: 16 }}>
          Didn't receive a text message? <Button color="primary" onClick={onReverify}>Update your number or request a new code.</Button>
        </Typography>
        {skippable && (
          <Button
            endIcon={<SkipIcon />}
            onClick={skip}
            size="large"
            style={{ marginTop: 40, opacity: 0.5 }}
            variant="outlined">
            Skip this Step
          </Button>
        )}
      </form>

      <ReverifyDialog type="cell" onClose={onDoneReverifying} open={reverifying} />
    </div>
  );
}

export default VerificationForm;
