import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  colors,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Hidden,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import AddLocationIcon from '@material-ui/icons/AddLocationOutlined';
import CompleteIcon from '@material-ui/icons/CheckCircle';
import EditLocationIcon from '@material-ui/icons/EditLocationOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import ReviewIcon from '@material-ui/icons/FindReplaceOutlined';
import Layout from '../../components/Layout';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';

const useStyles = makeStyles((theme) => ({
  accordionButton: {
    ['& > div:first-child > div']: {
      alignItems: 'center',
      display: 'flex',
      width: '100%',
    },
    ['&:not($cancelHover):hover']: {
      background: '#f4f4f4'
    },
  },
  cancelButton: {
    cursor: 'default !important',
    ['&:hover']: {
      background: '#fff'
    },
  },
  cancelHover: {},
  halfOpacity: {},
  stageLabel: {
    whiteSpace: 'nowrap',
    marginRight: 6,
    '&$halfOpacity': {
      opacity: 0.3
    },
    [theme.breakpoints.down('xs')]: {
      color: theme.palette.primary.main
    }
  },
  stageProgress: {
    height: 6,
    width: 100,
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
}));

const stages = {
  'research': {
    status: 5,
    label: 'Finding Links',
    Icon: AddLocationIcon
  },
  'review': {
    status: 33,
    label: 'Pending Review',
    Icon: ReviewIcon,
    halfOpacity: true
  },
  'cleanup': {
    status: 66,
    label: 'Editing Fields',
    Icon: EditLocationIcon
  },
  'complete': {
    status: 100,
    color: colors.green.A400,
    label: 'Complete',
    Icon: CompleteIcon,
    halfOpacity: true,
  },
};

function ResearchState({ api, classes, onAddUrl, onError, onReload, stateInfo }) {
  const [data, setData] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);

  const { state, stateName, stage } = stateInfo;

  const handleAddUrl = () => {
    onAddUrl(state, (newUrl) => {
      if (newUrl) {
        const newData = { ...data };
        newData.urls.push(newUrl);
        setData(newData);
      }
    });
  };

  const handleToggle = async () => {
    const newlyExpanded = !expanded;
    setExpanded(newlyExpanded);
    if (newlyExpanded) {
      // fetch data if in a relevant stage
      if (stage === 'research' || stage === 'cleanup') {
        setLoading(true);
        const { response, error } = await api.get(`volunteer/research/${state}`);
        if (response) {
          if (response.stage === stage) {
            setData(response || {});

          // if stage has changed, reload more
          } else {
            onError(`${state} has been updated. Please try again.`);
            setExpanded(false);
            onReload();
          }
        } else {
          onError(error || `Could not load data for ${state}. Please try again.`);
        }
        setLoading(false);
      }
    }
  };

  const { Icon, color, label, halfOpacity, status } = stages[stage];
  return (
    <Accordion expanded={expanded} onChange={handleToggle}>
      <AccordionSummary
        classes={{ expanded: classes.cancelHover }}
        className={clsx(classes.accordionButton, { [classes.cancelButton]: halfOpacity })}
        expandIcon={<ExpandMoreIcon />}>
        <div>
          <Icon style={{ color, marginRight: 4, opacity: halfOpacity ? 0.3 : 1 }} />
          <Typography style={{ flex: 1, opacity: halfOpacity ? 0.3 : 1 }}>{stateName}</Typography>
          <Typography variant="caption" className={clsx(classes.stageLabel, { [classes.halfOpacity]: halfOpacity })}>{label}</Typography>
          <LinearProgress className={classes.stageProgress} variant="determinate" value={status} />
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ borderTop: '1px solid #eee', flexDirection: 'column' }}>
        {loading && <LinearProgress style={{ marginTop: 8 }} />}
        {!loading && (
          <>
            {stage === 'research' && (
              <>
                <Alert severity="info">
                  <AlertTitle>
                    Add as many websites as required to document the full list of {stateName} vaccinators.
                  </AlertTitle>
                  <ul style={{ margin: 0, paddingLeft: 16 }}>
                    <li>In some states, this is easily available from the state's Department of Public Health (DPH) or dedicated COVID website.</li>
                    <li>Some states publish a comprehensive vaccine orders list in PDF format.</li>
                    <li>Other states may rely on each county DPH to host their own provider lists (in which case we'll need a link to every county).</li>
                    <li>Others may rely on local media or third party aggregators.</li>
                    <li>You may even have to call the DPH to request the list.</li>
                  </ul>
                </Alert>

                {/* TODO: UserA can delete URLs UserA added */}
                {data && (
                  <List disablePadding>
                    {(data.urls || []).map((item) => (
                      <ListItem key={item.url}>
                        <ListItemIcon><LinkIcon /></ListItemIcon>
                        <ListItemText primary={item.name} secondary={item.url} />
                      </ListItem>
                    ))}
                  </List>
                )}

                <div style={{ marginTop: 16, textAlign: 'center' }}>
                  <Button
                    color="secondary"
                    onClick={handleAddUrl}
                    size="large"
                    startIcon={<Icon />}
                    style={{ minWidth: 200 }}
                    variant="contained">Add URL</Button>
                </div>
              </>
            )}
            {stage === 'review' && (
              <Typography variant="body2" style={{ textAlign: 'center', padding: '24px 0', width: '100%' }}>
                Our internal team is using the submitted links to import the needed data. When we finish with this step,{' '}
                {stateName}'s status will be updated here.
              </Typography>
            )}
            {stage === 'cleanup' && (
              <div>
                {/*
                  TODO: "Usually, this just requires some Google Maps research."
                  TODO: This doesn't need to work yet.
                */}
              </div>
            )}
            {stage === 'complete' && (
              <Typography variant="body2" style={{ textAlign: 'center', padding: '24px 0', width: '100%' }}>
                {stateName}'s provider research has now been completed and the
                compiled list is being shared with our call team.
              </Typography>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}


export default function ResearchPage({ history }) {
  const classes = useStyles();
  const initialForm = { url: '', notes: '' };
  const { api } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [addUrlState, setAddUrlState] = useState(null);
  const [form, setForm] = useState(initialForm);
  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const breadcrumbs = [
    { label: 'Volunteer', href: '/volunteer' },
    { label: 'Action Center', href: '/volunteer' },
    { label: 'Vaccine Clinic Research' },
  ];

  const onAddUrl = (state, callback) => {
    setAddUrlState({ state, callback });
  };

  const handleClose = (synthEvent, event, newData) => {
    if (addUrlState.callback) addUrlState.callback(newData);
    setAddUrlState(null);
    setForm({ ...initialForm });
    setSubmitting(false);
  };

  const handleUrlAdded = async () => {
    if (!form.url) return onError('Please provider the URL you want to add.');
    setSubmitting(true);
    const { response, error } = await api.post(`volunteer/research/${addUrlState.state}`, { ...form });
    setSubmitting(false);
    if (response) {
      return handleClose(null, null, response);
    }
    return onError(error || 'There was a problem saving the URL. Please try again.');
  };

  const onChange = (which) => (event) => {
    setForm({
      ...form,
      [which]: event.target.value
    });
  };

  const onError = (msg) => {
    popToast(msg, 'error');
  };

  const initData = async () => {
    setLoading(true);
    const { response, error } = await api.get('volunteer/research');
    if (response && response.length > 0) {
      setStates(response);
    } else {
      onError(error || 'There was a problem loading data. Please refresh the page to try again.')
    }
    setLoading(false);
  };

  useEffect(async () => {
    initData();
  }, []);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Alert severity="info">
        <AlertTitle>
          Our current research objective is to build a call list for outreach to all the vaccine providers in the US.
          In each state, this requires these steps:
        </AlertTitle>
        1) <b>Research</b>: Volunteers will help find the websites where this list is published (in some states, this will require multiple websites in order to be comprehensive).<br/>
        2) <b>Review</b>: Our internal team will review the URLs for the state, import the good data, and highlight the missing data.<br/>
        3) <b>Editing</b>: Volunteers will help fill in any missing data that our internal review team was unable to uncover.<br/>
        4) <b>Completion</b>: Once a list is ready, we'll import it for our Phone Volunteers so they help signup providers in that state.
      </Alert>

      <Typography variant="h6" style={{ marginTop: 16 }}>Click on a state below to contribute<Hidden xsDown> to the research</Hidden>:</Typography>
      {loading && <LinearProgress style={{ marginTop: 8 }} />}
      {states.map((state) => (
        <ResearchState
          key={state.state}
          api={api}
          classes={classes}
          onAddUrl={onAddUrl}
          onError={onError}
          onReload={initData}
          stateInfo={state} />
      ))}

      <Dialog fullWidth onClose={handleClose} open={Boolean(addUrlState)}>
        <DialogContent>
          <TextField
            autoFocus
            fullWidth
            label="Website URL"
            onChange={onChange('url')}
            placeholder="Copy and paste the URL here..."
            value={form.url} />
          <TextField
            fullWidth
            multiline
            label="Notes (Optional)"
            onChange={onChange('notes')}
            style={{ margin: '16px 0' }}
            value={form.notes} />
        </DialogContent>
        <Divider />
        <DialogActions style={{ padding: '8px 24px' }}>
          <Button color="default" disabled={submitting} onClick={handleClose} variant="outlined">
            Cancel
          </Button>
          <div style={{ flex: 1 }} />
          <Button color="secondary" disabled={submitting} onClick={handleUrlAdded} variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}