import React, { useState } from 'react';
import Toast from '../components/Toast';

const ToastContext = React.createContext();
export default ToastContext;

// TODO (v2): change this to UtilProvider, and add:
//  - confirm (string or node)
//  - prompt
//  * ^^ could even probably use await to still use like if(await confirm(x))
//  - alert (string or node)
//  - handleApiResponse (returns response or pops error toast or prompts to log back in)

export function ToastProvider({ children }) {
  const [message, setMessage] = useState(null);
  const [toastProps, setToastProps] = useState(null);

  const clearToast = () => {
    setMessage('');
  };

  const popToast = (message, type = 'info', duration = 6000) => {
    setMessage(message);
    setToastProps({ type, duration });
  }

  const context = {
    clearToast,
    popToast
  };

  return (
    <ToastContext.Provider value={context}>
      {children}
      <Toast messageState={[message, setMessage]} {...toastProps} />
    </ToastContext.Provider>
  );
}