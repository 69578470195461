import React, { useContext } from 'react';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import Account from './account';
import Call from './call';
import Research from './research';
import Index from './index';

export default () => {
  const { path } = useRouteMatch();
  const { me } = useContext(AuthContext);
  return (
    <Switch>
      <Route exact path={path} component={Index} />
      <Route path={`${path}/call`} component={Call} />
      <Route path={`${path}/research`} component={Research} />
      <Route path={`${path}/account`} component={Account} />
      <Route><Redirect to={`${path}`} /></Route>
    </Switch>
  );
}