import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Collapse,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CyclingIcon from '@material-ui/icons/DirectionsBike';
import DropdownIcon from '@material-ui/icons/ArrowDropDown';
import DrivingIcon from '@material-ui/icons/DirectionsCar';
import LateIcon from '@material-ui/icons/Warning';
import TransitIcon from '@material-ui/icons/DirectionsTransit';
import WalkingIcon from '@material-ui/icons/DirectionsWalk';
import AddressAutocomplete, { geocodeResult } from '../AddressField';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import styles from '../../styles/base';

const methods = [
  { label: 'Select', value: '', Icon: null },
  { label: 'Driving', value: 'driving', Icon: DrivingIcon },
  { label: 'Public Transit', value: 'transit', Icon: TransitIcon },
  { label: 'Bicycling', value: 'bicycling', Icon: CyclingIcon },
  { label: 'Walking', value: 'walking', Icon: WalkingIcon },
];

function MatchForm({ classes, doseId, refreshMatches, onSave }) {
  const { api, me, logout } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  console.log('render MatchForm');
  const xsDown = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [form, setForm] = useState({
    address: me.patient?.geography?.address ?? '',
    geocode: me.patient?.geography?.geocode ?? null,
    distance: '',
    duration: '',
    eta: '',
    etaType: '',
    etd: '',
    travelMethod: '',
  });
  const [inNeed, setInNeed] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modeMenuAnchor, setModeMenuAnchor] = useState(null);

  const setFields = (kvps) => {
    setForm({ ...form, ...kvps });
  };

  const setField = (key, value) => {
    setFields({ [key]: value });
  };

  

  useEffect(async () => {
    if (form.geocode.length === 2) {
      if (form.travelMethod) {
        setLoading(true);
        const { response, error } = await api.post(`matches/${doseId}/eta`, {
          doseId,
          origin: form.geocode,
          method: form.travelMethod
        });
        if (response) {
          setFields({
            distance: response.distance,
            duration: response.duration,
            etd: new Date(response.etd),
            eta: new Date(response.eta),
            etaType: response.etaType,
          });
        } else if (error) {
          popToast(error, 'error');
          refreshMatches();
        } else {
          popToast('Something went wrong. Please try again.', 'error');
        }
        setLoading(false);
      }
    }
  }, [form.geocode, form.travelMethod]);
  

  const onChangeAddress = async (value) => {
    const geocodeData = value ? await geocodeResult(value) : {};
    setFields({
      address: geocodeData.address,
      geocode: geocodeData.geocode,
    });
  };

  const onClaimDose = async () => {
    const { response, error } = await api.post(`matches/${doseId}/claim`, { ...form });
    if (response) {
      refreshMatches();
    } else if (error) {
      popToast(error, 'error');
      refreshMatches();
    } else {
      popToast('Something went wrong. Please try again.', 'error');
    }
  };

  const onRejectDose = async () => {
    if (window.confirm('Are you sure you want to decline this dose?')) {
      const { response, error } = await api.post(`matches/${doseId}/reject`, { ...form });
      if (response) {
        refreshMatches();
      } else if (error) {
        popToast(error, 'error');
        refreshMatches();
      } else {
        popToast('Something went wrong. Please try again.', 'error');
      }
    }
  };

  const onSelectMode = (value) => () => {
    setField('travelMethod', value);
    closeModeMenu();
  };

  const onSetNeed = (hasNeed) => async () => {
    if (!hasNeed) {
      if (window.confirm("Are you sure? If you no longer need your account, we will terminate it now and permanently delete any sensitive data from our database. You will no longer be able to login or be notified about available vaccine doses.")) {
        const { response, error } = await api.del(`users`);
        if (response) {
          popToast('Thanks for using VaccineList! Your account has now been removed.');
          logout();
        } else {
          popToast(error || 'Something went wrong. Please try again.', 'error');
        }
      }
    } else {
      setInNeed(true);
    }
  };

  const openModeMenu = (event) => {
    setModeMenuAnchor(event.target);
  };

  const closeModeMenu = () => {
    setModeMenuAnchor(null);
  };
  

  const now = new Date();
  const selectedMode = methods.find(({ value }) => value === form.travelMethod);
  return (
    <Paper style={{ border: '8px outset #3f51b5', padding: 24, maxWidth: 620 }}>
      <Typography variant="h3" color="primary">Dose Available!</Typography>
      <Typography variant="h6" className={classes.onboardingSubheader}>
        You have matched to an available vaccine dose. Please confirm the information below to claim it.
      </Typography>
      <Divider />
      <div style={{ paddingTop: 32, paddingBottom: 8 }}>
        <Collapse in={inNeed === null}>
          <div className={classes.formContainer}>
            <Typography className={classes.matchQuestion}>Do you still want and need a COVID vaccine?</Typography>
            <div style={{ display: 'flex' }}>
              <Button
                fullWidth
                color="primary"
                onClick={onSetNeed(true)}
                size="large"
                variant="contained">Yes</Button>
              <div style={{ width: 16 }} />
              <Button
                fullWidth
                color="default"
                onClick={onSetNeed(false)}
                size="large"
                variant="contained">No</Button>
            </div>
          </div>
        </Collapse>

        <Collapse in={inNeed}>
          <div className={classes.formContainer}>
            <Typography className={classes.matchQuestion}>Where are you now and how will you get to the clinic?</Typography>
            <div className={classes.matchAddressRow}>
              <div>
                <AddressAutocomplete
                  defaultValue={form.address || ''}
                  label="Current Location"
                  onChange={onChangeAddress} />
              </div>
              <Button
                color="primary"
                endIcon={<DropdownIcon />}
                onClick={openModeMenu}
                startIcon={selectedMode.Icon && <selectedMode.Icon />}
                variant={selectedMode.value ? "outlined" : "contained"}>
                {selectedMode.label}
              </Button>
              <Menu anchorEl={modeMenuAnchor} open={Boolean(modeMenuAnchor)} onClose={closeModeMenu}>
                {methods.slice(1).map(({ Icon, label, value }) => (
                  <MenuItem onClick={onSelectMode(value)}>
                    <ListItemIcon><Icon /></ListItemIcon>
                    <ListItemText>{label}</ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>

          {form.geocode.length === 2 && form.travelMethod && (
            <Paper elevation={4} style={{ margin: `16px auto`, padding: `16px 24px` }}>
              <Collapse in={loading} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </Collapse>
              <Collapse in={!loading && form.distance}>
                <div className={classes.formContainer}>
                  <Table>
                    <TableBody>
                      {xsDown && (
                        <TableRow>
                          <TableCell variant="head">Latest Departure Time</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        {!xsDown && <TableCell variant="head">Latest Departure Time</TableCell>}
                        <TableCell align="right">
                          {moment(form.etd).calendar()}
                          <Typography
                            color={form.etd < now ? 'error' : 'textSecondary'}
                            display="block"
                            variant="caption">
                            {form.etd < now && <LateIcon fontSize="small" style={{ verticalAlign: 'bottom' }} />}
                            {moment(form.etd).fromNow()}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      {xsDown && (
                        <TableRow>
                          <TableCell variant="head">Latest Departure Time</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        {!xsDown && <TableCell variant="head">Estimated Travel Time</TableCell>}
                        <TableCell align="right">
                          {form.duration}
                          <Typography color="textSecondary" display="block" variant="caption">
                            {form.distance} away
                          </Typography>
                        </TableCell>
                      </TableRow>

                      {xsDown && (
                        <TableRow>
                          <TableCell variant="head">
                            {form.etaType === 'before' ? 'Arrive Before' : 'Appointment Time'}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        {!xsDown && (
                          <TableCell variant="head">
                            {form.etaType === 'before' ? 'Arrive Before' : 'Appointment Time'}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {moment(form.eta).calendar()}
                          <Typography color="textSecondary" display="block" variant="caption">
                            {moment(form.eta).fromNow()}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  <Typography className={classes.matchQuestion}>Can you arrive in time to receive this dose?</Typography>
                  <div className={classes.matchButtons}>
                    {/* TODO (v2): disable claiming if timing looks impossible */}
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={onClaimDose}
                      size="large"
                      type="submit"
                      variant="contained">Claim Dose</Button>
                    <div style={{ width: 16 }} />
                    <Button
                      color="default"
                      fullWidth
                      onClick={onRejectDose}
                      size="large"
                      variant="contained">No</Button>
                  </div>
                  <Typography align="center" color="textSecondary" display="block" variant="caption">
                    If you can't make it today, don't panic! There is a clinic in your
                    area with extra doses, so while there are no guarantees, this
                    opportunity will probably present itself again.
                  </Typography>
                </div>
              </Collapse>
            </Paper>
          )}
        </Collapse>
      </div>

      <Alert severity="warning">
        More people on our list will be offered this dose every minute, so please respond quickly.
      </Alert>
    </Paper>
  );
}

export default withStyles(styles)(MatchForm);