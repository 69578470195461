import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  ButtonGroup,
  FormControl,
  Hidden,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import useFormHelper from '../../hooks/useFormHelper';
import { useFormik } from '../../utils/formik';
import {
  ProviderRemindersConstants,
  ProviderRemindersSchema
} from '../../utils/validation';

const { reminderDays, reminderTimes } = ProviderRemindersConstants;

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& > *': {
      margin: `${theme.spacing(3)}px 0 ${theme.spacing(2)}px`,
    }
  },
}));

export default function ContactPreferences({ history, onSave }) {
  const classes = useStyles();

  const { api, me, patchMe } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);

  const initialReminders = {};
  reminderDays.forEach((day) => {
    initialReminders[day] = me?.provider?.reminders?.[day] ?? '';
  });

  const formik = useFormik({
    initialValues: {
      preferSMS: me?.provider?.preferSMS ?? false,
      reminders: initialReminders
    },
    validateOnBlur: true,
    validationSchema: ProviderRemindersSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/provider/contact', values);
      if (response) {
        patchMe(response);
        const needsSMS = (response.provider.preferSMS && !response.cell);
        if (needsSMS) {
          popToast(
            'If you prefer communication via text message (SMS), please also provide your cell number on the Account page.',
            'warning'
          );
        }
        if (onSave) onSave(needsSMS);
        else if (history && needsSMS) history.push('/provider/account');
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const updatePreference = (which) => () => {
    formik.setFieldValue('preferSMS', which)
  };

  const updateDay = (day) => (event) => {
    formik.setFieldValue(`reminders.${day}`, event.target.value);
  };

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <div>
        <Typography variant="subtitle2">
          Would you prefer we communicate with you via email or text message (cell phone)?
        </Typography>
        <ButtonGroup color="primary" fullWidth>
          <Button
            onClick={updatePreference(false)}
            variant={!formik.values.preferSMS ? 'contained' : 'outlined'}>
            Email
          </Button>
          <Button
            onClick={updatePreference(true)}
            variant={formik.values.preferSMS ? 'contained' : 'outlined'}>
            Cell Phone
          </Button>
        </ButtonGroup>
      </div>

      <div>
        <Typography variant="subtitle2">
          If you have not yet added any doses for the day, what time should we send a
          check-in message to see if there are any doses available for the waitlist?
        </Typography>
        <Hidden smDown>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 8 }}>
            {reminderDays.map((day) => (
              <FormControl key={day}>
                <InputLabel shrink>{day}</InputLabel>
                <Select
                  displayEmpty
                  onChange={updateDay(day)}
                  error={(formik.touched.reminders || {})[day] && Boolean((formik.errors.reminders || {})[day])}
                  value={(formik.values.reminders || {})[day] || ''}>
                  <MenuItem value=""><em style={{ opacity: 0.4 }}>(n/a)</em></MenuItem>
                  {reminderTimes.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>{label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
          </div>
        </Hidden>
        <Hidden mdUp>
          <List>
            {reminderDays.map((day) => (
              <ListItem key={day}>
                <ListItemText>{day}</ListItemText>
                <ListItemSecondaryAction>
                  <Select
                    displayEmpty
                    onChange={updateDay(day)}
                    error={(formik.touched.reminders || {})[day] && Boolean((formik.errors.reminders || {})[day])}
                    value={(formik.values.reminders || {})[day] || ''}
                    style={{ width: 90 }}>
                    <MenuItem value=""><em style={{ opacity: 0.4 }}>(none)</em></MenuItem>
                    {reminderTimes.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))}
                  </Select>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Hidden>
      </div>

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">
        Submit
      </Button>
    </form>
  );
}