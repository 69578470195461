import jumbotronImage from '../images/vaccine.jpg';
// https://images.pexels.com/photos/5863398/pexels-photo-5863398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260
// https://images.pexels.com/photos/5922094/pexels-photo-5922094.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260
// https://images.pexels.com/photos/5863398/pexels-photo-5863398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260
// (model): https://www.engagetu.com/wp-content/uploads/2019/05/economics-of-vaccinations.jpg
const arrowColor = '#f50057';
const arrowSize = 20;
const containerWidth = 912;
export default (theme) => ({
  appbar: {
    [theme.breakpoints.down('xs')]: {
      color: 'white',
      position: 'absolute',
    }
  },
  arrowDown: {
    width: 0,
    height: 0,
    borderLeft: `${arrowSize}px solid transparent`,
    borderRight: `${arrowSize}px solid transparent`,
    borderTop: `${arrowSize}px solid ${arrowColor}`,
    [theme.breakpoints.down('xs')]: {
      borderLeft: `${3 * arrowSize}px solid transparent`,
      borderRight: `${3 * arrowSize}px solid transparent`,
      borderTop: `${3 * arrowSize}px solid ${arrowColor}`,
    }
  },
  arrowDownBox: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      paddingTop: `${1.5 * arrowSize + 2}px`,
    }
  },
  finalSignup: {
    margin: '0 24px',
    fontSize: '25px',
    padding: '12.5px 36px',
    borderRadius: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  finalSignupAux: {
    width: 132,
  },
  finalSignupAuxRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '12px 24px 0',
    ['& > a']: {
      flex: 1,
      width: 'auto'
    }
  },
  howItWorks: {
    padding: '60px 60px 100px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 12,
      paddingRight: 12,
    }
  },
  howItWorksFlex: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  howItWorksLeft: {
    flex: 1,
    borderRight: `6px dashed ${arrowColor}`,
    padding: '36px 24px',
    ['& > div']: {
      padding: 24,
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      ['& > div']: {
        padding: `24px 0`,
      }
    },
    [theme.breakpoints.down('xs')]: {
      borderRight: 0,
      padding: `16px 0`,
      ['& > div']: {
        padding: `24px 0`,
        ['& > ul li']: {
          alignItems: 'flex-start',
          ['& > div:last-child']: {
            marginTop: 0
          },
        }
      }
    },
  },
  howItWorksRight: {
    flex: 1,
    padding: '36px 24px',
    ['& > div']: {
      padding: 24,
      height: '100%'
    },
    [theme.breakpoints.down('sm')]: {
      ['& > div']: {
        padding: `24px 0`,
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: `16px 0`,
      ['& > div']: {
        padding: `24px 0`,
        ['& > ul li']: {
          alignItems: 'flex-start',
          ['& > div:last-child']: {
            marginTop: 0
          },
        }
      }
    },
  },
  jumbotron: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    padding: `0 ${theme.spacing(4)}px`,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      flex: 2,
      maxWidth: 450,
      ['& h2']: {
        lineHeight: 1,
        marginBottom: theme.spacing(),
      }
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'none',
    }
  },
  jumbotronButtons: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(3)}px ${theme.spacing(-4)}px 0 0`,
      ['& button:first-child']: {
        borderColor: 'white',
        color: 'white'
      },
    }
  },
  jumbotronImage: {
    backgroundImage: `url(${jumbotronImage})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    clipPath: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
    flex: 1,
    height: '100%',
    shapeOutside: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%)',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  jumbotronOuter: {
    alignItems: 'center',
    border: '1px solid #DDD',
    borderWidth: '1px 0',
    display: 'flex',
    flexDirection: 'row',
    height: '80vh',
    maxHeight: 800,
    position: 'relative',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${jumbotronImage})`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      border: 'none',
      color: 'white',
      ['&:before']: {
        background: 'rgba(50, 50, 50, 0.7)',
        bottom: 0,
        content: '""',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
      }
    },
  },
  midSection: {
    margin: `60px auto`,
    width: '100%',
    maxWidth: containerWidth,
    padding: `0 ${theme.spacing(2)}px`,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 76,
      ['& h6']: {
        textAlign: 'justify',
      }
    }
  },
  offset: theme.mixins.toolbar,
  root: {
    flexGrow: 1,
  },
  slogan: {
    padding: '24px',
    background: '#3f51b5',
    ['& h4']: {
      color: 'white',
      fontStyle: 'italic',
      fontWeight: 'bold',
      textAlign: 'center',
      maxWidth: containerWidth,
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      }
    }
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75),
    fontWeight: 400
  },
  what: {
    background: '#f50057',
    color: 'white',
    position: 'absolute',
    width: 160,
    height: 80,
    borderRadius: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    zIndex: 1,
    marginTop: -78,
    left: '50%',
    marginLeft: -80
  },
  whatBox: {
    padding: '36px',
    position: 'relative',
    marginTop: 100,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    }
  },
  whatCaption: {
    position: 'absolute',
    opacity: 0.7,
    right: theme.spacing(4),
    bottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(-6),
      left: theme.spacing(2),
      right: theme.spacing(2),
    }
  },
  whatItem: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      ['& span']: {
        fontSize: '0.9rem',
      }
    }
  }
});