import React, { useContext } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Hidden,
  Paper,
  Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import styles from '../../styles/base';

const useStyles = makeStyles(styles);

const myTimezoneOffset = -1 * (new Date()).getTimezoneOffset();

export default function MatchDetails({ match, refreshMatches }) {
  const { api } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const classes = useStyles();

  const matchInfo = match.matchInfo || {};
  const timezoneShift = myTimezoneOffset - (matchInfo.clinic?.timezone?.offset || 0);
  const clinicApptTime = new Date((new Date(matchInfo.apptTime)).getTime() - timezoneShift * 60e3);

  const cancelAppointment = async () => {
    if (window.confirm('Are you sure you want to cancel your appointment? This dose will be released to someone else on the waitlist. This cannot be undone.')) {
      const { response, error } = await api.post(`matches/${match.doseId}/cancel`);
      if (response) {
        refreshMatches();
      } else if (error) {
        popToast(error, 'error');
        refreshMatches();
      } else {
        popToast('Something went wrong. Please try again.', 'error');
      }
    }
  };

  return (
    <Paper className={classes.paddedPaper} style={{ marginBottom: 60 }}>
      <div style={{ display: 'flex', alignItems: 'center', color: green.A700 }}>
        <Hidden xsDown>
          <CheckIcon fontSize="large" />
          <CheckIcon fontSize="large" />
          <CheckIcon fontSize="large" />
        </Hidden>
        <Typography variant="h3" style={{ flex: 1, textAlign: 'center' }}>
          Appointment Scheduled
        </Typography>
        <Hidden xsDown>
          <CheckIcon fontSize="large" />
          <CheckIcon fontSize="large" />
          <CheckIcon fontSize="large" />
        </Hidden>
      </div>

      <Typography variant="h6" className={classes.onboardingSubheader} align="center" style={{ marginBottom: 16 }}>
        Congrats! Now go get yourself vaccinated! All the details you need are below.
      </Typography>

      {/* TODO (v2): countdown timer? */}
      <div className={classes.flexCards}>
        <Card elevation={3} className={classes.flexCard}>
          <CardContent style={{ flex: 1 }}>
            <Typography gutterBottom variant="h6">
              Where to Go
            </Typography>
            <Typography>
              {matchInfo.clinic.name}<br/>
              {matchInfo.clinic.address}<br/>
              {matchInfo.contactNumber}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              component="a"
              href={`tel:${matchInfo.contactNumber}`}
              target="_blank">Call Clinic</Button>
            <div style={{ flex: 1 }} />
            <Button
              component="a"
              color="primary"
              href={`https://www.google.com/maps/dir//${encodeURIComponent(matchInfo.clinic.address)}/`}
              target="_blank">Get Directions</Button>
          </CardActions>
        </Card>

        <Card elevation={3} className={classes.flexCard}>
          <CardContent style={{ flex: 1 }}>
            <Typography gutterBottom variant="h6">
              When to Be There
            </Typography>
            <Typography>
              Please arrive{' '}
              <u>{matchInfo.apptType === 'at' ? 'shortly before' : 'anytime before'}</u>...
            </Typography>
            <Typography variant="h3">
              {moment(clinicApptTime).format('LT')}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {timezoneShift !== 0 && (
                <>
                  <b>{matchInfo.clinic?.timezone?.label ?? timezoneShift}</b> |{' '}
                </>
              )}
              {moment(clinicApptTime).format('LL')}
            </Typography>
            
            {timezoneShift !== 0 && (
              <Alert severity="warning" variant="filled">
                This clinic does not appear to be in your current timezone.
                Please arrive according to your appointment time
                in the clinic's timezone.
              </Alert>
            )}
          </CardContent>
          <CardActions>
            <div style={{ flex: 1 }} />
            <Button disabled>Please arrive on time</Button>
          </CardActions>
        </Card>

        <Card elevation={3} className={classes.flexCard}>
          <CardContent style={{ flex: 1 }}>
            <Typography gutterBottom variant="h6">
              When You Arrive
            </Typography>
            <Typography variant="body2">
              {matchInfo.arrivalInstructions}
            </Typography>
            
            <Typography variant="subtitle2" style={{ marginTop: 16 }}>Additional Notes</Typography>
            <Typography variant="body2">
              Bring Your Id &middot; Wear a Mask &middot; Practice Social Distancing
            </Typography>
          
          </CardContent>
          <CardActions>
            <div style={{ flex: 1 }} />
            <Button color="primary" onClick={window.print}>Print this Page</Button>
          </CardActions>
        </Card>
      </div>
      
      <Alert severity="info" className={classes.noPrint} style={{ marginTop: 16 }} action={(
        <Button color="inherit" onClick={cancelAppointment} size="small">
          Cancel Appointment
        </Button>
      )}>
        If something changes and you absolutely cannot make it on-time to your appointment,
        please cancel as soon as possible.
      </Alert>
    </Paper>
  );
}