import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Hidden from '@material-ui/core/Hidden';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import DateIoMoment from '@date-io/moment';
import AuthContext from '../../../contexts/AuthContext';
import useFormHelper from '../../../hooks/useFormHelper';
import styles from '../../../styles/base';
import {
  useFormik,
  FormikLabeledCheckbox,
  FormikSelect
} from '../../../utils/formik';
import {
  PatientHealthRisksSchema,
  PatientRisksConstants
} from '../../../utils/validation';

const { maxBirthday, conditionBuckets } = PatientRisksConstants;


function HealthRisk({ classes, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);

  const myRisks = me.patient?.risks ?? {};
  const formik = useFormik({
    initialValues: {
      birthday: myRisks.birthday ? new Date(me.patient.risks.birthday) : null,
      healthRisksSevere: myRisks.healthRisksSevere || 0,
      healthRisksMixed: myRisks.healthRisksMixed || 0,
      healthRisksLimited: myRisks.healthRisksLimited || 0,
      covid: myRisks.covid || false,
    },
    validateOnBlur: true,
    validationSchema: PatientHealthRisksSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/patient/risks', {
        page: 'health',
        ...values
      });
      if (response) {
        patchMe(response);
        if (onSave) onSave();
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);


  const commonChipProps = {
    className: classes.healthConditionChip,
    size: 'small',
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <div className={classes.flexPivotOnSm}>
        <div style={{ flex: 1 }}>
          <MuiPickersUtilsProvider utils={DateIoMoment}>
            <DatePicker
              autoOk
              disableFuture
              fullWidth
              maxDate={maxBirthday}
              openTo="year"
              format="MMMM D, YYYY"
              label="Birthday"
              placeholder="Click to add your age..."
              views={["year", "month", "date"]}
              name="birthday"
              value={formik.values.birthday}
              onChange={value => formik.setFieldValue('birthday', value)}
              error={formik.touched.birthday && Boolean(formik.errors.birthday)}
              InputLabelProps={{ shrink: true }}
            />
          </MuiPickersUtilsProvider>
        </div>

        <Hidden xsDown><div style={{ width: 20 }} /></Hidden>
        
        <FormControl className={classes.flexWrapped}>
          <InputLabel htmlFor="covid-status" shrink>Infection History</InputLabel>
          <div style={{ paddingTop: 10 }}>
            <FormikLabeledCheckbox
              color="primary"
              id="covid-status"
              label="I have previously tested positive for COVID-19."
              name="covid"
              formik={formik}
            />
          </div>
        </FormControl>
      </div>

      <Paper elevation={4}>
        <Typography variant="subtitle2" style={{ background: '#3f51b5', padding: '12px 16px', color: 'white' }}>Indicate how many conditions you have in each of the following groups:</Typography>
        <div style={{ padding: 12 }}>
          {Object.keys(conditionBuckets).map((tier) => {
            const conditions = conditionBuckets[tier];
            return (
              <div key={tier} className={classes.healthTierBox}>
                <FormikSelect
                  name={tier}
                  formik={formik}
                  className={classes.healthTierSelect}
                  variant="outlined">
                  <MenuItem value={0}>None</MenuItem>
                  <MenuItem value={1}>One</MenuItem>
                  <MenuItem value={2}>Two (or more)</MenuItem>
                </FormikSelect>

                <div className={classes.healthTierConditions}>
                  {conditions.map((condition) => {
                    if (Array.isArray(condition)) {
                      return (
                        <Tooltip key={condition[0]} title={condition[1]} enterTouchDelay={0}>
                          <Chip
                            icon={<InfoIcon color="primary" />}
                            label={condition[0]}
                            {...commonChipProps} />
                        </Tooltip>
                      );
                    } else {
                      return (
                        <Chip
                          key={condition}
                          label={condition}
                          {...commonChipProps} />
                      );
                    }
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </Paper>

      <Alert severity="error" style={{ textAlign: 'left' }}>
        All information submitted here may be verified at the vaccination clinic prior to
        receiving a vaccination. By clicking "Submit", you agree that all information
        submitted is accurate to the best of your ability.
        <br/><br/>
        <strong>Overrepresentation of your risk profile may result in legal action and/or unnecessarily wasted doses.</strong>
      </Alert>

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">Submit</Button>
    </form>
  );
}

export default withStyles(styles)(HealthRisk);
