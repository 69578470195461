export function initMapsApi(ref) {
  if (typeof window !== 'undefined' && !ref.current) {
    if (!document.querySelector('#google-maps')) {
      const script = document.createElement('script');
      script.setAttribute('async', '');
      script.setAttribute('id', 'google-maps');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
      document.querySelector('head').appendChild(script);
    }

    ref.current = true;
  }
}

let autocompleteService;
export function getAutocompleteService() {
  if (!autocompleteService) {
    try {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    } catch (e) {
      autocompleteService = undefined;
    }
  }
  return autocompleteService;
}

let geocoderService;
export function getGeocoderService() {
  if (!geocoderService) {
    try {
      geocoderService = new window.google.maps.Geocoder();
    } catch (e) {
      geocoderService = undefined;
    }
  }
  return geocoderService;
}
