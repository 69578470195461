import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import GeographyForm from '../../components/patient/Geography';
import { HealthRisk, OccupationRisk, HouseholdRisk, OtherRisk } from '../../components/patient/risk/index';
import VerificationForm from '../../components/VerificationForm';
import Layout from '../../components/Layout';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import styles from '../../styles/base';

function PatientSignup({ classes }) {
  const { api, me, patchMe } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [page, setPage] = useState(me && me.cellVerified ? 2 : 1);

  const onNextPage = async () => {
    setPage(page + 1);
    window.scrollTo(0, 0);
  };
  
  const onComplete = async () => {
    const { response, error } = await api.post('users/onboarded');
    if (response) {
      patchMe(response);
    } else if (error) {
      popToast(error, 'error');
    } else {
      popToast('Something went wrong. Please try again.', 'error');
    }
  };
 
  return (
    <Layout onboarding centerContent>
      <div style={{ maxWidth: 675, textAlign: 'center' }}>
        <Collapse in={page === 1}>
          <Typography variant="h3" className={classes.onboardingHeader}>Verify Your Cell Number</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 32 }}>
            We sent a text message to {me.cell} with a unique verification code.
            Please enter the code below to confirm your cell number.
          </Typography>

          <VerificationForm onSave={onNextPage} />
        </Collapse>

        <Collapse in={page === 2}>
          <Typography variant="h3" className={classes.onboardingHeader}>Geographic Profile</Typography>
          <Alert severity="warning" style={{ textAlign: 'left', marginBottom: 24 }}>
            If you match with an available dose within your travel area, we will
            send you a text message (SMS) to confirm your availability. You will
            have <strong>limited time to confirm</strong> before we offer the dose
            to the next person in line.
            <br/><br/>
            Once confirmed, we will send information on where the vaccination clinic is
            and what your arrival instructions are. To minimize the chance of cancellation
            or wasted doses, please be as accurate below as possible.
            <br/><br/>
            <strong>If you cancel an appointment after confirming your availability for it, you will be much less likely to match for future doses on our platform.</strong>
          </Alert>

          <GeographyForm onSave={onNextPage} />
        </Collapse>

        <Collapse in={page === 3}>
          <Typography variant="h3" className={classes.onboardingHeader}>Risk Profile: Health</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 16 }}>
            Your information will only ever be shared with a vaccine administrator.
            Any data you submit here will be permanantly deleted when your account
            is deleted or when VaccineList terminates its service.
          </Typography>
          
          <HealthRisk onSave={onNextPage} />
        </Collapse>

        <Collapse in={page === 4}>
          <Typography variant="h3" className={classes.onboardingHeader}>Risk Profile: Occupation</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 16 }}>
            How does your occupation impact your exposure?
          </Typography>

          <OccupationRisk onSave={onNextPage} />
        </Collapse>

        <Collapse in={page === 5}>
          <Typography variant="h3" className={classes.onboardingHeader}>Risk Profile: Household</Typography>
          <Typography variant="h6" className={classes.onboardingSubheader} style={{ marginBottom: 16 }}>
            How does your personal risk impact others in your household?
          </Typography>

          <HouseholdRisk onSave={onNextPage} />
        </Collapse>

        <Collapse in={page === 6}>
          <Typography variant="h3" className={classes.onboardingHeader}>Risk Profile: Other</Typography>

          <OtherRisk onSave={onComplete} />
        </Collapse>

      </div>
    </Layout>
  );
}

export default withStyles(styles)(PatientSignup);
