import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import VerificationIcon from '@material-ui/icons/SecurityOutlined';
import Layout from '../../components/Layout';
import VerificationForm from '../../components/VerificationForm';
import AuthContext from '../../contexts/AuthContext';
import ToastContext from '../../contexts/ToastContext';
import useFormHelper from '../../hooks/useFormHelper';
import {
  useFormik,
  FormikTextField
} from '../../utils/formik';
import { AccountSchema } from '../../utils/validation';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& > *': {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    }
  },
}));

export default function Account() {
  const classes = useStyles();
  const { api, logout, me, patchMe } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  const [verifyingCell, setVerifyingCell] = useState(!me.cellVerified);

  const formik = useFormik({
    initialValues: {
      type: 'patient',
      firstName: me.firstName || '',
      lastName: me.lastName || '',
      cell: me.cell || '',
      email: me.email || '',
    },
    validateOnBlur: true,
    validationSchema: AccountSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users', values);
      if (response) {
        const promptForCellVerification = response.cell && me.cell !== response.cell && !response.cellVerified;
        patchMe(response);
        setSuccessMsg();
        if (promptForCellVerification) {
          onVerifyCell();
        }
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik, true);

  const onDeleteAccount = async () => {
    if (window.confirm('Are you sure you want to delete your account? This cannot be undone.')) {
      const { response, error } = await api.del('users');
      if (response) {
        popToast('Thanks for using VaccineList! Your account has now been removed.');
        logout();
      } else {
        setErrorMsg(error || 'Something went wrong. Please try again.');
      }
    }
  };
  const onVerifyCell = () => {
    setVerifyingCell(true);
  };
  const onDoneVerifyingCell = () => {
    setVerifyingCell(false);
  };
  const onResendEmail = async () => {
    const { response, error } = await api.patch('users/verify', { type: 'email', value: me.email });
    if (response) {
      setSuccessMsg('Verification Email Resent.');
    } else if (error) {
      setErrorMsg(error);
    } else {
      setErrorMsg('Something went wrong. Please try again.');
    }
  };

  const breadcrumbs = [
    { label: 'Dashboard', href: '/patient' },
    { label: 'Account', href: '/patient/account' },
    { label: 'Settings' },
  ];

  const showCellVerification = me.cell && !me.cellVerified && me.cell === formik.values.cell;
  const showEmailVerification = me.email && !me.emailVerified && me.email === formik.values.email;
  return (
    <Layout centerContent breadcrumbs={breadcrumbs} suppressPageWarnings>
      <div style={{ display: 'flex', flexDirection: 'column', width: 600 }}>
        <Paper style={{ padding: 32, width: '100%', marginBottom: 16 }}>
          <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormikTextField
                autoFocus
                fullWidth
                name="firstName"
                label="First Name"
                placeholder="Enter your first name..."
                formik={formik}
              />

              <div style={{ width: 16 }} />

              <FormikTextField
                fullWidth
                name="lastName"
                label="Last Name"
                placeholder="Enter your last name..."
                formik={formik}
              />
            </div>

            <FormikTextField
              fullWidth
              name="cell"
              label="Cell Phone"
              placeholder="Enter your cell phone..."
              helperText="If you match with an available dose, we use text message (SMS) to ensure the quickest possible response."
              InputProps={{
                endAdornment: showCellVerification && (
                  <Tooltip title="Pending Verification (click to verify)" placement="left">
                    <InputAdornment position="end">
                      <IconButton color="secondary" onClick={onVerifyCell}>
                        <VerificationIcon />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                )
              }}
              formik={formik}
            />

            <FormikTextField
              fullWidth
              name="email"
              label="Email (optional)"
              placeholder="Enter email address..."
              helperText="For less urgent communication."
              style={{ marginTop: 12 }}
              InputProps={{
                endAdornment: showEmailVerification && (
                  <Tooltip title="Pending Verification (click to resend)" placement="left">
                    <InputAdornment position="end">
                      <IconButton color="secondary" onClick={onResendEmail}>
                        <VerificationIcon />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                )
              }}
              formik={formik}
            />
            
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">Update Account</Button>
          </form>
        </Paper>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', opacity: 0.5 }}>
          {/* TODO: <Button variant="contained">Update Password</Button> */}
          <Button onClick={onDeleteAccount} size="small" variant="outlined">Delete Account</Button>
        </div>
      </div>

      <Dialog open={verifyingCell}>
        <DialogTitle>Verify Your Cell Number</DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          <Typography variant="body1" color="textSecondary" style={{ fontWeight: '400', marginBottom: 32 }}>
            We sent a text message to {me.cell} with a unique verification code.
            Please enter the code below to confirm your cell number.
          </Typography>
          <VerificationForm onSave={onDoneVerifyingCell} />
        </DialogContent>
      </Dialog>
    </Layout>
  );
}