import React from 'react';
import Paper from '@material-ui/core/Paper';
import Layout from '../../components/Layout';
import ContactPreferences from '../../components/provider/ContactPreferences';

export default function ContactPrefs({ history }) {
  const breadcrumbs = [
    { label: 'Dashboard', href: '/provider' },
    { label: 'Account', href: '/provider/account' },
    { label: 'Contact Preferences' },
  ];
  return (
    <Layout centerContent breadcrumbs={breadcrumbs}>
      <div style={{ display: 'flex', flexDirection: 'column', width: 700 }}>
        <Paper style={{ padding: 32, width: '100%', marginBottom: 16 }}>
          <ContactPreferences history={history} />
        </Paper>
      </div>
    </Layout>
  );
}