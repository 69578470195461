import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Dialog,
  DialogContent,
  Hidden,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AuthContext from '../contexts/AuthContext';
import useFormHelper from '../hooks/useFormHelper';
import {
  useFormik,
  FormikTextField
} from '../utils/formik';
import { VolunteerSchema } from '../utils/validation';
import styles from '../styles/base';

function QuickVolunteerDialog({ classes, handleClose, specialty }) {
  const { api } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      specialty,
      name: '',
      email: '',
      profession: '',
      location: '',
      message: ''
    },
    validateOnBlur: true,
    validationSchema: VolunteerSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.post('auth/volunteer', values);
      if (response) {
        setSuccessMsg(`Your message has been received. We will reach out shortly!`);
        handleClose();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik, true);

  return (
    <Dialog open onClose={handleClose} PaperProps={{ style: { margin: '32px 8px' } }}>
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
          <div>
            <Hidden xsDown>
              <Typography variant="h6" style={{ fontSize: '1.2rem', marginBottom: 16 }}>
                Thanks in advance for your help! Send us a little info about yourself and we'll invite you to our internal Slack channel.
              </Typography>
            </Hidden>
            <Hidden smUp>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                Thanks in advance for your help!
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 32 }}>
                Send us a little info about yourself and we'll invite you to our internal Slack channel.
              </Typography>
            </Hidden>
          </div>
          <IconButton aria-label="close" onClick={handleClose} size="small">
            <CloseIcon />
          </IconButton>
        </div>

        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
          <FormikTextField
            autoFocus
            fullWidth
            name="name"
            label="Name"
            InputLabelProps={{ shrink: true }}
            formik={formik}
          />

          <FormikTextField
            fullWidth
            name="email"
            label="Email"
            InputLabelProps={{ shrink: true }}
            formik={formik}
          />

          <FormikTextField
            fullWidth
            name="profession"
            label="Profession / Job Title"
            InputLabelProps={{ shrink: true }}
            formik={formik}
          />

          <FormikTextField
            fullWidth
            name="location"
            helperText="Not in the US? Enter your country here."
            label="US Zip Code"
            InputLabelProps={{ shrink: true }}
            formik={formik}
          />

          <FormikTextField
            fullWidth
            multiline
            rows={3}
            name="message"
            label="Personal Message / Areas of Interest"
            placeholder="Is there something specific you would like to help with or are you just happy to chip in wherever you can?"
            InputLabelProps={{ shrink: true }}
            formik={formik}
          />
          
          <Button
            color="primary"
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained">Submit</Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(QuickVolunteerDialog);
