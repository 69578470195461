import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Hidden,
  Link,
  Typography
 } from '@material-ui/core';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import AuthContext from '../contexts/AuthContext';
import styles from '../styles/base';

function About({ classes }) {
  const { me } = useContext(AuthContext);
  return (
    <>
      <Layout centerContent>
        <div style={{ maxWidth: 845, textAlign: 'left' }}>
          <Typography
            variant="h3"
            className={classes.onboardingHeader}
            color="primary"
            style={{ textAlign: 'center', marginBottom: 32 }}>Goodbye From VaccineList</Typography>
          <div className={classes.formContainer}>
            <Typography>
              We set out building VaccineList to eliminate vaccine waste while improving prioritization
              of the most at-risk patients in the community. From the beginning, this was a personal
              passion project, motivated by our own family's experience with the vaccine rollout and
              desire to solve a problem that no one else seemed to be working on.
            </Typography>
            <Typography>
              Last week, I spoke with Cyrus Massoumi, the former CEO of Zocdoc and current founder of
              a nearly identical service called <span className={classes.drb}>Dr. B</span>{' '}
              (<Link href="https://www.google.com/search?q=dr+b&tbm=nws" target="_blank" style={{ whiteSpace: 'nowrap' }}>read <Hidden xsDown>about <b>Dr. B</b> in</Hidden> the news</Link>).
              {' '}<span className={classes.drb}>Dr. B</span> shares our mission, and I believe it will be
              easier to achieve our shared vision by consolidating our efforts with them. They are
              already getting more doses to patients on their waitlist than we are, and they have far
              more resources at their disposal to continue to grow.
            </Typography>
            <Typography>
              At this time, we recommend all patients that are still in need of a vaccine to signup for{' '}
              <span className={classes.drb}>Dr. B</span>, and we are asking our providers to do the same.
              We will be shutting down VaccineList permanently.
            </Typography>
            <Typography>
              Thank you for your support and excitement through this journey, and we look forward to
              a time in the very near future when vaccines are no longer scarce and the world feels
              a little bit more normal again.
            </Typography>
            <div style={{ paddingBottom: 8, textAlign: 'center' }}>
              <Button color="primary" href="https://hidrb.com" size="large" variant="contained">Go to Dr. B's Website</Button>
            </div>
            <Typography>
              <b>Why are you doing this?</b> Statistically, it's a better opportunity for more of our users to get vaccinated sooner.
            </Typography>
            <Typography>
              <b>Can I automatically transfer my profile?</b> Unfortunately not, but signup at <span className={classes.drb}>Dr. B</span> is quick.
            </Typography>
            <Typography>
              <b>What will happen to my data?</b> All patient data will be permanently deleted within the next 72 hours.
            </Typography>
            <Typography>
              <b>What about…?</b> If you have any questions, don't hesitate to reach out to {process.env.REACT_APP_CONTACT_EMAIL}
            </Typography>
          </div>
        </div>
      </Layout>
      {!me && <Footer />}
    </>
  );
}

export default withStyles(styles)(About);