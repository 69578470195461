import React, { useContext, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  CircularProgress,
  Collapse,
} from '@material-ui/core';
import AddressAutocomplete, { geocodeResult } from '../AddressField';
import AuthContext from '../../contexts/AuthContext';
import useFormHelper from '../../hooks/useFormHelper';
import styles from '../../styles/base';
import {
  useFormik,
  FormikTextField
} from '../../utils/formik';
import { ClinicSchema } from '../../utils/validation';

function ClinicForm({ classes, editClinic, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);
  const [id, setId] = useState((editClinic || {}).id || '');
  const [loading, setLoading] = useState(false);

  const patchMeWithClinic = (clinic) => {
    me.provider.clinics = me.provider.clinics.filter(({ id }) => id !== clinic.id);
    me.provider.clinics.push(clinic);
    patchMe(me);
  };

  const formik = useFormik({
    initialValues: editClinic ? { ...editClinic } : {
      name: '',
      address: '',
      affiliation: '',
      geocode: null,
      phone: '',
      arrivalInstructions: ''
    },
    validateOnBlur: true,
    validationSchema: ClinicSchema,
    onSubmit: async (values) => {
      let apiResponse;
      if (id) {
        apiResponse = await api.patch(`clinics/${id}`, values);
      } else {
        apiResponse = await api.post('clinics', values);
      }

      const { response, error } = apiResponse;
      if (response) {
        patchMeWithClinic(response);
        if (onSave) onSave(response);
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik, true);

  const onChangeAddress = async (value) => {
    setLoading(true);
    const geocodeData = value ? await geocodeResult(value) : {};
    if (geocodeData.address) {
      // if clinic already exists...
      const { response } = await api.post('clinics/search', { address: geocodeData.address });
      if (response) {
        // connect to clinic
        await api.post(`users/provider/clinic/${response.id}`);
        const { completed, ...clinic } = response;

        // if matching clinic completed, send to next step
        if (completed) {
          patchMeWithClinic(clinic);
          if (onSave) onSave();
          else setSuccessMsg();

        // else, prompt to complete clinic info
        } else {
          setId(clinic.id);
          Object.keys(clinic).forEach((k) => {
            formik.setFieldValue(k, clinic[k]);
          });
        }

      // else, create new from geocoded data
      } else {
        formik.setFieldValue('address', geocodeData.address);
        formik.setFieldValue('geocode', geocodeData.geocode);
      }
    } else {
      formik.setFieldValue('address', '');
      formik.setFieldValue('geocode', null);
    }

    setLoading(false);
  }

  return (
    <div>
      <Collapse in={loading}>
        <div style={{ margin: `60px`, textAlign: 'center' }}>
          <CircularProgress size={60} />
        </div>
      </Collapse>
      <Collapse in={!loading && !formik.values.geocode}>
        <div className={classes.formContainer}>
          <AddressAutocomplete label="Clinic Address" onChange={onChangeAddress} />
        </div>
      </Collapse>
      <Collapse in={!loading && !!formik.values.geocode}>
        <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
          <FormikTextField
            disabled
            fullWidth
            name="address"
            label="Clinic Address *"
            formik={formik}
          />

          <FormikTextField
            autoFocus
            fullWidth
            name="name"
            label="Clinic Name *"
            placeholder="Enter a label for this clinic..."
            formik={formik}
          />

          <FormikTextField
            fullWidth
            name="affiliation"
            label="Healthcare Affiliation"
            placeholder="Enter the name of the affiliated healthcare organization..."
            formik={formik}
          />

          <FormikTextField
            fullWidth
            name="phone"
            label="Clinic Phone *"
            placeholder="Enter a phone number for this clinic..."
            formik={formik}
          />

          <FormikTextField
            fullWidth
            multiline
            rows={4}
            name="arrivalInstructions"
            label="Arrival Instructions *"
            helperText="You can customize these as needed for any given day, but this will serve as a default."
            placeholder="Enter the typical arrival instructions given for patients receiving a leftover dose..."
            formik={formik}
          />
          
          <Button
            color="primary"
            fullWidth
            disabled={formik.isSubmitting || loading}
            size="large"
            type="submit"
            variant="contained">Submit</Button>
        </form>
      </Collapse>
    </div>
  );
};

export default withStyles(styles)(ClinicForm);
