import React, { useContext, useState } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Hidden,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useScrollTrigger
 } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MenuIcon from '@material-ui/icons/Menu';
import LogoIcon from '@material-ui/icons/LocalPharmacyOutlined';
import SeparatorIcon from '@material-ui/icons/KeyboardArrowRight';
import Drawer from './Drawer';
import AuthContext from '../contexts/AuthContext';
import ReverifyDialog from './ReverifyDialog';

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: 'background-color 0.15s ease-out',
    zIndex: theme.zIndex.drawer + 1,
  },
  breadcrumbContainer: {
    marginBottom: theme.spacing(),
    width: '100%',
    '@media print': {
      display: 'none !important',
    }
  },
  breadcrumbDivider: {
    width: '100%',
    marginTop: 55
  },
  grow: {
    flex: 1
  },
  offset: theme.mixins.toolbar,
  main: {
    borderTop: '1px solid #DDD',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(3),
    overflowX: 'scroll'
  },
  mainCentered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainNav: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  mainNoGutters: {
    padding: 0
  },
  mainRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100vw'
  },
  menu: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  notDrawer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column'
  },
  title: {
    flexGrow: 1,
    paddingLeft: theme.spacing(0.75),
    fontWeight: 400
  },
  [theme.breakpoints.down('sm')]: {
    breadcrumbContainer: {
      marginBottom: theme.spacing(2),
    },
    breadcrumbDivider: {
      display: 'none',
    },
    main: {
      padding: theme.spacing(1.5),
    },
    notDrawer: {
      maxWidth: '100%',
    }
  },
}));

export default function Layout({
  breadcrumbs,
  centerContent,
  children,
  disableContentGutters,
  hideDivider,
  onboarding,
  styles = {},
  suppressPageWarnings
}) {
  const classes = useStyles();
  const history = useHistory();
  const { me, logout } = useContext(AuthContext);
  const triggered = useScrollTrigger({ threshold: 10 });

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [reverifying, setReverifying] = useState(false);

  const appBarProps = {};
  const authStyles = {};
  if (me && !onboarding) {
    appBarProps.color = 'primary';
    authStyles.background = '#f7f7f7';
  } else if (triggered) {
    appBarProps.color = 'inherit';
    appBarProps.elevation = 4;
  } else {
    appBarProps.color = 'inherit';
    appBarProps.elevation = 0;
  }

  const handleLogout = async () => {
    await logout();
    history.push('/');
  };
  const handleDrawerOpen = () => {
    setMobileDrawer(true);
  };
  const handleDrawerClose = () => {
    setMobileDrawer(false);
  };

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const closeMenu = () => {
    setMenuAnchor(null);
  };

  const resendEmail = () => {
    setReverifying(true);
  };
  const onDoneReverifying = () => {
    setReverifying(false);
  };

  const handleLogoClick = () => {
    history.push(me ? `/${me.type}` : '/');
  };
  const logoProps = onboarding ? {} : {
    onClick: handleLogoClick,
    style: { cursor: 'pointer' }
  };

  let crumbs;
  let crumbsTitle;
  if (breadcrumbs) {
    crumbs = breadcrumbs.slice(0, -1);
    crumbsTitle = breadcrumbs[breadcrumbs.length - 1];
  }

  return (
    <div style={{ display: 'flex', minHeight: '100vh', ...authStyles, ...styles }}>
      <AppBar
        className={classes.appBar}
        position="fixed"
        {...appBarProps}>
        <Toolbar>
          <LogoIcon {...logoProps} />
          <Typography variant="h6" className={classes.title} {...logoProps}>
            VaccineList.org
          </Typography>
          <div style={{ flex: 1 }} />
          {me
            ? (
              <>
                <Hidden smDown>
                  <Button color="inherit" onClick={handleLogout}>Logout</Button>
                </Hidden>
                <Hidden mdUp>
                  {onboarding
                    ? (
                      <>
                        <IconButton color="inherit" onClick={openMenu}>
                          <MenuIcon />
                        </IconButton>
                        <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={closeMenu}>
                          <MenuItem onClick={logout}>Logout</MenuItem>
                        </Menu>
                      </>
                    )
                    : (
                      <IconButton color="inherit" onClick={handleDrawerOpen}>
                        <MenuIcon />
                      </IconButton>
                    )}
                </Hidden>
              </>
            )
            : (
              <>
                <Hidden xsDown>
                  <Button color="inherit" component={RouterLink} to="/login" style={{ marginRight: 8 }}>Log In</Button>
                </Hidden>
                <Button color="inherit" component={RouterLink} to="/signup" variant="outlined">Sign Up</Button>
              </>
            )}
        </Toolbar>
      </AppBar>
      
      <div className={classes.mainRow}>
        {me && !onboarding && (
          <Drawer mobileOpen={mobileDrawer} onMobileClose={handleDrawerClose} type={me.type} />
        )}
        <div className={classes.notDrawer}>
          <div className={classes.offset} />
          {!suppressPageWarnings && !onboarding && (
            <>
              {me && me.email && !me.emailVerified && (
                <>
                  <Alert
                    action={(
                      <Button
                        color="inherit"
                        onClick={resendEmail}
                        size="small"
                        style={{ whiteSpace: 'nowrap' }}
                        variant="outlined">Resend <Hidden smDown>Email</Hidden></Button>
                    )}
                    severity={me.type === 'provider' ? 'error' : 'warning'}>
                    Your email address <b>{me.email}</b> has not yet been confirmed.
                    Please click the verification link in the email we sent to finish setting up your account.
                  </Alert>
                  <ReverifyDialog type="email" onClose={onDoneReverifying} open={reverifying} />
                </>
              )}
            </>
          )}
          <Container
            className={clsx({
              [classes.main]: true,
              [classes.mainNoGutters]: disableContentGutters,
              [classes.mainCentered]: centerContent && !crumbs
            })}
            disableGutters={true}
            maxWidth={false}>
            {crumbs && (
              <div className={classes.breadcrumbContainer}>
                <Breadcrumbs separator={<SeparatorIcon fontSize="small" />}>
                  {crumbs.map(({ label, href }, x) => (
                    <Link key={x} color="inherit" component={RouterLink} to={href}>{label}</Link>
                  ))}
                </Breadcrumbs>
                <Typography variant="h5">{crumbsTitle.label}</Typography>
                {!hideDivider && <Divider className={classes.breadcrumbDivider} />}
              </div>
            )}
            {centerContent && crumbs
              ? (
                <div className={classes.mainCentered} style={{ flex: 1 }}>
                  {children}
                </div>
              )
              : children}
          </Container>
        </div>
      </div>
    </div>
  );
};
