import React, { useContext } from 'react';
import { Switch, Redirect, Route, useRouteMatch } from 'react-router-dom';
import AuthContext from '../../contexts/AuthContext';
import Account from './account';
import Clinics from './clinics';
import Contact from './contact';
import Index from './index';
import Onboarding from './onboarding';

export default () => {
  const { path } = useRouteMatch();
  const { me } = useContext(AuthContext);
  if (me.onboarded) {
    return (
      <Switch>
        <Route exact path={path} component={Index} />
        <Route path={`${path}/account`} component={Account} />
        <Route path={`${path}/clinics`} component={Clinics} />
        <Route path={`${path}/contact`} component={Contact} />
        <Route><Redirect to={`${path}`} /></Route>
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={path} component={Onboarding} />
        <Route><Redirect to={`${path}`} /></Route>
      </Switch>
    );
  }
}