import React, { useContext } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import ScrollToTop from './components/ScrollToTop';
import AuthContext, { AuthProvider } from './contexts/AuthContext';
import { ToastProvider } from './contexts/ToastContext';
import routes from './pages/routes';
import defaultTheme from './styles/theme';

function AppRouter() {
  const { me } = useContext(AuthContext);

  let authedRoutes;
  if (me && me.type) {
    authedRoutes = [
      ...routes.always,
      ...routes.loggedIn,
      ...routes[me.type]
    ];
  } else {
    authedRoutes = [
      ...routes.always,
      ...routes.loggedOut
    ];
  }

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {authedRoutes.map((route) => {
          if (route.redirect) {
            return <Route key="*"><Redirect to={route.redirect} /></Route>;
          } else {
            return <Route key={route.path} {...route} />;
          }
        })}
      </Switch>
    </Router>
  );
}

function App() {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <AuthProvider>
        <ToastProvider>
          <AppRouter />
        </ToastProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
