import React, { useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AuthContext from '../../contexts/AuthContext';
import useFormHelper from '../../hooks/useFormHelper';
import styles from '../../styles/base';
import {
  useFormik,
  FormikTextField
} from '../../utils/formik';
import { ProviderEmployerInfoSchema } from '../../utils/validation';

function VerificationInfo({ classes, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      employer: (me.provider || {}).employer || '',
      jobTitle: (me.provider || {}).jobTitle || '',
    },
    validateOnBlur: true,
    validationSchema: ProviderEmployerInfoSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/provider/employment', values);
      if (response) {
        patchMe(response);
        if (onSave) onSave();
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik, true);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <FormikTextField
        autoFocus
        fullWidth
        name="employer"
        label="Employer Company Name *"
        placeholder="Enter your employer name..."
        formik={formik}
      />

      <FormikTextField
        fullWidth
        name="jobTitle"
        label="Job Title"
        placeholder="Enter your title..."
        formik={formik}
      />

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">
        Submit
      </Button>
    </form>
  );
}

export default withStyles(styles)(VerificationInfo);
