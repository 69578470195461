import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ToastContext from '../contexts/ToastContext';

export default function Footer() {
  const { popToast } = useContext(ToastContext);
  const contactUs = () => {
    navigator.clipboard.writeText(process.env.REACT_APP_CONTACT_EMAIL || '');
    popToast('Our email address has been copied to your clipboard. You can paste it into your preferred email client to send us a message.', 'info', 10000);
  };

  return (
    <footer style={{ background: '#222', color: 'white', padding: '0 24px' }}>
      <div style={{ padding: '24px 0 20px', margin: '0 auto', maxWidth: 912 }}>
        <Typography variant="body2" style={{ fontStyle: 'italic', opacity: 0.5 }}>
          Thanks to the healthcare heroes, essential workers, scientists working overtime,
          social-distancers and mask-wearers for getting us this far.
        </Typography>
        <Divider style={{ backgroundColor: 'rgba(255,255,255,0.2)', margin: '12px 0' }} />
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {[
            ['Sign Up', '/signup'],
            ['Log In', '/login'],
            ['Volunteer', '/volunteer'],
            ['Donate', '/donate'],
            ['About', '/about'],
            ['Privacy', '/privacy'],
            ['Terms', '/terms'],
            ['Contact', contactUs]
          ].map(([label, href]) => (
            <span style={{ display: 'inline', fontSize: '0.8rem', marginRight: 16, marginBottom: 6, whiteSpace: 'nowrap' }}>
              {typeof href === 'function'
                ? (
                  <Link key={label} color="inherit" onClick={href} style={{ cursor: 'pointer' }}>{label}</Link>
                )
                : (
                  <Link key={label} color="inherit" component={RouterLink} to={href}>{label}</Link>
                )
              }
            </span>
          ))}
        </div>
        <div style={{ marginTop: 24 }}>
          <Typography color="inherit" variant="caption">VaccineList © 2021</Typography>
        </div>
      </div>
      
    </footer>
  );
}