import React from 'react';
import Paper from '@material-ui/core/Paper';
import Layout from '../../components/Layout';
import Geography from '../../components/patient/Geography';

export default function GeographyProfile() {
  const breadcrumbs = [
    { label: 'Dashboard', href: '/patient' },
    { label: 'Account', href: '/patient/account' },
    { label: 'Geography Profile' },
  ];
  return (
    <Layout centerContent breadcrumbs={breadcrumbs}>
      <Paper style={{ padding: '16px 32px', textAlign: 'center' }}>
        <Geography />
      </Paper>
    </Layout>
  );
}