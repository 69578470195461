import React, { useContext } from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AuthContext from '../../../contexts/AuthContext';
import useFormHelper from '../../../hooks/useFormHelper';
import styles from '../../../styles/base';
import {
  useFormik,
  FormikLabeledCheckbox,
  FormikSelect
} from '../../../utils/formik';
import {
  PatientOccupationRisksSchema,
  PatientRisksConstants
} from '../../../utils/validation';

const { workerTallies } = PatientRisksConstants;
function OccupationRisk({ classes, onSave }) {
  const { api, me, patchMe } = useContext(AuthContext);

  const myRisks = me.patient?.risks ?? {};
  const formik = useFormik({
    initialValues: { 
      healthcare: myRisks.healthcare || false,
      publicMembers: myRisks.publicMembers || 0,
      coworkers: myRisks.coworkers || 0,
      wfh: myRisks.wfh === false ? false : true,  // (default to true)
    },
    validateOnBlur: true,
    validationSchema: PatientOccupationRisksSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users/patient/risks', {
        page: 'occupation',
        ...values
      });
      if (response) {
        patchMe(response);
        if (onSave) onSave();
        else setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik);

  return (
    <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
      <FormikLabeledCheckbox
        color="primary"
        label="I am currently unemployed or able to work without sharing physical space with non-household members."
        name="wfh"
        formik={formik}
      />

      <Collapse in={!formik.values.wfh}>
        <Paper elevation={4} className={clsx([classes.formContainer, classes.occupationRisks])}>

          <Typography style={{ marginTop: 0 }}>
            My job requires me to share physical space with
            <FormikSelect
              className={classes.inlineField}
              displayEmpty
              name="coworkers"
              formik={formik}>
              {workerTallies.map((tally, x) => (
                <MenuItem key={x} value={x}>{tally}</MenuItem>
              ))}
            </FormikSelect>
            coworkers from outside my household per day.
          </Typography>

          <Typography>
            My job requires me to share physical space with
            <FormikSelect
              className={classes.inlineField}
              displayEmpty
              name="publicMembers"
              formik={formik}>
              {workerTallies.map((tally, x) => (
                <MenuItem key={x} value={x}>{tally}</MenuItem>
              ))}
            </FormikSelect>
            members of the public per day.
          </Typography>

          <Typography>
            My job
            <FormikSelect
              className={classes.inlineField}
              displayEmpty
              name="healthcare"
              formik={formik}>
              {['does not', 'does'].map((label, x) => (
                <MenuItem key={x} value={Boolean(x)}>{label}</MenuItem>
              ))}
            </FormikSelect>
            require me to share physical space with known COVID-positive individuals.
          </Typography>

        </Paper>
      </Collapse>

      <Button
        color="primary"
        disabled={formik.isSubmitting}
        fullWidth
        size="large"
        type="submit"
        variant="contained">Submit</Button>
    </form>
  );
}

export default withStyles(styles)(OccupationRisk);
