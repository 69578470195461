import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import AuthContext from '../contexts/AuthContext';
import styles from '../styles/base';

function About({ classes }) {
  const { me } = useContext(AuthContext);
  return (
    <>
      <Layout centerContent>
        <div style={{ maxWidth: 912, textAlign: 'left' }}>
          <Typography
            variant="h3"
            className={classes.onboardingHeader}
            style={{ textAlign: 'center', marginBottom: 32 }}>Why We Exist</Typography>

          <div className={classes.formContainer}>
            <Typography>
              COVID took hold in the US about a month before my wife's second round of cancer treatment.
              It hasn't been a fun year for anyone, but for us, we've had the added complications of
              immunosuppression, medical travel, life-altering surgery, and a toddler who we are desperately
              trying to provide a stable environment for. That said, we also feel incredibly privileged to
              have family support (though also complicated by COVID), a remote job, and a team of doctors we trust.
            </Typography>
            <Typography>
              As we approached commencement of my wife's third round of treatment, her oncologist suggested
              that it would be great to get the vaccine but would definitely prefer that she get it before
              starting a new immunologic drug to avoid unknown complication risks. Oof. So do we try to get her
              the vaccine before it's "allowed" in our state? Do we delay treatment until it's
              her turn? Do we start treatment now and wait until she's off treatment again in 1 - 2 years to
              get vaccinated (and is that fair to our child)? Is it ethical to try to skip the line with
              the knowledge that the phases of the rollout couldn't possibly capture the nuance of our situation?
            </Typography>
            <Typography>
              As we struggled through this, we started to read about the doses being wasted all over the country.
              Because multiple doses are in each vial of vaccine and an open vial spoils within 6 hours, unless
              a clinic has the exact number of patients to match the doses in the vial, there is wastage. This
              has reason to be underreported by providers, and even so, estimates for the amount of vaccine
              wasted in the first round of distribution are several percent at minimum. Yikes.
            </Typography>
            <Typography>
              So we got on the phone, we told our story to every clinic that would listen, and finally, at the
              end of the day, a nurse at a local town's Department of Health had an extra dose, remembered us,
              and called to tell us my wife could get it if she could be there within 20 minutes. This was a
              tremendously needed win in our family's journey this year.
            </Typography>
            <Typography>
              Battling cancer means you do a lot of bargaining with the universe (and you really try not to cross
              it). So, on the way home from her shot, we had already made up our minds -- ok, we got this win,
              let's help others do the same.
            </Typography>
            <Typography>
              And so here we are.
            </Typography>
            <Typography>
              How's it work? Patients fill out a risk profile and geographic info. We score them according to their
              risk factors (following CDC guidelines). When a provider has an excess dose, they can allocate it to the
              waitlist, and our software finds the most at-risk patient who is geographically available to receive
              the dose on the provider's schedule. The patient receives a text message to confirm their availability
              (or not), and the software progressively offers it to more and more patients on the list until there
              is a match. At that point, information is exchanged between the patient and provider and the rest is
              up to them.
            </Typography>
            <Typography>
              Our goal is to help eliminate vaccine waste while still prioritizing the most at risk populations, and
              we think we can do it.
            </Typography>
            <Typography>
              Want to help?{' '}
              <Link component={RouterLink} to="/volunteer">Click here</Link>.
              Want to sign up?{' '}
              <Link component={RouterLink} to="/signup">Click here</Link>.
            </Typography>
            <Typography>
              Thanks for reading!
            </Typography>
          </div>
        </div>
      </Layout>
      {!me && <Footer />}
    </>
  );
}

export default withStyles(styles)(About);