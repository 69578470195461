import { useContext, useEffect } from 'react';
import AuthContext from '../contexts/AuthContext';
import ToastContext from '../contexts/ToastContext';

function Logout({ history }) {
  const { logout } = useContext(AuthContext);
  const { popToast } = useContext(ToastContext);
  useEffect(() => {
    popToast('Your session has expired. Please log in to continue.', 'info');
    logout();
  }, []);

  return null;
}

export default Logout;