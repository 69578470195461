import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Tooltip,
} from '@material-ui/core';
import VerificationIcon from '@material-ui/icons/SecurityOutlined';
import Layout from '../../components/Layout';
import AuthContext from '../../contexts/AuthContext';
import useFormHelper from '../../hooks/useFormHelper';
import {
  useFormik,
  FormikTextField
} from '../../utils/formik';
import { AccountSchema } from '../../utils/validation';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    '& > *': {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px`,
    }
  },
}));

export default function Account() {
  const classes = useStyles();
  const { api, me, patchMe } = useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      type: 'volunteer',
      firstName: me.firstName || '',
      lastName: me.lastName || '',
      email: me.email || '',
      jobTitle: me.volunteer.jobTitle || '',
    },
    validateOnBlur: true,
    validationSchema: AccountSchema,
    onSubmit: async (values) => {
      const { response, error } = await api.patch('users', values);
      if (response) {
        patchMe(response);
        setSuccessMsg();
      } else if (error) {
        setErrorMsg(error);
      } else {
        setErrorMsg('Something went wrong. Please try again.');
      }
    },
  });

  const { setErrorMsg, setSuccessMsg } = useFormHelper(formik, true);

  const onResendEmail = async () => {
    const { response, error } = await api.patch('users/verify', { type: 'email', value: me.email });
    if (response) {
      setSuccessMsg('Verification Email Resent.');
    } else if (error) {
      setErrorMsg(error);
    } else {
      setErrorMsg('Something went wrong. Please try again.');
    }
  };

  const breadcrumbs = [
    { label: 'Volunteer', href: '/volunteer' },
    { label: 'Account', href: '/volunteer/account' },
    { label: 'Settings' },
  ];

  const showEmailVerification = me.email && !me.emailVerified && me.email === formik.values.email;
  return (
    <Layout centerContent breadcrumbs={breadcrumbs} suppressPageWarnings>
      <div style={{ display: 'flex', flexDirection: 'column', width: 600 }}>
        <Paper style={{ padding: 32, width: '100%', marginBottom: 16 }}>
          <form onSubmit={formik.handleSubmit} className={classes.formContainer}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <FormikTextField
                autoFocus
                fullWidth
                name="firstName"
                label="First Name"
                placeholder="Enter your first name..."
                formik={formik}
              />

              <div style={{ width: 16 }} />

              <FormikTextField
                fullWidth
                name="lastName"
                label="Last Name"
                placeholder="Enter your last name..."
                formik={formik}
              />
            </div>

            <FormikTextField
              fullWidth
              name="email"
              label="Email"
              placeholder="Enter email address..."
              InputProps={{
                endAdornment: showEmailVerification && (
                  <Tooltip title="Pending Verification (click to resend)" placement="left">
                    <InputAdornment position="end">
                      <IconButton color="secondary" onClick={onResendEmail}>
                        <VerificationIcon />
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                )
              }}
              formik={formik}
              style={{ marginTop: 12 }}
            />

            <FormikTextField
              fullWidth
              name="jobTitle"
              helperText="If retired, enter your pre-retirement profession."
              label="Profession / Job Title"
              placeholder="Enter your title..."
              formik={formik}
              style={{ marginTop: 12 }}
            />
            
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained">Update Account</Button>
          </form>
        </Paper>

        {/* TODO: 
        (delete should convert to volunteer account and delete personal data)
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Button variant="contained">Update Password</Button>
          <Button color="secondary" variant="outlined">Delete Account</Button>
        </div>
        */}
      </div>
    </Layout>
  );
}